import {
    AmendTileRequest,
    CommitTileRequest,
    FetchPresignedPutUrlRequest,
    IdRequest,
    MapServiceContext,
    PresignedPutUrlResponse,
    TileResponse,
    TilesResponse,
    UploadRequest
} from "../MapServiceContext";
import {APIResponse, AuthData} from "../APIServiceTypes";
import axios, {AxiosError, AxiosResponse} from "axios";


function authHeader(auth: AuthData) {
    return {headers: {'Authorization': `Bearer ${auth.authToken}`}};
}

const mapServiceContextImpl: MapServiceContext = {

    removeTile(request: IdRequest, authData: AuthData): void {
        axios.post(process.env.REACT_APP_API_HOST + "/remove-tile", request, authHeader(authData))
            .catch((err: AxiosError) => {
                throw new Error("A Problem occurred removing the Tile: " + request.id)
            })
    },

    amendTile(request: AmendTileRequest, auth: AuthData): Promise<TileResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/amend-tile", request, authHeader(auth))
            .then((res: AxiosResponse<TileResponse>) => {
                return res.data
            }).catch((err: AxiosError) => {
                throw new Error("Not Yet Implemented!");
            })
    },

    fetchTiles(authData: AuthData): Promise<TilesResponse> {
        return axios.get(process.env.REACT_APP_API_HOST + "/tiles/default", authHeader(authData))
            .then((res: AxiosResponse<TilesResponse>) => {
                return res.data
            }).catch((err: AxiosError) => {
                console.error(err);
                throw new Error("Error Handling not implemented")
            })
    },

    fetchPresignedPutUrl(request: FetchPresignedPutUrlRequest, auth: AuthData): Promise<PresignedPutUrlResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/presigned-upload-url",
            request,
            authHeader(auth)
        ).then((res: AxiosResponse<PresignedPutUrlResponse>) => {
                return {...res.data, errorMessages: []}
            }
        ).catch((err: AxiosError<APIResponse>) => {
            throw new Error("Not Yet Implemented!");
        })
    },

    uploadFile(request: UploadRequest): void {
        axios.put(request.presignedUrl, request.file, {
            headers: {'Content-Type': request.file.type}
        }).then((res: AxiosResponse) => {
            console.debug("Upload complete...");
        }).catch((err: AxiosError) => {
            console.error(err);
            throw new Error("An error occurred!")
        });
    },

    commitTile(request: CommitTileRequest, auth: AuthData): Promise<TileResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/commit-tile", request, authHeader(auth))
            .then((res: AxiosResponse<TileResponse>) => {
                return res.data
            }).catch((err: AxiosError) => {
                throw new Error("Not Yet Implemented!");
            })
    }

}

export default mapServiceContextImpl;