import {ArmyHeader, MobileEntity} from "../../services/RealmServiceContext";
import {Tooltip} from "antd";

interface ArmySizeIconProps {
    mob: MobileEntity | ArmyHeader
}

const MobIcon = (props: ArmySizeIconProps) => {
    const {mob} = props

    return <Tooltip title={mob.size.name}><span style={{cursor:"pointer"}}  dangerouslySetInnerHTML={{__html: mob.size.symbol}}/></Tooltip>

}

export default MobIcon