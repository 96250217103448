import {Col, Drawer, Row} from "antd";
import UserActionMenu from "./components/UserActionMenu";
import GameBoard from "./components/GameBoard";
import ConfirmEmail from "./ConfirmEmail";
import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {selectedRealmIdState} from "./atoms";
import useWebsocket from "./hooks/useWebsocket";
import useReferenceData from "./hooks/UseReferenceData";
import RealmSelectionCarousel from "./components/RealmSelectionCarousel";

interface WindowSize {
    innerWidth: number,
    innerHeight: number
}

function getWindowSize(): WindowSize {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

const ContentWindow = (props: { renderCount: number }) => {
    useWebsocket();
    const selectedRealmId = useRecoilValue<string>(selectedRealmIdState)
    useReferenceData({realmId: selectedRealmId});
    const [windowSize, setWindowSize] = React.useState<WindowSize>(getWindowSize());
    const [contentHeight, setContentHeight] = React.useState<number>(0)
    const [showConfirmEmail, setShowConfirmEmail] = useState<boolean>(false);

    // function handleAssignFlag(base64FlagData: string) {
    //     if (authDetail.sessionIsActive()) {
    //         apiContext.assignUserFlag({base64FlagData, authData: authDetail.authData});
    //     }
    // }

    useEffect(() => {
        if (window.location.href.includes("?confirm-email=true&"))
            setShowConfirmEmail(true);
    }, [window.location.href])

    useEffect(() => {
        setContentHeight(windowSize.innerHeight - 57)
    }, [windowSize])

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    return <>

        <Row><Col span={18}><UserActionMenu/></Col></Row>
        {selectedRealmId === "" && <RealmSelectionCarousel/>}
        {/*todo this should be closing the game board on logout*/}
        {selectedRealmId !== "" && <GameBoard contentHeight={contentHeight}/>}

        <Drawer placement={"top"} height={"100VW"} destroyOnClose={true} footer={null}
                title={"Confirm Your Email"}
                open={showConfirmEmail}
                onClose={() => setShowConfirmEmail(false)}>
            <ConfirmEmail/>
        </Drawer>
    </>
}

export default ContentWindow