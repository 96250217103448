import {Form} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useContext} from "react";
import {JoinKingdomRequest, KingdomDTOResponse, KingdomServiceContext} from "../../services/KingdomServiceContext";
import {KingdomAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {weSentABird} from "../../services/APIResponseHandler";
import FormFooter from "../utility/FormFooter";

const JoinKingdomForm = (props: { kingdom: KingdomDTOResponse, onFinished: () => void }) => {
    const authDetail: AuthDetail = useAuth();
    const [loading, setLoading] = React.useState<boolean>(false);
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)
    const [joinKingdomRequest, setJoinKingdomRequest] = React.useState<JoinKingdomRequest>({
        id: props.kingdom.id,
        message: ""
    })


    function joinKingdom() {
        if (props.kingdom) {
            setLoading(true);
            weSentABird({message: "We've sent our fastest messenger to convene with the rulers of " + props.kingdom.name + " asserting they welcome us to their ranks!"})
            kingdomService.joinKingdom(joinKingdomRequest, authDetail.authData)
                .then(r => {
                    setLoading(false);
                    props.onFinished()
                })
        }
    }

    return <Form layout={"vertical"}>
        <Form.Item label={"Message to the Rulers of " + props.kingdom.name}>
            <TextArea value={joinKingdomRequest.message}
                      onChange={e => setJoinKingdomRequest({...joinKingdomRequest, message: e.target.value})}
                      rows={4}/>
        </Form.Item>
        <FormFooter loading={loading} onCancel={props.onFinished} onOk={joinKingdom}/>
    </Form>
}

export default JoinKingdomForm