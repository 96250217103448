import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {Select} from "antd";


function RangeSelector(props: { value: number, onChange: (v: number) => void }) {

    return <FormItem>
        <FormItemLabel required={true} label={"Range"} prefixCls={""}/>
        <Select value={props.value} onChange={(v) => props.onChange(v)}>
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={15}>15</Select.Option>
        </Select>
    </FormItem>

}

export default RangeSelector;