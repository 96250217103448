import {MilitaryUnitResponse, UnitRequest} from "../../../services/ReferenceServiceContext";
import {Button, Image, Modal, Space, Table} from "antd";
import React, {useState} from "react";
import {BiPlus} from "react-icons/bi";
import UnitForm from "./UnitForm";
import {MdEdit} from "react-icons/md";
import ResourcesBlock from "../../resources/ResourcesBlock";
import ImageUploader from "../../utility/ImageUploader";

const columns = [
    {title: 'Actions', dataIndex: 'actions', key: 'actions'},
    {title: 'Image', dataIndex: 'image', key: 'image'},
    {title: 'Class', dataIndex: 'type', key: 'type'},
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {title: 'Armor', dataIndex: 'armor', key: 'armor'},
    {title: 'Shield', dataIndex: 'shield', key: 'shield'},
    {title: 'Primary Weapon', dataIndex: 'primaryWeapon', key: 'primaryWeapon'},
    {title: 'Secondary Weapon', dataIndex: 'secondaryWeapon', key: 'secondaryWeapon'},
    {title: 'Costs', dataIndex: 'costs', key: 'costs'},
    {title: 'Upkeep', dataIndex: 'upkeep', key: 'upkeep'},
]

const emptyUnit: UnitRequest = {
    id: undefined,
    classId: "",
    name: "",
    armor: undefined,
    shield: undefined,
    primaryWeapon: undefined,
    secondaryWeapon: undefined,
}

function unitRequestOf(unit: MilitaryUnitResponse): UnitRequest {
    return {
        ...unit,
        classId: unit.classification.id,
        armor: unit.armor?.id,
        shield: unit.shield?.id,
        primaryWeapon: unit.primaryWeapon?.id,
        secondaryWeapon: unit.secondaryWeapon?.id
    }
}

function UnitTable(props: { units: MilitaryUnitResponse[], loading: boolean }) {
    const [selectedUnit, setSelectedUnit] = useState<UnitRequest | undefined>(undefined);


    return <>
        <Button icon={<BiPlus/>} type={"primary"} onClick={() => setSelectedUnit(emptyUnit)}>New Unit</Button>
        {selectedUnit &&
            <Modal open={true}
                   footer={false}
                   onCancel={() => setSelectedUnit(undefined)}
                   title={selectedUnit.id ? "Edit " + selectedUnit.name : "New Unit"}
            >
                <UnitForm onFinishCallback={() => setSelectedUnit(undefined)} unit={selectedUnit}/>
            </Modal>}
        <Table loading={props.loading}
               columns={columns}
               dataSource={props.units.map((unit) => {
                   return {
                       ...unit,
                       actions:
                           <Space>
                               <Button onClick={() => setSelectedUnit(unitRequestOf(unit))} size={"small"}
                                       icon={<MdEdit/>}/>
                               <ImageUploader fileId={`assets/units/${unit.id}.png`}/>
                           </Space>
                       ,
                       costs: <ResourcesBlock showZeroValues={false} resources={unit.costs}/>,
                       key: unit.id,
                       armor: unit.armor?.name,
                       shield: unit.shield?.name,
                       primaryWeapon: unit.primaryWeapon?.name,
                       secondaryWeapon: unit.secondaryWeapon?.name,
                       image: <Image src={`${process.env.REACT_APP_ASSET_HOST}/assets/units/${unit.id}.png`}
                                     height={150}/>
                   }
               })}
        /></>

}

export default UnitTable;