import {Html} from "react-konva-utils";
import {Coordinate, MobileEntity} from "../../services/RealmServiceContext";
import React from "react";
import {Group} from "react-konva";
import MovingMOBDetail from "./MovingMOBDetail";
import {useRecoilBridgeAcrossReactRoots_UNSTABLE} from "recoil";

const MobHelp = (props: { mousePosition: Coordinate, mob: MobileEntity }) => {
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()

    return <Group id={"mob-help-layer"} x={props.mousePosition.x + 40} y={props.mousePosition.y + 40}>

        <Html>
            <RecoilBridge>
                <MovingMOBDetail mob={props.mob}/>
            </RecoilBridge>
        </Html>

    </Group>

}

export default MobHelp