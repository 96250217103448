import {Cost} from "../../services/RealmServiceContext";
import {Space} from "antd";
import ResourceTag from "./ResourceTag";


const ResourcesBlock = (props: { showZeroValues: boolean, resources: Cost[]}) => {

    return <Space>
        {/*<DebugData  info={props}/>*/}
        {props.resources && props.resources
            .filter((cost: Cost) => cost !== undefined && cost !== null)
            .sort((a, b) => a.resourceType < b.resourceType ? -1 : a.resourceType > b.resourceType ? 1 : 0)
            .filter((cost: Cost) => props.showZeroValues || cost.quantity !== 0)
            .map(cost =>
                <ResourceTag key={"resource-" + cost.resourceType} name={cost.resourceType} quantity={cost.quantity}/>
            )}
    </Space>

}

export default ResourcesBlock