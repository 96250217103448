import {ClassRequest} from "../../../services/ReferenceServiceContext";
import {Form, InputNumber} from "antd";
import FreeformShortTextField from "../form-components/FreeformShortTextField";
import React, {useState} from "react";
import CostForm from "../form-components/CostForm";
import TextArea from "antd/es/input/TextArea";
import SaveButton from "../form-components/SaveButton";
import useReferenceServiceContext from "../useReferenceServiceContext";
import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import DebugData from "../../DebugData";

function ClassForm(props: { onFinishCallback: () => void, class: ClassRequest }) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [classRequest, setClassRequest] = useState<ClassRequest>({...props.class});

    function save() {
        refService.defineClass(classRequest, authDetail.authData)
            .then(() => {
                props.onFinishCallback();
            }).catch(() => {
            console.error("Error saving class: " + classRequest.name);
        })
    }

    return <Form>
        <FreeformShortTextField label={"Name"} value={classRequest.name || classRequest.unitType}
                                onChange={(v: string) => setClassRequest({...classRequest, name: v})}/>
        <FormItem>
            <FormItemLabel prefixCls={""} required={true} label={"Description"}/>
            <TextArea value={classRequest.description}
                      onChange={(v) => setClassRequest({...classRequest, description: v.target.value})}/>
        </FormItem>
        <FormItem>
            <FormItemLabel prefixCls={""} required={true} label={"Wages"}/>
            <InputNumber value={classRequest.wages}
                         onChange={(v) => setClassRequest({...classRequest, wages: v || 0})}/>
        </FormItem>
        <CostForm costs={classRequest.trainingCosts}
                  setCosts={(costs) => setClassRequest({...classRequest, trainingCosts: costs})}/>
        <SaveButton save={save}/>
        <DebugData info={classRequest}/>
    </Form>;
}

export default ClassForm;