import {ArmorRequest, ArmorResponse} from "../../../services/ReferenceServiceContext";
import React, {useState} from "react";
import {BiPlus} from "react-icons/bi";
import {Avatar, Button, Modal, Space, Table} from "antd";
import {MdEdit} from "react-icons/md";
import ResourcesBlock from "../../resources/ResourcesBlock";
import ArmorForm from "./ArmorForm";
import ImageUploader from "../../utility/ImageUploader";


const columns = [
    {title: 'Actions', dataIndex: 'actions', key: 'actions',},
    {title: 'Image', dataIndex: 'image', key: 'image'},
    {title: 'Name', dataIndex: 'name', key: 'name',},
    {title: 'Defense', dataIndex: 'defense', key: 'defense',},
    {title: 'Costs', dataIndex: 'costs', key: 'costs',}
]

const emptyArmor: ArmorRequest = {
    id: undefined,
    name: "",
    defense: "",
    costs: [],
    requiredTech: []
};

function ArmorTable(props: { loading: boolean, armor: ArmorResponse[] }) {
    const [selectedArmor, setSelectedArmor] = useState<ArmorRequest | undefined>(undefined);

    return <>
        <Button type={"primary"} icon={<BiPlus/>} onClick={() => setSelectedArmor(emptyArmor)}>Add Armor</Button>

        {selectedArmor &&
            <Modal open={true}
                   footer={false}
                   onCancel={() => setSelectedArmor(undefined)}
                   title={selectedArmor.id ? "Edit " + selectedArmor.name : "New Armor"}
            >
                <ArmorForm onFinishCallback={() => setSelectedArmor(undefined)}
                           armor={selectedArmor}/>
            </Modal>}

        <Table loading={props.loading}
               columns={columns}
               dataSource={props.armor
                   .map((a: ArmorResponse) => {
                       return {
                           ...a,
                           image: <Avatar shape={"square"}
                                          src={`${process.env.REACT_APP_ASSET_HOST}/assets/armor/${a.id}.png`}/>,
                           actions:
                               <Space>
                                   <Button onClick={() => setSelectedArmor(a)} size={"small"} icon={<MdEdit/>}/>
                                   <ImageUploader fileId={`assets/armor/${a.id}.png`}/>
                               </Space>
                           ,
                           costs: <ResourcesBlock showZeroValues={false} resources={a.costs}/>
                       }

                   })}/>
    </>
}

export default ArmorTable;