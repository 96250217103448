import {Resource} from "../../../services/RealmServiceContext";
import {Select} from "antd";
import React from "react";
import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";

function ResourceSelector(props: { value: Resource, onChangeCallback: (v: Resource) => void }) {

    return <FormItem>
        <FormItemLabel prefixCls={""} label={"Resource"}/>
        <Select value={props.value} onChange={(v) => {
            props.onChangeCallback(v)
        }}>
            <Select.Option value={Resource.FOOD} label={"Food"}>Food</Select.Option>
            <Select.Option value={Resource.GOLD} label={"Gold"}>Gold</Select.Option>
            <Select.Option value={Resource.ORE} label={"Ore"}>Ore</Select.Option>
            <Select.Option value={Resource.STONE} label={"Stone"}>Stone</Select.Option>
            <Select.Option value={Resource.WOOD} label={"Wood"}>Wood</Select.Option>
        </Select>
    </FormItem>
}


export default ResourceSelector;