import useReferenceServiceContext from "../reference-data/useReferenceServiceContext";
import React, {useEffect, useState} from "react";
import {MilitaryUnitResponse} from "../../services/ReferenceServiceContext";
import {Skeleton} from "antd";
import MilitaryUnitDetailCard from "./MilitaryUnitDetailCard";


function MilitaryUnitDetail(props: { militaryUnitId: string }): JSX.Element {
    const [authDetail, refService] = useReferenceServiceContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [militaryUnitDetail, setMilitaryUnitDetail] = useState<MilitaryUnitResponse | undefined>()

    useEffect(() => {
        setLoading(true);
        refService.militaryUnitDetail(props.militaryUnitId, authDetail.authData)
            .then((m) => {
                    setLoading(false)
                    setMilitaryUnitDetail(m)
                }
            ).catch((e) => {
            setLoading(false);
            console.error(e)
        })

    }, [props.militaryUnitId]);

    return <Skeleton loading={loading}>
        {militaryUnitDetail && <MilitaryUnitDetailCard unit={militaryUnitDetail}/>}
    </Skeleton>

}

export default MilitaryUnitDetail