import {Col, Form, Input, Row, Space, Tooltip} from "antd";
import React, {useContext} from "react";
import {AuthServiceContext, RegistrationRequest, UserRegistrationRequest} from "../../services/AuthServiceContext";
import {AuthAPI} from "../../App";
import FormItem from "antd/es/form/FormItem";
import NetworkCallButton from "../NetworkCallButton";


const RegistrationForm = () => {
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")
    const [loading, setLoading] = React.useState<boolean>(false)
    const authContext = useContext<AuthServiceContext>(AuthAPI);
    const [userProfile, setUserProfile] = React.useState<UserRegistrationRequest>({
        username: "",
        password: "",
        email: ""
    })

    function register() {
        setLoading(true);
        authContext
            .register(new RegistrationRequest(userProfile.username, userProfile.email, userProfile.password))
            .then(r => {
                setLoading(false);
            }).catch((r) => {
            setLoading(false);
        })
    }

    return <>
        <h2 style={{ letterSpacing:"2px", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", color: "#FFF"}}>Sign Up</h2>
        <Space direction={"vertical"}>
            <Row>
                <Col span={24}>
                    <Form layout={"vertical"}>
                        <FormItem>
                            <Tooltip title={"A Public Name other rulers will address you by"}>
                                <Input placeholder={"Ruler Name"}
                                       onChange={(e) => setUserProfile({...userProfile, username: e.target.value})}
                                       value={userProfile.username}/>
                            </Tooltip>
                        </FormItem>
                        <FormItem>
                            <Tooltip title={"We will never share your email address with anyone"}>
                                <Input placeholder={"Email Address"}
                                       onChange={(e) => setUserProfile({...userProfile, email: e.target.value})}
                                       value={userProfile.email}/>
                            </Tooltip>
                        </FormItem>
                        <FormItem>
                            <Input placeholder={"Password"} type={"password"} value={userProfile.password}
                                   onChange={(e) => setUserProfile({...userProfile, password: e.target.value})}/>
                        </FormItem>
                        <FormItem>
                            <Input placeholder={"Confirm Password"} type={"password"} value={confirmPassword}
                                   onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </FormItem>
                        <FormItem>
                            <NetworkCallButton idleText={"Sign Up"} loading={loading} onClick={register}/>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        </Space>
    </>
}

export default RegistrationForm