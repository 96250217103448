import {ErrorResponse} from "../services/RealmServiceContext";
import {authHeaderFrom, ErrorReportRequest} from "../services/APIServiceTypes";
import React from "react";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Form, message, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import useAuth, {AuthDetail} from "../hooks/useAuth";

function ReportProblemForm(props: { errorResponse: ErrorResponse }) {
    const [showProblemForm, setShowProblemForm] = React.useState<boolean>(true);
    const authDetail: AuthDetail = useAuth();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorReport, setErrorReport] = React.useState<ErrorReportRequest>({
        descriptionOfTheProblem: "",
        attemptedAction: "",
        errorMessage: props.errorResponse.errors.join("\n"),
        errorResponseFromSubmission: props.errorResponse,
    })

    function submitForm() {
        console.debug("Submitting this error Report:", errorReport);
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_HOST}/report-problem`, errorReport
            , authHeaderFrom(authDetail.authData))
            .then((res: AxiosResponse<any>) => {
                setLoading(false);
                setShowProblemForm(false);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setLoading(false);
                message.error("Uh Oh! There was a problem submitting your Problem Report. I'm really sorry about this. Please submit the error via discord if you can.");
                throw new Error("Something went wrong submitting the problem report!", {cause: err});
            })
    }

    return <Modal title={"Report a Problem"}
                  destroyOnClose={true}
                  open={showProblemForm}
                  onCancel={() => setShowProblemForm(false)}
                  onOk={submitForm}
                  okButtonProps={{disabled: loading}}>
        <Form layout="vertical"
        >
            <Form.Item label="What were you trying to do? And what did you expect to happen?">
                <TextArea rows={4} value={errorReport.attemptedAction}
                          onChange={(v) => setErrorReport({...errorReport, attemptedAction: v.target.value})}/>
            </Form.Item>
            <Form.Item label="What did happen?">
                <TextArea rows={4}
                          value={errorReport.descriptionOfTheProblem}
                          onChange={(v) => setErrorReport({...errorReport, descriptionOfTheProblem: v.target.value})}/>
            </Form.Item>
            <Form.Item label="Do you see any error Messages? If so what are they?">
                <TextArea rows={4}
                          value={errorReport.errorMessage}
                          onChange={(v) => setErrorReport({...errorReport, descriptionOfTheProblem: v.target.value})}/>
            </Form.Item>
        </Form>
    </Modal>

}

export default ReportProblemForm;