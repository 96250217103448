import React, {useEffect} from "react";
import {Avatar, Button, Divider, Space, Spin, Tooltip} from "antd";
import {MdOutlineDownloadDone} from "react-icons/md";
import {MilitaryUnitResponse} from "../../services/ReferenceServiceContext";
import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import ResourcesBlock from "../resources/ResourcesBlock";
import {ColonyDetailResponse} from "../../services/RealmServiceContext";
import {useRecoilValue} from "recoil";
import {colonyState} from "../../atoms";
import DebugData from "../DebugData";
import MilitaryUnitDetailCard from "../military/MilitaryUnitDetailCard";

interface MilitaryUnitFormControlProps {
    unit: MilitaryUnitResponse
    inputNumber: JSX.Element
}

function MilitaryUnitWidget(props: { unit: MilitaryUnitResponse }) {
    const [showDetail, setShowDetail] = React.useState<boolean>(false);

    return <>
        <Divider orientation={"left"}>
            <div style={{cursor: "pointer"}} onClick={() => setShowDetail(!showDetail)}>
                <Space>
                    <Avatar size={"small"}
                            src={`${process.env.REACT_APP_ASSET_HOST}/assets/units/${props.unit.id}.png`}/>{props.unit.name}
                </Space>
            </div>
        </Divider>
        {!showDetail && <ResourcesBlock showZeroValues={true} resources={props.unit.costs}/>}
        {showDetail && <MilitaryUnitDetailCard unit={props.unit}/>}
    </>;
}

function MilitaryUnitFormControl(props: MilitaryUnitFormControlProps) {
    const [current, setCurrent] = React.useState<number>(0);
    const [inTraining, setInTraining] = React.useState<number>(0);
    const colonyDetail: ColonyDetailResponse = useRecoilValue<ColonyDetailResponse>(colonyState)

    useEffect(() => {
        setCurrent(colonyDetail
            .militia
            .filter((u) => u.unit.id === props.unit.id)
            .map((u) => u.quantity)
            .reduce((a, b) => a + b, 0)
        )
        setInTraining(colonyDetail
            .inTraining
            .filter((u) => u.unit.id === props.unit.id)
            .map((u) => u.quantity)
            .reduce((a, b) => a + b, 0)
        )
    }, [colonyDetail.inTraining, colonyDetail.militia, props.unit.classId]);

    return (<>
            <FormItem>
                <Space direction={"vertical"}>
                    <FormItemLabel prefixCls={""} label={<MilitaryUnitWidget unit={props.unit}/>}/>
                    <Space.Compact block>
                        <Tooltip title="Current">
                            <Button disabled={true} icon={<MdOutlineDownloadDone/>}>{current}</Button>
                        </Tooltip>
                        <Tooltip title="In Training">
                            <Button disabled={true}
                                    icon={<Spin size={"small"} spinning={inTraining > 0}></Spin>}>
                                {inTraining}
                            </Button>
                        </Tooltip>
                        <Tooltip title="Train">
                            {props.inputNumber}
                        </Tooltip>
                    </Space.Compact>
                </Space>
            </FormItem>
            <DebugData info={props.unit}/>
        </>
    );
}

export default MilitaryUnitFormControl;