import {ArmorResponse, MilitaryUnitResponse, WeaponResponse} from "../../services/ReferenceServiceContext";
import {Avatar, Badge, Col, Image, Layout, Row, Space, Tooltip, Typography} from "antd";
import {Header} from "antd/lib/layout/layout";
import ResourcesBlock from "../resources/ResourcesBlock";
import {Content, Footer} from "antd/es/layout/layout";
import {titleCase} from "../../utils/Toolbox";
import Paragraph from "antd/lib/typography/Paragraph";
import {Resource} from "../../services/RealmServiceContext";
import React from "react";

function ArmorDetail(props: { armor: ArmorResponse }) {
    return <Tooltip title={props.armor.name}>
        <Badge color={"geekblue"} count={props.armor.defense}>
            <Avatar
                shape={"square"}
                src={`${process.env.REACT_APP_ASSET_HOST}/assets/armor/${props.armor.id}.png`}
            />
        </Badge>
    </Tooltip>
}

function WeaponDetail(props: { weapon: WeaponResponse }) {
    return <Tooltip title={props.weapon.name}>
        <Badge color={"red"} count={props.weapon.offense}>
            <Avatar
                shape={"square"}
                src={`${process.env.REACT_APP_ASSET_HOST}/assets/weapons/${props.weapon.id}.png`}
            />
        </Badge>
    </Tooltip>
}

function MilitaryUnitDetailCard(props: { unit: MilitaryUnitResponse }) {
    return <Layout style={{border: "1px"}}>
        <Header style={{color: "#AAFFFF"}}>
            <Row>
                <Col span={8}>{props.unit.name}</Col>
                <Col span={2}/>
                <Col span={14}><ResourcesBlock showZeroValues={true} resources={props.unit.costs}/></Col>
            </Row>
        </Header>
        <Content>
            <Image src={`${process.env.REACT_APP_ASSET_HOST}/assets/units/${props.unit.id}.png`}/>
            <Space size={"large"}>
                {props.unit.primaryWeapon && <WeaponDetail weapon={props.unit.primaryWeapon}/>}
                {props.unit.secondaryWeapon && <WeaponDetail weapon={props.unit.secondaryWeapon}/>}
                {props.unit.armor && <ArmorDetail armor={props.unit.armor}/>}
                {props.unit.shield && <ArmorDetail armor={props.unit.shield}/>}
            </Space>
            <Typography.Title level={4}>{titleCase(props.unit.classification.name)}</Typography.Title>
            <Paragraph type={"secondary"}>
                {props.unit.classification.description}
            </Paragraph>
            <Paragraph>
                Wages: <ResourcesBlock showZeroValues={true} resources={[{
                resourceType: Resource.GOLD,
                quantity: props.unit.classification.wages
            }]}/>
            </Paragraph>
            <Footer children={<></>}/>
        </Content>
        {/*<Statistic title={"Class"} value={titleCase(props.unit.type)}/>*/}
    </Layout>
}

export default MilitaryUnitDetailCard;