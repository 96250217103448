import {
    ArmyHeader,
    BattleResultResponse,
    EnemyArmyHeader,
    RealmServiceContext,
    RulersResponse
} from "../../services/RealmServiceContext";
import React from "react";
import {Button, Divider, Tag} from "antd";
import {useRecoilValue} from "recoil";
import {activeRealm, rulersOfTheRealm} from "../../atoms";
import {GiSwordsEmblem} from "react-icons/gi";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {RealmDetail} from "../../services/APIServiceTypes";
import MobIcon from "./MobIcon";
import {weReceivedABird} from "../../services/APIResponseHandler";
import BattleResultMessage from "../military/BattleResultMessage";
import Paragraph from "antd/lib/typography/Paragraph";

export interface InspectEnemyArmyProps {
    army: ArmyHeader
    enemy: EnemyArmyHeader
}

export const InspectEnemyArmy = (props: InspectEnemyArmyProps) => {
    const {army, enemy} = props;
    const [loading, setLoading] = React.useState<boolean>(false)
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const realm = useRecoilValue<RealmDetail>(activeRealm)

    function notifyPlayerOfBattleResult(response: BattleResultResponse) {
        // todo embellish this message
        let victoryMessage = response.victory ? "defeated" : "was defeated by";
        let armyName = props.army.name;
        let enemyName = props.enemy.name;
        let enemyOwner = rulers[props.enemy.ownerId].name;
        let message = <>
            <Paragraph>Our army {armyName} {victoryMessage} the enemy {enemyName} led by {enemyOwner}!</Paragraph>
            <Paragraph>{response.survivalRate * 100}% of {armyName} is still able to fight.</Paragraph>
            <Paragraph>{response.averageEnemySurvivalRate * 100}% of {enemyName} is still able to fight.</Paragraph>
        </>

        weReceivedABird({message: <BattleResultMessage message={message}/>})
    }


    function attack() {
        console.debug("Attacking Army!")
        setLoading(true)
        realmService
            .attackArmy({eraId: realm.activeEraId, aggressorId: army.id, defenderId: enemy.id}, authDetail.authData)
            .then(r => {
                setLoading(false)
                notifyPlayerOfBattleResult(r)
            })
    }

    return <>
        The enemy <Tag> <MobIcon mob={props.enemy}/> <b>{enemy.name}</b></Tag>led
        by <Tag>{rulers[enemy.ownerId].name}</Tag>is within striking distance!
        <Divider/>
        The {army.size.name} <Tag>{army.name}</Tag>eagerly await your orders...<br/>
        We believe we have a probability of defeating the enemy
        of <Tag>{(enemy.probabilityOfDefeating * 100).toString().substring(0, 5) + "%"}</Tag>
        <Divider/>
        <Button disabled={loading} icon={<GiSwordsEmblem/>} size={"small"} danger={true}
                onClick={attack}>Attack</Button>
    </>
}
