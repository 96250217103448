import React, {useEffect} from "react";
import {Checkbox, Space} from "antd";
import LoginForm from "./player/LoginForm";
import RegistrationForm from "./player/RegistrationForm";

function LoginOrRegisterForm() {
    const [alreadyRegistered, setAlreadyRegistered] = React.useState<boolean>(false);
    const [backgroundImage, setBackgroundImage] = React.useState<string>("url(/assets/register-background.png)")

    useEffect(() => {
        setBackgroundImage(alreadyRegistered ? "url(/assets/login-guard.png)" : "url(/assets/register-background.png)")
    }, [alreadyRegistered]);

    return <>
        <Checkbox checked={alreadyRegistered}
                  onChange={() => setAlreadyRegistered(!alreadyRegistered)}>
            Already Registered
        </Checkbox>
        <div style={{
            backgroundRepeat: "false", backgroundImage: backgroundImage,
            backgroundSize: "100%", height: "400px", padding: "25px"
        }}>
            <Space direction={"vertical"}>
                {alreadyRegistered ? <LoginForm/> : <RegistrationForm/>}
            </Space>

        </div>
    </>;
}

export default LoginOrRegisterForm;