import {useEffect, useState} from "react";
import {ClassResponse} from "../../../services/ReferenceServiceContext";
import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {Select} from "antd";
import useReferenceServiceContext from "../useReferenceServiceContext";


function ClassSelector(props: {label: string, value: string | undefined, onChange: (v: string) => void}) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [classes, setClasses] = useState<ClassResponse[]>([]);

    useEffect(() => {
        refService.listClasses(authDetail.authData)
            .then((response) => {
                setClasses(response);
            }).catch((error) => {
            console.error(error);
        })
    }, []);

    return <FormItem>
        <FormItemLabel required={true} label={props.label} prefixCls={""}/>
        <Select value={props.value} onChange={(v) => props.onChange(v)}>
            {classes.map((classification: ClassResponse) => <Select.Option value={classification.id}>{classification.name}</Select.Option>)}
        </Select>
    </FormItem>

}

export default ClassSelector;
