import {Col, Row, Space, Statistic} from "antd";
import {RiVipCrownFill} from "react-icons/ri";
import {MdPeopleAlt} from "react-icons/md";
import ToggleHelpButton from "../ToggleHelpButton";
import React from "react";
import {ColonyDetailResponse} from "../../services/RealmServiceContext";
import {titleCase} from "../../utils/Toolbox";


const ColonyStatistics = (props: { colony: ColonyDetailResponse }) => {

    return <Row>
        <Col span={6}><Statistic title={<Space><RiVipCrownFill/>{"Ruler"}</Space>}
                                 value={props.colony.owner.name}/></Col>
        <Col span={6}><Statistic title={<Space><MdPeopleAlt/>{"Population"}</Space>}
                                 value={props.colony.detail.population}/></Col>
        <Col span={6}><Statistic title={<Space><MdPeopleAlt/>{"Size"}</Space>}
                                 value={titleCase(props.colony.detail.currentSize)}/></Col>
        <Col><ToggleHelpButton/></Col>
    </Row>
}
export default ColonyStatistics