import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {activeRealm, entityPositionsState} from "../../atoms";
import {ColonyDTOType, Coordinate, EntityPositionsResponse, MobileEntity} from "../../services/RealmServiceContext";
import {useEffect, useState} from "react";
import useAuth from "../../hooks/useAuth";

function MiniMap() {
    const authDetail = useAuth();
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const entityPositions = useRecoilValue<EntityPositionsResponse>(entityPositionsState);
    const [positionRatio, setPositionRatio] = useState<number>(0);

    useEffect(() => {
        let mapSize: number = realm.realmMap.map.length * 16;
        setPositionRatio(350 / mapSize);
    }, [realm]);

    function minimapCoords(position: Coordinate, sizeInPixels: number): { top: number, left: number } {
        const coordinate = new Coordinate(position.x * positionRatio, position.y * positionRatio);
        return {top: coordinate.y - (sizeInPixels / 2), left: coordinate.x - (sizeInPixels / 2)};
    }

    function isFriendly(c: ColonyDTOType): boolean {
        return c.ownerId === authDetail.authData.userId
            // || c.kingdomId === player (or if player is in the kingdom)
            ;
    }

    function isFriendlyMob(m: MobileEntity): boolean {
        return m.header.owner === authDetail.authData.userId
            // || c.kingdomId === player (or if player is in the kingdom)
            ;
    }

    return <div style={{height: "400px"}}>
        <div style={{position: "relative"}}>
            <img key={"minimap"} width={350} style={{position: "absolute", top: 0, left: 0}}
                 src={`${process.env.REACT_APP_ASSET_HOST}/assets/maps/${realm.id}-minimap.png`}/>
            {entityPositions.colonies
                .map(c => {
                    return <div key={c.id}>
                        {isFriendly(c) &&
                            <img width={c.visibilityRadius * positionRatio} height={c.visibilityRadius * positionRatio}
                                 style={{
                                     ...minimapCoords(c.position, c.visibilityRadius * positionRatio),
                                     position: "absolute",
                                     opacity: "25%"
                                 }}
                                 src={`assets/minimap-icons/visibility.png`}
                            />}
                        <img alt={c.name} width={c.size / 16 * 5} height={c.size / 16 * 5}
                             src={`assets/minimap-icons/colony.png`}
                             style={{
                                 ...minimapCoords(c.position, c.size / 16 * 5),
                                 position: "absolute",
                             }}/>
                    </div>
                })
            }
            {entityPositions.mobs
                .map(m => {
                    return <div key={m.header.id}>
                        {isFriendlyMob(m) &&
                            <img width={m.header.visibilityRadius * positionRatio}
                                 height={m.header.visibilityRadius * positionRatio}
                                 style={{
                                     ...minimapCoords(m.header.vector.position, m.header.visibilityRadius * positionRatio),
                                     position: "absolute",
                                     opacity: "25%"
                                 }}
                                 src={`assets/minimap-icons/visibility.png`}
                            />}
                        <img alt={m.header.name} width={3} height={3}
                             src={`assets/minimap-icons/mob.png`}
                             style={{
                                 ...minimapCoords(m.header.vector.position, 3),
                                 position: "absolute",
                                 opacity: "75%"
                             }}/>
                    </div>

                })
            }
        </div>
    </div>
}

export default MiniMap;