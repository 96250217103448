import {useRecoilState, useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import React, {useEffect, useState} from "react";
import {
    ColonyDetailResponse,
    ColonyDTOType,
    EntityPositionsResponse,
    MobileEntity,
    RealmServiceContext
} from "../../services/RealmServiceContext";
import ConstructionTab from "./ConstructionTab";
import {Space, Tabs, Tooltip} from "antd";
import {BsFileEarmarkBarGraph} from "react-icons/bs";
import {ImHammer2} from "react-icons/im";
import {activeRealm, colonyState, entityPositionsState} from "../../atoms";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import EconomyTab from "./EconomyTab";
import ColonySelector from "./ColonySelector";
import {GiCheckedShield, GiMeepleArmy, GiRallyTheTroops, GiSwordwoman, GiTiedScroll} from "react-icons/gi";
import DefensesTab from "./DefensesTab";
import TrainingTab from "./TrainingTab";
import ArmiesTab from "../military/ArmiesTab";
import ColonyStatistics from "./ColonyStatistics";
import KingdomPanel from "../kingdom/KingdomPanel";
import MessagesPanel from "../communications/MessagesPanel";

export interface Props {
    colonyId: string | null
}

function WarRoom({colonyId}: Props) {
    const authDetail: AuthDetail = useAuth();
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [colony, setColony] = useRecoilState<ColonyDetailResponse>(colonyState);
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const [armies, setArmies] = useState<MobileEntity[]>(positions.mobs.filter(m => m.header.owner === authDetail.authData.userId))
    const [colonies, setColonies] = useState<ColonyDTOType[]>()

    useEffect(() => {
        setArmies(positions.mobs.filter(m => m.header.owner === authDetail.authData.userId))
        setColonies(positions.colonies)
    },[authDetail.authData.userId, positions])

    useEffect(() => {
        if (colonyId)
            realmService.colonyDetail({colonyId: colonyId, eraId: realm.activeEraId}, authDetail.authData)
                .then((c) => {
                    console.debug("Colony: ", c)
                    setColony(c);
                })
    }, [realm.activeEraId, colonyId, realmService, authDetail.authData, setColony])


    function noArmiesHelpText() {
        if (armies.length < 1 && positions.colonies.length < 1)
            return "You have no armies, right click on the map to Field an Army!";
        else
            return <></>
    }

    return (<>
        {noArmiesHelpText()}
        <ColonySelector/>
        {colony && <ColonyStatistics colony={colony}/>}
        <Tabs type="card"
              size={"small"}
              items={[
                  {
                      id: "armies",
                      key: "armies",
                      label: (<Space><Tooltip title={"Armies"}><GiMeepleArmy/></Tooltip></Space>),
                      children: (<ArmiesTab/>)
                  },
                  {
                      id: "economy",
                      key: "economy",
                      label: (<Space><Tooltip title={"Economy"}><BsFileEarmarkBarGraph/></Tooltip></Space>),
                      children: (<EconomyTab/>)
                  },
                  {
                      id: "construction",
                      key: "construction",
                      label: (<Space><Tooltip title={"Construction"}><ImHammer2/></Tooltip></Space>),
                      children: (colony && <ConstructionTab/>)
                  },
                  {
                      id: "defenses",
                      key: "defenses",
                      label: (<Space><Tooltip title={"Defense"}><GiCheckedShield/></Tooltip></Space>),
                      children: (colony && <DefensesTab/>)
                  },
                  {
                      id: "training",
                      key: "training",
                      label: (<Space><Tooltip title={"Training"}><GiSwordwoman/></Tooltip></Space>),
                      children: (colony && <TrainingTab/>)
                  },
                  {
                      id: "kingdom",
                      key: "kingdom",
                      label: (<Space><Tooltip title={"Kingdom"}><GiRallyTheTroops/></Tooltip></Space>),
                      children: (<KingdomPanel/>),
                  },
                  {
                      id: "messages",
                      key: "messages",
                      label: (<Space><Tooltip title={"Messages"}><GiTiedScroll/></Tooltip></Space>),
                      children: (<MessagesPanel/>)
                  },
              ]}

        />
    </>)
}

export default WarRoom
/*
Call Publix Pharmacy tell them to call CVS St Lucie West and give them.
772-878-7078
 */
