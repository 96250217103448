import {ColonyHeader, MobileEntity, RealmServiceContext, RulersResponse} from "../../services/RealmServiceContext";
import React, {useEffect} from "react";
import {Button, Divider} from "antd";
import {useRecoilValue} from "recoil";
import {activeRealm, rulersOfTheRealm} from "../../atoms";
import {GiSwordsEmblem} from "react-icons/gi";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {RealmDetail} from "../../services/APIServiceTypes";
import ColonyIcon from "../colony/ColonyIcon";
import MobName from "./MobName";

export interface InspectEnemyColonyProps {
    army: MobileEntity
    colony: ColonyHeader
}

export const InspectEnemyColony = (props: InspectEnemyColonyProps) => {
    const {army, colony} = props;
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        console.debug("Rendering Inspect Enemy")
    }, [])

    function attack() {
        console.debug("Attacking Army!")
        setLoading(true);
        realmService.attackColony({
            eraId: realm.activeEraId,
            aggressorId: army.header.id,
            defenderId: colony.id
        }, authDetail.authData)
            .then(r => {
                setLoading(false);
            })
    }

    function friendlyNumber(num: number, zeroSub?: string) {
        if (num === 0)
            return zeroSub || "no"
        else
            return num.toString()
    }

    return <>
        {colony &&
            <>
                We have setup camp near <ColonyIcon variant={"dark"} colony={colony}/>
                <br/>The colony is within striking distance and the men of <MobName variant={"dark"} mob={army}/>
                are preparing for battle. Our spies tell us the colony
                has {friendlyNumber(colony.stationedArmies)} defending armies stationed inside.
                We believe if we attacked now, we would have a {colony.probabilityOfDefeating}% chance of defeating
                them.
                <Divider/>
                <Button loading={loading} icon={<GiSwordsEmblem/>} danger={true} onClick={attack}>Attack</Button>
            </>
        }
    </>
}
