import {Content} from "antd/es/layout/layout";
import {Col, Image as Img, Layout, Row} from "antd";
import {Edge, Tile} from "../../services/MapServiceContext";
import React, {useEffect} from "react";

interface Props {
    tiles: Tile[]
    file: File | null
    tile: Tile
}

export const TILE_PREFIX = `${process.env.REACT_APP_ASSET_HOST}/assets/tiles/default/`;
// todo when the TilePreviewer loads, we should download all tile assets and load them to local storage
//  this is where we should view them from when loading them into the previewer - ie: do not download them from network
//  after you have already downloaded them to the local.
//  download them all everytime the TilePreviewer is loaded, this way don't worry about cache invalidation
function TilePreviewer({tile, tiles, file}: Props) {
    const [preview, setPreview] = React.useState<string | undefined>()

    useEffect(() => {
        if (tile.id !== undefined) {
            setPreview(`${TILE_PREFIX}${tile.name}.png`)
            return
        }

        if (!file) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)

    }, [tile, file])

    function matchEdges(edge1: Edge | undefined, edge2: Edge | undefined): boolean {
        return edge1?.cp1 === edge2?.cp1 &&
            edge1?.cp2 === edge2?.cp2 &&
            edge1?.cp3 === edge2?.cp3
    }

    function shuffle(array: any[]) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    return <Layout>
        <Content style={{width: "48px"}}>
            <Row>
                <Col span={8}/>
                <Col
                    span={8}>{shuffle(tiles.filter((t: Tile) => matchEdges(t.south, tile.north))
                    .map(t => <Img
                        src={TILE_PREFIX + t.name + ".png"}/>))[0] || <Img src={"nomatch.png"}/>}
                </Col>
                <Col span={8}/>
            </Row>
            <Row>
                <Col span={8}>
                    {shuffle(tiles.filter((t: Tile) => matchEdges(t.east, tile.west)).map(t => <Img
                        src={TILE_PREFIX + t.name + ".png"}/>))[0] || <Img src={"nomatch.png"}/>}
                </Col>
                <Col span={8}>
                    <Img src={preview}/>
                </Col>
                <Col span={8}>
                    {shuffle(tiles.filter((t: Tile) => matchEdges(t.west, tile.east)).map(t => <Img
                        src={TILE_PREFIX + t.name + ".png"}/>))[0] || <Img src={"nomatch.png"}/>}
                </Col>
            </Row>
            <Row>
                <Col span={8}/>
                <Col span={8}>
                    {shuffle(tiles.filter((t: Tile) => matchEdges(t.north, tile.south)).map(t => <Img
                        src={TILE_PREFIX + t.name + ".png"}/>))[0] || <Img src={"nomatch.png"}/>}
                </Col>
                <Col span={8}/>
            </Row>
        </Content>
    </Layout>;
}

export default TilePreviewer;