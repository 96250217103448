import {Col, Image, Row} from "antd";
import React from "react";
import SettleColonyForm from "./colony/SettleColonyForm";

export interface Props {
    mobId: string
    mobName: string
}

function SettleColony(props: Props) {

    return (
        <Row>
            <Col span={"12"}>
                <Image src={`${process.env.REACT_APP_ASSET_HOST}/assets/scribe.png`} width={300}/>
            </Col>
            <Col span={"12"}>
                <SettleColonyForm mobId={props.mobId}/>
            </Col>
        </Row>
    )


}

export default SettleColony