import {ColonyEconomyResponse, EraTurnState, RealmServiceContext} from "../../services/RealmServiceContext";
import {Table} from "antd";
import React, {ReactNode, useEffect} from "react";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {activeRealm, eraTurnState} from "../../atoms";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import numeral from "numeral";

function rowClassName(record: any) {
    return {...record, background: "#CCCCCC"}
}

function renderPercentage(val: number): ReactNode {
    return numeral(val).format("0.00%")
}

function renderAccountingValue(val: number): ReactNode {
    let color = val < 0 ? "red" : "green";
    return <div style={{color: color}}>{numeral(val).format('(0,0)')}</div>;
}


const EconomyTab = () => {
    const authDetail: AuthDetail = useAuth();
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [tableData, setTableData] = React.useState<ColonyEconomyResponse[]>([])
    const era = useRecoilValue<EraTurnState>(eraTurnState)
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        if (authDetail.sessionIsActive()) {
            setLoading(true);
            realmService.economyDetail(realm.activeEraId, authDetail.authData)
                .then((r) => {
                    setLoading(false);
                    setTableData(r);
                })
        } else {
            setTableData([])
        }
    }, [era, authDetail.authData, realm.activeEraId, realmService])


    return <>
        <Table
            loading={loading}
            direction={"rtl"}
            size={"small"}
            bordered
            rowClassName={rowClassName}
            dataSource={tableData}
            columns={[{title: "Colony", key: "key", render: (val: ColonyEconomyResponse) => val.colonyName},
                {title: "Gold", dataIndex: "gold", key: "gold", render: renderAccountingValue},
                {title: "Stone", dataIndex: "stone", key: "stone", render: renderAccountingValue},
                {title: "Ore", dataIndex: "ore", key: "ore", render: renderAccountingValue},
                {title: "Food", dataIndex: "food", key: "food", render: renderAccountingValue},
                {title: "Wood", dataIndex: "wood", key: "wood", render: renderAccountingValue},
                {
                    title: "Productivity Rate",
                    dataIndex: "productivityRate",
                    key: "productivityRate",
                    render: renderPercentage
                }
            ]}
        /></>

}

export default EconomyTab

/*

*/