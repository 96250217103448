import {TransferQuantity, UnitRetainer} from "../../services/RealmServiceContext";
import React, {useEffect, useState} from "react";
import MilitaryUnitTransferSlider from "./MilitaryUnitTransferSlider";

interface TransferFormParams {
    unitRetainers: UnitRetainer[]
    stageForTransfer: (transferQuantities: TransferQuantity) => void
}


function TransferForm(props: TransferFormParams) {
    const [transferQuantities, setTransferQuantities] = useState<TransferQuantity[]>([])
    const [availableQuantities, setAvailableQuantities] = useState<TransferQuantity[]>([])

    useEffect(() => {
        setAvailableQuantities(transferQuantities)
    }, [transferQuantities]);

    useEffect(() => {
        setTransferQuantities(props.unitRetainers
            .flatMap(u => u.transferableUnits)
            .map(tq => {
                console.debug(tq)
                return tq
            })
            .sort((a, b) => a.unit?.name?.localeCompare(b.unit?.name || "") || 0)
        )
    }, [props.unitRetainers]);

    return <>
        {availableQuantities.map(tq =>
            <MilitaryUnitTransferSlider
                key={tq.unitTypeId.id}
                transferQuantity={tq}
                available={tq.quantity}
                setTransferQuantity={props.stageForTransfer}
            />
        )}
    </>;
}

export default TransferForm;