import {Modal, Space} from "antd";
import {InspectEnemyColony} from "./InspectEnemyColony";
import React from "react";
import {ColonyHeader, MobileEntity} from "../../services/RealmServiceContext";

const EnemyColonyInspectorModal = (props:{open:boolean, inspectingArmy:MobileEntity, selectedColony:ColonyHeader, onCancel:()=>void}) => {


    return <Modal title={<Space>{props.selectedColony.name}</Space>}
                  footer={false}
                  open={props.open}
                  onCancel={props.onCancel}>
        <InspectEnemyColony
            army={props.inspectingArmy}
            colony={props.selectedColony}/>
    </Modal>
}

export default EnemyColonyInspectorModal