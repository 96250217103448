import {useRecoilValue} from "recoil";
import {EraTurnState, TimeOfDay} from "../../services/RealmServiceContext";
import {eraTurnState} from "../../atoms";
import {Popover, Spin, Statistic, Tag} from "antd";
import {BsMoonFill, BsSun} from "react-icons/bs";
import React, {useEffect, useState} from "react";

const {Countdown} = Statistic;

const EraStatus = () => {
    const eraStatus = useRecoilValue<EraTurnState>(eraTurnState)
    const [finished, setFinished] = useState<boolean>(false)

    useEffect(() => {
        console.debug("EraStatus: ", eraStatus);
        setFinished(eraStatus && Date.now() > eraStatus.nextTurn);
    }, [eraStatus])

    function tooltipTitle() {
        return eraStatus.timeOfDay === TimeOfDay.DAY ? "Day" : "Night";
    }

    function tooltipContent() {
        return "Depending on the time of day, certain units have penalties or bonuses for attack and defense.";
    }

    return <>
        {eraStatus &&
            <Popover title={tooltipTitle()} content={tooltipContent()}>
            <Tag color={eraStatus.timeOfDay === TimeOfDay.DAY ? "#80801d" : "#0f133d"}>
                {!finished && <Countdown
                    prefix={eraStatus.timeOfDay === TimeOfDay.DAY
                        ? <BsSun color={"gold"}/>
                        : <BsMoonFill color={"#CCCCCC"}/>}
                    onFinish={() => setFinished(true)}
                    valueStyle={{color:"#CCCCCC", fontSize: "small"}}
                    value={eraStatus.nextTurn}
                />}
                {finished && <Spin style={{color:"#CCCCCC"}} size={"small"}/>}
            </Tag>
            </Popover>
        }
    </>
}

export default EraStatus