import {Layer} from "react-konva";
import {ColonyDTOType, EntityPositionsResponse} from "../../../services/RealmServiceContext";
import Colony from "../../Colony";
import React from "react";
import {useRecoilValue} from "recoil";
import {entityPositionsState} from "../../../atoms";


function ColonyLayer() {
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState);

    return <Layer key={"colonies-layer"} id={"colonies-layer"} visible={true}>
        {positions && positions.colonies.map((c: ColonyDTOType) => <Colony key={c.id} colony={c}/>)}
    </Layer>

}

export default ColonyLayer;