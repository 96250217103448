import {EntityStatus, MobileEntity, RealmServiceContext} from "../../services/RealmServiceContext";
import {Button, Popconfirm, Space, Tooltip} from "antd";
import {TiCompass} from "react-icons/ti";
import {FaRoute} from "react-icons/fa";
import React from "react";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {
    activeRealm,
    mobileEntityPropertyVisibilityState,
    selectedMobileEntityState,
    UserAction,
    userActionState
} from "../../atoms";
import {titleCase} from "../../utils/Toolbox";
import SettleColonyButton from "../colony/SettleColonyButton";
import {MobileEntityPropertyVisibility} from "./Mob";


// This is ugly stuff here, but needed...
// the icon starts out pointing 45deg, so reset it to 0 then rotate to the heading.
// 0deg is EAST in this case.
// backend gives a heading based on a counter-clockwise rotation, so we need to subtract the heading from
// the compassRotationModifier in order to get to the correct (clockwise) rotation that CSS performs.
const compassRotationModifier: number = 45;
const ArmyCommandButtons = (props: { army: MobileEntity }) => {
    const authDetail: AuthDetail = useAuth();
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [selectedMob, setSelectedMob] = useRecoilState<MobileEntity>(selectedMobileEntityState)
    const setUserAction = useSetRecoilState<UserAction>(userActionState)
    const [mobProps, setMobProps] = useRecoilState<MobileEntityPropertyVisibility>(mobileEntityPropertyVisibilityState)
    const rotation = compassRotationModifier - props.army.header.vector.velocity.heading;
    const status: string = titleCase(props.army.header.status);
    const [loading, setLoading] = React.useState<boolean>(false);

    function handleCancelMove(army: MobileEntity) {
        setLoading(true)
        realmService.stop({realmId: realm.id, authData: authDetail.authData, mobId: army.header.id})
            .then(r => setLoading(false))
    }

    function handleShowRoute(army: MobileEntity) {
        if (army.header.id === selectedMob.header.id)
            setMobProps({...mobProps, path: !mobProps.path})
        else {
            setSelectedMob(army);
            setMobProps({...mobProps, path: true})
        }
    }

    function handleMoveMob(army: MobileEntity) {
        setUserAction(UserAction.MOVING);
        setSelectedMob(army);
    }

    if (props.army.header.status === EntityStatus.MOVING) {
        return <Space><Popconfirm title={"New Orders"}
                                  onConfirm={() => handleCancelMove(props.army)}
                                  okText={"Stop"}
                                  showCancel={false}>
            <Button loading={loading} type={"text"}
                    icon={<TiCompass size={23} style={{transform: 'rotate(' + rotation + 'deg)'}}/>}>
                {status}
            </Button>
        </Popconfirm>
            <Tooltip title={"Show Route"}>
                <Button shape={"circle"} size={"small"} onClick={() => handleShowRoute(props.army)} icon={<FaRoute/>}/>
            </Tooltip>
            <SettleColonyButton mobId={props.army.header.id} mobName={props.army.header.name}/>
        </Space>
    } else {
        return <Space><Popconfirm title={"New Orders"}
                                  onConfirm={() => handleMoveMob(props.army)}
                                  okText={"March"}
                                  showCancel={false}>
            <Button type={"text"}>{status}</Button>
        </Popconfirm>
            <SettleColonyButton mobId={props.army.header.id} mobName={props.army.header.name}/>
        </Space>
    }
}

export default ArmyCommandButtons