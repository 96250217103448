import {MapServiceContext, TerrainType, Tile} from "../../services/MapServiceContext";
import {MapAPI} from "../../App";
import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Row, Select, Space, Typography, Upload, UploadFile} from "antd";
import {Content} from "antd/es/layout/layout";
import TilePreviewer from "./TilePreviewer";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import TilesetManager from "./TilesetManager";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import NetworkCallButton from "../NetworkCallButton";
import {AiOutlineEye} from "react-icons/ai";

const {Title} = Typography;


function MapConfiguration() {
    const authDetail: AuthDetail = useAuth();
    const mapContext = React.useContext<MapServiceContext>(MapAPI);
    const [tile, setTile] = React.useState<Tile>({})
    const [tileList, setTileList] = React.useState<Tile[]>([])
    const [file, setFile] = React.useState<File | null>(null);
    const [presignedPutUrl, setPresignedPutUrl] = React.useState<string | null>(null);
    const [selectedTile, setSelectedTile] = React.useState<Tile | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const terrainTypeOptions = [TerrainType.FOREST, TerrainType.GRASS, TerrainType.MOUNTAIN, TerrainType.SEA, TerrainType.BLANK]
        .map((t: TerrainType) => {
            return (<Select.Option key={t}>{t}</Select.Option>)
        });

    useEffect(() => {
        console.debug("Selected Tile: " + selectedTile?.id)
        if (selectedTile !== undefined) {
            setTile(selectedTile)
        }
    }, [selectedTile])


    function fetchPresignedUploadUrlForFile(f: RcFile) {
        console.debug(f);
        mapContext.fetchPresignedPutUrl({contentType: f.type, fileName: f.name}, authDetail.authData)
            .then((r) => {
                setPresignedPutUrl(r.presignedUrl)
            })
    }

    function isCompleted(tile: Tile): boolean {
        return tile.center !== undefined &&
            tile.west?.cp1 !== undefined &&
            tile.west.cp2 !== undefined &&
            tile.west.cp3 !== undefined &&
            tile.north?.cp1 !== undefined &&
            tile.north.cp2 !== undefined &&
            tile.north.cp3 !== undefined &&
            tile.south?.cp1 !== undefined &&
            tile.south.cp2 !== undefined &&
            tile.south.cp3 !== undefined &&
            tile.east?.cp1 !== undefined &&
            tile.east.cp2 !== undefined &&
            tile.east.cp3 !== undefined;
    }

    useEffect(() => {
        mapContext.fetchTiles(authDetail.authData)
            .then(r => setTileList(r.tiles))
    }, [mapContext, authDetail.authData])

    useEffect(() => {
        console.debug(tile);
    }, [tile])


    function uploadFile() {
        if (file !== null && presignedPutUrl !== null) {
            setLoading(true);
            mapContext.uploadFile({file: file, presignedUrl: presignedPutUrl})
        } else {
            throw new Error("Either the file or the presigned URL is undefined")
        }
    }

    function commitTile() {
        if (file !== null) {
            setLoading(true);
            uploadFile();
            mapContext.commitTile({tile: tile, fileName: file.name}, authDetail.authData)
                .then(res => {
                    setTile({});
                    mapContext.fetchTiles(authDetail.authData)
                        .then(r => setTileList(r.tiles))
                    setLoading(false);
                }).catch(err => {
                console.error(err);
            })
        }
    }

    function amendTile() {
        console.debug("Amending tile: " + tile)
        setLoading(true);
        mapContext.amendTile({tile: tile}, authDetail.authData)
            .then(res => {
                setTile(res.tile);
                setLoading(false);
            }).catch(err => {
            console.error(err);
        })
    }

    function saveButton() {
        if (selectedTile !== undefined) {
            return <NetworkCallButton idleText={"Amend Tile"} loading={loading} onClick={amendTile}/>
        } else {
            return <NetworkCallButton idleText={"Commit"} loading={loading} onClick={commitTile}/>
        }
    }


    return <>
        <Layout style={{padding: "1rem"}}>
            <Space direction={"vertical"}>
                <Row>
                    <Col span={18}>
                        <Content>
                            <Title level={2}>{tile?.name}</Title>
                            <Row>
                                <Col span={24}>
                                    <Space.Compact>
                                        <Upload accept={"image/png"}
                                                showUploadList={false}
                                                beforeUpload={(f: RcFile) => {
                                                    fetchPresignedUploadUrlForFile(f);
                                                }}
                                                onChange={(a: UploadChangeParam<UploadFile<any>>) => {
                                                    if (a.fileList.length > 1)
                                                        a.fileList.reverse().pop();
                                                }}
                                                customRequest={(options) => {
                                                    console.debug(options);
                                                    //@ts-ignore
                                                    setFile(options.file);
                                                }}>

                                            <Button icon={<AiOutlineEye/>}>Preview Tile</Button>
                                        </Upload>
                                        <Select placeholder={"Fill All Terrain Types"}
                                                value={tile.center}
                                                onChange={(e: TerrainType) => setTile({
                                                    ...tile,
                                                    center: e,
                                                    north: {...tile?.north, cp1: e, cp2: e, cp3: e},
                                                    west: {...tile?.west, cp1: e, cp2: e, cp3: e},
                                                    south: {...tile?.south, cp1: e, cp2: e, cp3: e},
                                                    east: {...tile?.east, cp1: e, cp2: e, cp3: e},
                                                })}>
                                            {terrainTypeOptions}
                                        </Select>
                                        <Select placeholder={"Center Terrain Type"}
                                                value={tile?.center}
                                                onChange={(e: TerrainType) => setTile({
                                                    ...tile,
                                                    center: e
                                                })}>
                                            {terrainTypeOptions}
                                        </Select>
                                        {saveButton()}
                                    </Space.Compact>

                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.north?.cp1}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                north: {...tile?.north, cp1: e},
                                                west: {...tile?.west, cp1: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.north?.cp2}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                north: {...tile?.north, cp2: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.north?.cp3}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                north: {...tile?.north, cp3: e},
                                                east: {...tile?.east, cp1: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.west?.cp2}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                west: {...tile?.west, cp2: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <TilePreviewer file={file} tiles={tileList} tile={tile}/>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.east?.cp2}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                east: {...tile?.east, cp2: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.south?.cp1}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                south: {...tile?.south, cp1: e},
                                                west: {...tile?.west, cp3: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.south?.cp2}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                south: {...tile?.south, cp2: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder={"Terrain Type"}
                                            value={tile?.south?.cp3}
                                            onChange={(e: TerrainType) => setTile({
                                                ...tile,
                                                south: {...tile?.south, cp3: e},
                                                east: {...tile?.east, cp3: e}
                                            })}>
                                        {terrainTypeOptions}
                                    </Select>
                                </Col>
                            </Row>
                        </Content>
                    </Col>
                    <Col span={6}>
                        <TilesetManager setSelectedTile={setSelectedTile} tiles={tileList}/>
                    </Col>
                </Row>
            </Space>
        </Layout>
    </>;
}

export default MapConfiguration;