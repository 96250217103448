import {Button, Form, Space} from "antd";
import React from "react";

const FormFooter = (props: { loading: boolean, onCancel: () => void, onOk: () => void, labels?: { cancel: string, ok: string } }) => {

    return <Form.Item>
        <Space style={{float: "right"}}>
            <Button loading={props.loading} onClick={props.onCancel}>{props.labels?.cancel || "Cancel"}</Button>
            <Button loading={props.loading} type={"primary"} onClick={props.onOk}>{props.labels?.ok || "Ok"}</Button>
        </Space>
    </Form.Item>
}

export default FormFooter