import {RealmDetail} from "../services/APIServiceTypes";
import {Col, Row, Statistic} from "antd";
import {RealmDetailResponse} from "../services/RealmServiceContext";

function RealmStats(props: { realm: RealmDetail | RealmDetailResponse }) {

    return <>
        <Row>
            <Col span={2}></Col>
            <Col span={20}>
                <Row>
                    <Col span={6}>
                        <Statistic title={"Map Size"} value={props.realm.realmMap.map.length}/>
                    </Col>
                    <Col span={6}>
                        <Statistic title={"Era"} value={props.realm.eraCount}/>
                    </Col>
                    <Col span={6}>
                        <Statistic title={"Current Turn"} value={props.realm.eraTurnState?.turn}/>
                    </Col>
                    <Col span={6}>
                        <Statistic title={"Players"} value={props.realm.users.length}/>
                    </Col>
                </Row>
            </Col>
            <Col span={2}></Col>
        </Row>
    </>
}

export default RealmStats