import {ErrorResponse} from "./RealmServiceContext";
import {Button, Divider, notification} from "antd";
import React from "react";
import ReportProblemForm from "../components/ReportProblemForm";
import BirdIcon from "../components/utility/BirdIcon";


function ErrorNotification(props: { errorResponse: ErrorResponse }) {
    const [showProblemForm, setShowProblemForm] = React.useState<boolean>(false);

    function handleReportProblemClick() {
        console.debug("Sending problem form open to true")
        setShowProblemForm(true);
    }

    return <p>
        There was a problem handling this request!
        <Divider>Errors:</Divider>
        {props.errorResponse.errors.map(e => {
            return <p>{e}</p>;
        })
        }
        <Divider>Contact Support</Divider>
        <Button onClick={handleReportProblemClick} size={"small"} type={"primary"}>Report Problem</Button>
        {showProblemForm &&
            <ReportProblemForm errorResponse={props.errorResponse}/>
        }
    </p>;
}

export function handleError(errorResponse: ErrorResponse | undefined): void {
    if (!errorResponse) {
        notification.error({message: "An unknown error occurred!", duration: -1});
    } else {
        notification.error({message: <ErrorNotification errorResponse={errorResponse}/>, duration: -1});
    }

}

export interface Bird {
    message: React.ReactNode;
}

export function weSentABird(birdSent: Bird) {
    notification.info({placement: "bottomLeft", duration: 5, message: birdSent.message, icon: <BirdIcon/>})
}

export function weReceivedABird(birdReceived: Bird) {
    notification.success({placement: "top", message: birdReceived.message, duration: 10, icon: <BirdIcon/>})
}