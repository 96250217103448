import {
    ArmyDetailRequest,
    ArmyDetailResponse,
    ArmySizesResponse,
    AssignUserFlagRequest,
    AttackArmyRequest,
    AttackColonyRequest,
    BattleResultResponse,
    BeginEraRequest,
    CloseGatesRequest,
    ColonyDetailResponse,
    ColonyEconomyResponse,
    ColonyResponse,
    CostDetailResponse,
    EndEraRequest,
    EntityPositionsRequest,
    EntityPositionsResponse,
    EraDTO,
    ErrorResponse,
    FieldArmyRequest,
    JoinRealmRequest,
    MoveMobRequest,
    NewRealmRequest,
    NewRealmResponse, OpenGatesRequest,
    PlayerStatusRequest,
    PlayerStatusResponse,
    QueueRequest,
    QueueUnitsRequest,
    RealmDetailRequest,
    RealmDetailResponse,
    RealmServiceContext,
    RulersOfTheRealmRequest,
    RulersResponse,
    SettleColonyRequest,
    StopMobRequest,
    TransferUnitsRequest,
    TutorialResponse,
    UnitRetainer
} from "../RealmServiceContext";
import axios, {AxiosError, AxiosResponse} from "axios";
import {APIResponse, AuthData, authHeader, authHeaderFrom, AuthorizedAPIRequest, RealmHeader} from "../APIServiceTypes";
import {handleError, weSentABird} from "../APIResponseHandler";
import {prepMapForSerialization} from "../../utils/Toolbox";


export 

const realmServiceContext: RealmServiceContext = {

    stop(request: StopMobRequest): Promise<APIResponse> {
        weSentABird({message: "We have ordered the army to halt its movement, they will receive word as soon as our bird reaches them!"})
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/stop-army`, {
            realmId: request.realmId,
            mobId: request.mobId
        }, authHeader(request))
            .then((res: AxiosResponse<APIResponse>) => {
                return {errorMessages: []}
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    settleColony(request: SettleColonyRequest): Promise<ColonyResponse> {
        weSentABird({message: "We have ordered the army to settle a colony, they will receive word as soon as our bird reaches them!"})
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/settle-colony`, {
            mobId: request.mobId, realmId: request.realmId, name: request.name
        }, authHeader(request))
            .then((res: AxiosResponse<ColonyResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    moveMob(request: MoveMobRequest): Promise<APIResponse> {
        weSentABird({message: "We have ordered the army to march, they will receive word as soon as our bird reaches them!"})
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/move-mob`, {
            realmId: request.realmId,
            mobId: request.mobId,
            path: request.path
        }, authHeader(request))
            .then((res: AxiosResponse<APIResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    entityPositions(request: EntityPositionsRequest): Promise<EntityPositionsResponse> {
        if(!request.eraId)
            return Promise.resolve({colonies: [], mobs: [], errorMessages: []});
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${request.eraId}/entity-positions`, authHeader(request))
            .then((res: AxiosResponse<EntityPositionsResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    realmDetail(request: RealmDetailRequest): Promise<RealmDetailResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/realm-detail/${request.realmId}`, authHeader(request))
            .then((res: AxiosResponse<RealmDetailResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw err;
            })
    },

    listRealms(request: AuthorizedAPIRequest): Promise<RealmHeader[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/realms", authHeader(request))
            .then((res: AxiosResponse<RealmHeader[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    // joinRealm(request: JoinRealmRequest, authData: AuthData): Promise<JoinRealmResponse> {
    //     return Promise.resolve({errorMessages: ["Error Handling Not Implemented"]});
    // },

    newRealm(request: NewRealmRequest): Promise<NewRealmResponse> {
        console.debug("Auth Data: ", request.authData);
        let submittableRequest = {...request}
        //@ts-ignore
        delete submittableRequest['authData'];
        return axios.post(process.env.REACT_APP_API_HOST + "/create-realm", submittableRequest, authHeader(request))
            .then((res: AxiosResponse<NewRealmResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    joinRealm(request: JoinRealmRequest, authData: AuthData): Promise<RealmDetailResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/join-realm`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<RealmDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    beginEra(request: BeginEraRequest, authData: AuthData): Promise<EraDTO> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/begin`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<EraDTO>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    endEra(request: EndEraRequest, authData: AuthData): Promise<EraDTO> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/end`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<EraDTO>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    fieldArmy(request: FieldArmyRequest, authData: AuthData): Promise<APIResponse> {
        weSentABird({message: "We have ordered a new army be fielded, they will receive word as soon as our bird reaches them!"})
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/field-army`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<APIResponse>) => {
                return res.data; // todo have this return something else it should have army data in it maybe?
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    colonyDetail(request: { colonyId: string; eraId: string }, authData: AuthData): Promise<ColonyDetailResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${request.eraId}/colony/${request.colonyId}`, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyDetailResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    queueBuildings(request: QueueRequest, authData: AuthData): Promise<ColonyDetailResponse> {
        console.debug(request);
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/queue-buildings`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    queueUnits(request: QueueUnitsRequest, authData: AuthData): Promise<ColonyDetailResponse> {
        console.debug("Submitting Request:", request);
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/queue-units`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    closeGates(request: CloseGatesRequest, authData: AuthData): Promise<ColonyDetailResponse> {
        console.debug(request);
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/close-gates`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was a problem closing the gates!");
            })
    },

    openGates(request: OpenGatesRequest, authData: AuthData): Promise<ColonyDetailResponse> {
        console.debug(request);
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/open-gates`, request, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was a problem opening the gates!");
            })
    },

    playerStatus(request: PlayerStatusRequest, authData: AuthData): Promise<PlayerStatusResponse> {
        console.debug(request);
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/status/${request.eraId}`, authHeaderFrom(authData))
            .then((res: AxiosResponse<PlayerStatusResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    armyDetail(request: ArmyDetailRequest, authData: AuthData): Promise<ArmyDetailResponse> {
        console.debug(request);
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${request.eraId}/army/${request.armyId}`, authHeaderFrom(authData))
            .then((res: AxiosResponse<ArmyDetailResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    rulers(request: RulersOfTheRealmRequest, authData: AuthData): Promise<RulersResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/rulers/${request.realmId}`, authHeaderFrom(authData))
            .then((res: AxiosResponse<RulersResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    }

    , attackArmy(request: AttackArmyRequest, authData: AuthData): Promise<BattleResultResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/attack-army`, request, authHeaderFrom(authData))
            .then(r => r.data)
            .catch(err => {
                handleError(err.response?.data);
            })
    },

    attackColony(request: AttackColonyRequest, authData: AuthData): Promise<BattleResultResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/attack-colony`, request, authHeaderFrom(authData))
            .then(r => r.data)
            .catch(err => {
                handleError(err.response?.data);
            })
    },

    armySizes(authData: AuthData): Promise<ArmySizesResponse> {
        console.debug("Fetching Army Size Data")
        return axios.get(`${process.env.REACT_APP_API_HOST}/army-sizes`, authHeaderFrom(authData))
            .then((res: AxiosResponse<ArmySizesResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },


    economyDetail(eraId: string, authData: AuthData): Promise<ColonyEconomyResponse[]> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${eraId}/economy`, authHeaderFrom(authData))
            .then((res: AxiosResponse<ColonyEconomyResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    assignUserFlag(request: AssignUserFlagRequest): Promise<APIResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/assign-flag`,
            {
                scope: "PLAYER",
                assigneeId: request.authData.userId,
                base64ImageData: request.base64FlagData,
            },
            authHeaderFrom(request.authData))
            .then((res:AxiosResponse<APIResponse>) => {
                return res.data;
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    unitRetainers(eraId: string, mobId: string, authData: AuthData): Promise<UnitRetainer[]> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${eraId}/unit-retainers-within-range/${mobId}`, authHeaderFrom(authData))
            .then((res: AxiosResponse<UnitRetainer[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    currentCostDetail(eraId: string, authData: AuthData): Promise<CostDetailResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/era/${eraId}/costs`, authHeaderFrom(authData))
            .then((res: AxiosResponse<CostDetailResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("");
            })
    },

    transferUnits(request: TransferUnitsRequest, authData: AuthData): Promise<void> {
        console.debug("Submitting Request:", request);
        weSentABird({message: "We have ordered the transfer of units, they will receive word as soon as our bird reaches them!"})
        return axios.post(`${process.env.REACT_APP_API_HOST}/era/transfer-units`, {
            ...request,
            transferredUnits: prepMapForSerialization(request.transferredUnits)
        }, authHeaderFrom(authData))
            .then((res: AxiosResponse<void>) => {
                return res.data;
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
            })
    }

    , startTutorial(authData: AuthData): Promise<TutorialResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/start-tutorial`, null, authHeaderFrom(authData))
            .then((res: AxiosResponse<TutorialResponse>) => {
                return res.data
            }).catch((err:AxiosError<ErrorResponse>) => {
                handleError(err.response?.data)
                throw new Error("A problem occurred starting the tutorial!")
            })
    }


}
export default realmServiceContext;