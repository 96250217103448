import React, {useEffect} from "react";
import {ActionCost} from "../../services/RealmServiceContext";
import {Button, Divider, Popconfirm, Space} from "antd";
import ResourcesBlock from "../../components/resources/ResourcesBlock";
import {combineCosts} from "../../components/utility/MathUtils";

function SpendResourcesButton(props: {
                                  children?: React.ReactNode,
                                  onComplete: () => void,
                                  onCancel: () => void,
                                  resources: () => ActionCost[],
                                  loading?: boolean,
                                  icon?: React.ReactNode,
                                  buttonMessage?: string,
                                  disabled?: boolean
                                  messages?: { ok?: string, cancel?: string }
                              }
) {

    useEffect(() => {
        console.debug(props.resources())
    }, [props, props.resources]);

    return <Popconfirm
        disabled={props.disabled}
        title={"Are you sure?"}
        description={<><Divider orientation={"left"}>Total Cost</Divider>
            <ResourcesBlock showZeroValues={false} resources={combineCosts(props.resources())}></ResourcesBlock>
        </>}
        onConfirm={props.onComplete}
        onCancel={() => {
        }}
        okText={props.messages?.ok || "Let's Go!"}
        cancelText={props.messages?.cancel || "Nope! that's too rich for my blood!"}
    >
        <Button icon={props.icon} disabled={props.disabled}>
            <Space>{props.buttonMessage || props.children || "Submit"}</Space>
        </Button>
    </Popconfirm>;
}

export default SpendResourcesButton;