import {MapServiceContext, Tile} from "../../services/MapServiceContext";
import {Avatar, Button, List, Space, Typography} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {useEffect} from "react";
import {MapAPI} from "../../App";
import {compareStringIgnoreCase} from "../../utils/Toolbox";
import Search from "antd/es/input/Search";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {TILE_PREFIX} from "./TilePreviewer";
import {AiOutlineDelete} from "react-icons/ai";

const {Title} = Typography;

export interface Props {
    tiles: Tile[]

    setSelectedTile(tile: Tile): void
}

function TilesetManager({tiles, setSelectedTile}: Props) {
    const authDetail: AuthDetail = useAuth();
    const mapContext = React.useContext<MapServiceContext>(MapAPI);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [filteredTiles, setFilteredTiles] = React.useState<Tile[]>(tiles.sort((a, b) => compareStringIgnoreCase(a.name, b.name)));

    useEffect(() => {
        setFilteredTiles(tiles.filter((t: Tile) => t.name?.includes(searchTerm)).sort((a, b) => compareStringIgnoreCase(a.name, b.name)))
    }, [searchTerm, tiles])

    function handleRemoveTile(id: string | undefined): void {
        if (id) {
            mapContext.removeTile({id: id}, authDetail.authData);
            setFilteredTiles(filteredTiles.filter((t: Tile) => t.id !== id));
        }
    }

    return <>
        <Content>
            <Space direction={"vertical"}>
                <Title level={2}>Tileset: {tiles[0]?.tileSet}</Title>
                <Search placeholder="input search text" onChange={(e) => setSearchTerm(e.target.value)}
                        onSearch={(t) => setSearchTerm(t)} style={{width: 200}}/>
            </Space>
        </Content>
        <List
            style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
            size="small"
            bordered
            itemLayout={"horizontal"}
            loading={tiles.length === 0}
            dataSource={filteredTiles}
            renderItem={(t) => (
                <List.Item actions={[<Button danger={true} icon={<AiOutlineDelete/>}
                                             onClick={() => handleRemoveTile(t.id)}/>]}>
                    <List.Item.Meta
                        title={<div style={{cursor: "pointer"}} onClick={() => setSelectedTile(t)}>
                            <Space><Avatar style={{borderRadius: 0}} shape="square"
                                           src={`${TILE_PREFIX}${t.name}.png`}/>
                                {t.name}
                            </Space></div>}
                        description={<div>{t.center}</div>}
                    />

                </List.Item>
            )}
        />
    </>
}

export default TilesetManager;