import {Button, message, Popconfirm, Space} from "antd";
import React from "react";
import {useRecoilState} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {activeRealm} from "../../atoms";
import useRealmServiceContext from "../../useRealmServiceContext";

function EraControlPanel() {
    const [realm, setRealm] = useRecoilState<RealmDetail>(activeRealm);
    const [authDetail, realmService] = useRealmServiceContext();

    function handleBeginEra() {
        console.debug("Beginning Era");
        // when begin era is clicked we should also fire join realm for the user who clicked it?
        realmService.beginEra({realmId: realm.id}, authDetail.authData)
            .then(era => {
                console.debug(era);
                message.success("A new Era has begun!")
                realmService.realmDetail({realmId: era.realmId, authData: authDetail.authData})
                    .then(r => {
                        setRealm(r)
                    })
            }).catch(error => {
            console.error(error);
        })
    }

    function handleEndEraWithNewMap() {
        console.debug("Ending Era with new map");
        // when begin era is clicked we should also fire join realm for the user who clicked it?
        realmService.endEra({id: realm.activeEraId, generateNewMap: true}, authDetail.authData)
            .then(era => {
                console.debug(era);
                message.success("The Era has Ended... and a new map is being generated!");
            }).catch(error => {
            console.error(error);
        })
    }

    function handleEndEra() {
        console.debug("Ending Era");
        // when begin era is clicked we should also fire join realm for the user who clicked it?
        realmService.endEra({id: realm.activeEraId, generateNewMap: false}, authDetail.authData)
            .then(era => {
                console.debug(era);
                message.success("The Era has Ended!");
            }).catch(error => {
            console.error(error);
        })
    }

    return <Space direction={"vertical"}>

        {realm.activeEraId === null &&
            <>
                <p>Previous Era: {realm.eraCount}</p>
                <p>To begin a new Era, click the Begin Era button below.</p>
                <Button onClick={handleBeginEra}>Begin Era</Button>
            </>
        }
        {realm.activeEraId !== null &&
            <>
                <p>Era: {realm.eraCount}</p>
                <p>Turn: {realm.turn}</p>
                <Popconfirm title={"End the Era?"}
                            description={<Button onClick={handleEndEraWithNewMap}>Yes and Give me a new Map
                                too!</Button>}
                            onConfirm={handleEndEra}>
                    <Button>End Era</Button>
                </Popconfirm>
            </>}
    </Space>
}

export default EraControlPanel;