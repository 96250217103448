import ResourcesBlock from "../../resources/ResourcesBlock";
import ResourceSelector from "./ResourceSelector";
import {Cost, Resource} from "../../../services/RealmServiceContext";
import {useState} from "react";
import FormItem from "antd/es/form/FormItem";
import {Button, Divider, InputNumber} from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";

function CostForm(props: { costs: Cost[], setCosts: (costs: Cost[]) => void }) {
    const [currentResource, setCurrentResource] = useState<Resource>(Resource.FOOD);
    const [currentAmount, setCurrentAmount] = useState<number | null>(null);

    return <FormItem>
        <FormItemLabel prefixCls={""} label={<Divider orientation={"left"}>Costs</Divider>}/>
        <ResourcesBlock showZeroValues={false} resources={props.costs}/>
        <ResourceSelector value={currentResource} onChangeCallback={(v) => {
            setCurrentResource(v)
        }}/>
        <FormItem>
            <FormItemLabel prefixCls={""} label={"Amount"}/>
            <InputNumber type={"number"}
                         value={currentAmount}
                         onChange={(v) => {
                             setCurrentAmount(v)
                         }}/>
        </FormItem>
        <Button onClick={() => {
            props.setCosts([...props.costs, {resourceType: currentResource, quantity: currentAmount || 0}])
        }}>Add Cost</Button>
    </FormItem>
}

export default CostForm;