import {Button, Popconfirm} from "antd";
import {MdDelete} from "react-icons/md";


function DeleteButton(props: {name: string, onConfirm: () => void}) {

    return <>
        <Popconfirm title={"Are you sure you want to delete: " + props.name} onConfirm={props.onConfirm}>
            <Button danger icon={<MdDelete/>}/>
        </Popconfirm>
    </>

}

export default DeleteButton