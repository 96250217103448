import React from "react";
import {ColumnsType} from "antd/es/table";
import {Coordinate, MobileEntity} from "../../services/RealmServiceContext";
import {Button, Modal, Space, Table} from "antd";
import FieldArmyForm from "../FieldArmyForm";
import ArmyName from "./ArmyName";
import ArmyCommandButtons from "../Mob/ArmyCommandButtons";

interface ArmiesTableProps {
    armies: MobileEntity[]
}

const columns: ColumnsType<MobileEntity> = [
    {title: "Name", render: (army) => <ArmyName army={army} variant={"dark"}/>},
    {title: "Orders", render: (army) => <ArmyCommandButtons army={army}/>},
];

const ArmiesTable = (props: ArmiesTableProps) => {
    const {armies} = props;
    const [action, setAction] = React.useState<{
        showFieldArmyModal: boolean,
    }>({
        showFieldArmyModal: false,
    });

    return (
        <>
            <Space direction={"vertical"}>
                <Button size={"small"} type={"primary"}
                        onClick={() => setAction({...action, showFieldArmyModal: true})}>
                    Field Army
                </Button>
                <Table size={"small"} dataSource={armies.map(a => {
                    return {...a, key: a.header.id}
                })} columns={columns}/>
            </Space>
            <Modal width={300} open={action.showFieldArmyModal}
                   onCancel={() => setAction({...action, showFieldArmyModal: false})}
                   destroyOnClose={true}
                   footer={false}
                   title={"Field Army"}
            >
                <FieldArmyForm position={new Coordinate(0, 0)} onOk={() => {
                    setAction({...action, showFieldArmyModal: false})
                }}/>
            </Modal>
        </>
    );
}

export default ArmiesTable;