import {useRecoilState, useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {activeRealm, kingdoms} from "../../atoms";
import React, {useContext, useEffect, useState} from "react";
import {KingdomDTOResponse, KingdomServiceContext} from "../../services/KingdomServiceContext";
import {KingdomAPI} from "../../App";
import {Button, Divider, Modal, Popconfirm, Space, Table} from "antd";
import FormKingdomForm from "./FormKingdomForm";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import DebugData from "../DebugData";
import {ColumnsType} from "antd/es/table";
import KingdomName from "./KingdomName";
import KingdomLeader from "./KingdomLeader";
import PendingApplicationsTable from "./PendingApplicationsTable";
import HelpText from "../HelpText";


const columns: ColumnsType<KingdomDTOResponse> = [
    {title: "Name", render: (kingdom) => <KingdomName kingdom={kingdom}/>},
    {title: "Leader", render: (kingdom) => <KingdomLeader kingdom={kingdom}/>},
    {title: "Members", render: (kingdom) => <>{1 + kingdom.officerIds.length + kingdom.memberIds.length}</>},
];


function KingdomPanel() {
    const authDetail: AuthDetail = useAuth();
    const realmDetail = useRecoilValue<RealmDetail>(activeRealm)
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)
    const [kingdomList, setKingdomList] = useRecoilState<KingdomDTOResponse[]>(kingdoms)
    const [formKingdomOpen, setFormKingdomOpen] = useState<boolean>(false)
    const [kingdomOfUser, setKingdomOfUser] = useState<KingdomDTOResponse | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        kingdomService
            .listKingdoms({realmId: realmDetail.id}, authDetail.authData)
            .then(r => {
                setKingdomList(r)
            })
    }, [authDetail.authData, kingdomService, realmDetail, setKingdomList])

    function isKingdomMember(): boolean {
        return kingdomOfUser !== undefined;
    }

    useEffect(() => {
        let kingdom = kingdomList
            .find(k => Array.of(k.memberIds, k.officerIds, [k.leaderId]).some((memberIDs: string[]) => memberIDs.find(id => id === authDetail.authData.userId)))
        setKingdomOfUser(kingdom);
    }, [kingdomList, authDetail.authData.userId])

    function leaveKingdom() {
        if (kingdomOfUser) {
            setLoading(true)
            kingdomService.leave({kingdomId: kingdomOfUser.id}, authDetail.authData)
                .then(res => {
                    kingdomService
                        .listKingdoms({realmId: realmDetail.id}, authDetail.authData)
                        .then(r => {
                            setLoading(false);
                            setKingdomList(r)
                        })
                })
            ;
        }
    }

    const HELP = {
        kingdomHelpMessage: <div> Kingdoms are how players group together forming alliances.
                <br/>After joining a Kingdom you:
                <br/> - Share vision with your allies
                <br/> - Can station armies in allied colonies.
                <br/> - Gain communication channels with allies.
                <br/> - And more!
                <br/>Once you have joined a Kingdom in a Realm, you will be in that Kingdom for all Eras.
        </div>
    }

    return (<>

        <PendingApplicationsTable kingdoms={kingdomList}/>
        <Divider orientation={"left"}>{"Kingdoms of " + realmDetail.name}</Divider>
        <HelpText message={HELP.kingdomHelpMessage}/>
        <Table size={"small"} columns={columns} dataSource={kingdomList}/>
        <Space>
            <Button disabled={isKingdomMember()} onClick={() => setFormKingdomOpen(true)}>New
                Kingdom</Button>
            <Popconfirm title={"Do you really want to Leave the Kingdom?"} onConfirm={() => leaveKingdom()}>
                <Button loading={loading} danger={true} disabled={!isKingdomMember()}>Leave Your
                    Kingdom</Button>
            </Popconfirm>
        </Space>
        <DebugData info={kingdomList}/>

        <Modal title={"Form a new Kingdom"}
               open={formKingdomOpen}
               destroyOnClose
               onCancel={() => setFormKingdomOpen(false)}
               footer={false}>
            <FormKingdomForm/>
        </Modal>

    </>)

}

export default KingdomPanel