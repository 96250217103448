import React, {useEffect} from 'react';
import {
    ArmyDetailResponse,
    ArmyHeader,
    ColonyHeader,
    EnemyArmyHeader,
    MobileEntity,
    RealmServiceContext,
    RulersResponse
} from "../../services/RealmServiceContext";
import {activeRealm, rulersOfTheRealm, UserAction} from "../../atoms";
import {Group} from "react-konva";
import {Html} from "react-konva-utils";
import {Divider, Modal} from "antd";
import {RealmDetail} from "../../services/APIServiceTypes";
import {RealmAPI} from "../../App";
import {MobileEntityPropertyVisibility} from "./Mob";
import {useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilValue, useSetRecoilState} from "recoil";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import MobIcon from "./MobIcon";
import ArmyDetail from "./ArmyDetail";
import {ControlActivity, ControlState, currentControlActivity} from "../GameBoard";
import Flag from "../player/Flag";
import EnemyArmyInspectorModal from "./EnemyArmyInspectorModal";
import EnemyColonyInspectorModal from "./EnemyColonyInspectorModal";
import ArmyCommandButtons from "./ArmyCommandButtons";

interface MobMenuProps {
    mob: MobileEntity
    mobProps: MobileEntityPropertyVisibility
    setSelectedMob: (props: MobileEntity) => void
    setUserAction: (props: UserAction) => void
}


const MobMenu: React.FC<MobMenuProps> = (props: MobMenuProps) => {
    const {mob, mobProps, setSelectedMob, setUserAction} = props
    const authDetail: AuthDetail = useAuth();
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [armyDetail, setArmyDetail] = React.useState<ArmyDetailResponse | undefined>(undefined)
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
    const [selectedArmy, setSelectedArmy] = React.useState<ArmyHeader | EnemyArmyHeader | undefined>(undefined)
    const [selectedColony, setSelectedColony] = React.useState<ColonyHeader | undefined>(undefined)
    const setControlActivity = useSetRecoilState<ControlActivity>(currentControlActivity);
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    useEffect(() => {
        if (selectedArmy && selectedArmy.ownerId === authDetail.authData.userId)
            console.debug("This army is owned by this user, swap the Mob for the selected one")
    }, [authDetail.authData.userId, selectedArmy])

    useEffect(() => {
        if (selectedColony && selectedColony.ownerId === authDetail.authData.userId) {
            setControlActivity({controlState: ControlState.MANAGE_CITY, controlObjectId: selectedColony.id})
            setUserAction(UserAction.IDLE);
        }
    }, [setControlActivity, selectedColony, authDetail.authData.userId, setUserAction])


    useEffect(() => {
        console.debug("New Mob received: ", mob)
        console.debug("Fetching Mob Detail from API")
        realmService
            .armyDetail({eraId: realm.activeEraId, armyId: mob.header.id}, authDetail.authData)
            .then(response => setArmyDetail(response))
    }, [authDetail.authData, mob, realm.activeEraId, realmService])

    // todo get out of this recoil bridge if you can - maybe since the _selected mob_ is global state and user action is
    //  global state you can move this thing out...?
    return (<Group id={"mob-menu-" + mob.header.id}
                   x={mob.header.vector.position.x}
                   y={mob.header.vector.position.y}>

        <Html>
            <RecoilBridge>
                <Modal open={true}
                       onCancel={() => setUserAction(UserAction.IDLE)}
                       onOk={() => setUserAction(UserAction.IDLE)}
                       title={<><Flag size={"medium"} ruler={rulers[mob.header.owner]}/> {mob.header.name} <MobIcon
                           mob={mob}/></>}>
                    <Divider>Orders</Divider>
                    <ArmyCommandButtons army={mob}/>
                    <Divider>Detail</Divider>
                    <ArmyDetail detail={armyDetail} setSelectedArmy={setSelectedArmy}
                                setSelectedColony={setSelectedColony}/>
                    {selectedArmy &&
                        <EnemyArmyInspectorModal open={selectedArmy.ownerId !== authDetail.authData.userId}
                                                 enemyArmy={selectedArmy}
                                                 inspectingArmy={mob}
                                                 onCancel={() => setSelectedArmy(undefined)}
                        />}
                    {selectedColony &&
                        <EnemyColonyInspectorModal open={selectedColony.ownerId !== authDetail.authData.userId}
                                                   inspectingArmy={mob}
                                                   selectedColony={selectedColony}
                                                   onCancel={() => setSelectedColony(undefined)}
                        />}
                </Modal>
            </RecoilBridge>
        </Html>
    </Group>)

}

export default MobMenu