import {KingdomServiceContext, MemberApplication} from "../../services/KingdomServiceContext";
import RulerName from "../player/RulerName";
import React, {useContext} from "react";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";
import {Divider, Form, Tag} from "antd";
import BirdIcon from "../utility/BirdIcon";
import FormFooter from "../utility/FormFooter";
import {KingdomAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";

const PendingApplication = (props: { application: MemberApplication, onFinished: () => void }) => {
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)
    const [loading, setLoading] = React.useState<boolean>(false);

    function accept() {
        setLoading(true)
        kingdomService.acceptApplication({
            userId: props.application.userId,
            kingdomId: rulers[authDetail.authData.userId].kingdomId,
        }, authDetail.authData).then(res => {
            setLoading(false);
            props.onFinished();
        })
    }

    function deny() {
        setLoading(true);
        kingdomService.declineApplication({
            userId: props.application.userId,
            kingdomId: rulers[authDetail.authData.userId].kingdomId,
        }, authDetail.authData).then(res => {
            setLoading(false);
            props.onFinished();
        })
    }

    return <>
        My Liege,<br/>
        We have received a bird from <Tag><RulerName ruler={rulers[props.application.userId]}/></Tag>. They wish to join
        forces with our kingdom.
        <Divider orientation={"left"}><BirdIcon/></Divider>
        {props.application.message}
        <Form>
            <FormFooter loading={loading} onCancel={deny} onOk={accept} labels={{cancel: "Decline", ok: "Accept"}}/>
        </Form>
    </>
}

export default PendingApplication