import {Circle} from "react-konva";
import React from "react";
import {Coordinate} from "../services/RealmServiceContext";

interface Props {
    points: Coordinate[]
    visible: boolean
}

const Journey = (props: Props) => {
    const {points, visible} = props;
    //todo something weird here with the radius value not picking up and rendering properly...
    return <>
        {visible && points.map(point =>
            <Circle
            key={point.x.toString() + point.y.toString()}
            x={point.x}
            y={point.y}
            radius={2.25}
            stroke={"#00d0ff"}
            strokeWidth={1}
            perfectDrawEnabled={true}
            width={10}
        />)}
    </>
}

export default Journey