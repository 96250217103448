import {UnitRequest} from "../../../services/ReferenceServiceContext";
import React, {useState} from "react";
import DebugData from "../../DebugData";
import FreeformShortTextField from "../form-components/FreeformShortTextField";
import {Form, Space} from "antd";
import WeaponSelector from "../form-components/WeaponSelector";
import ClassSelector from "../form-components/ClassSelector";
import ArmorSelector from "../form-components/ArmorSelector";
import SaveButton from "../form-components/SaveButton";
import DeleteButton from "../form-components/DeleteButton";
import useReferenceServiceContext from "../useReferenceServiceContext";


function UnitForm(props: { onFinishCallback: () => void, unit: UnitRequest }) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [unitRequest, setUnitRequest] = useState<UnitRequest>({...props.unit});

    function save() {
        console.debug("Saving unit: " + unitRequest.name);
        refService.defineUnit(unitRequest, authDetail.authData)
            .then(() => {
                props.onFinishCallback();
            }).catch(() => {
            console.error("Error saving unit: " + unitRequest.name);
        })
    }

    function remove() {
        console.debug("Removing unit: " + unitRequest.name);
        if(unitRequest.id) {
            refService.deleteUnit(unitRequest.id, authDetail.authData)
            props.onFinishCallback()
        }
    }

    return <>
        <Form>
            <ClassSelector label={"Class"}
                           value={unitRequest.classId}
                           onChange={(v: string) => setUnitRequest({...unitRequest, classId: v})}/>
            <FreeformShortTextField label={"Name"} value={unitRequest.name}
                                    onChange={(v: string) => setUnitRequest({...unitRequest, name: v})}/>
            <WeaponSelector label={"Primary Weapon"}
                            value={unitRequest.primaryWeapon}
                            onChange={(v: string) => setUnitRequest({...unitRequest, primaryWeapon: v})}/>
            <WeaponSelector label={"Secondary Weapon"}
                            value={unitRequest.secondaryWeapon}
                            onChange={(v: string) => setUnitRequest({...unitRequest, secondaryWeapon: v})}/>
            <ArmorSelector label={"Armor"}
                           value={unitRequest.armor}
                           onChange={(v: string) => setUnitRequest({...unitRequest, armor: v})}/>
            <ArmorSelector label={"Shield"} value={unitRequest.shield}
                           onChange={(v: string) => setUnitRequest({...unitRequest, shield: v})}/>
            <Space>
                <SaveButton save={save}/>
                {unitRequest.id && <DeleteButton name={unitRequest.name} onConfirm={remove}/>}
            </Space>
        </Form>
        <DebugData info={unitRequest}/>
    </>
}

export default UnitForm;