import {Tabs} from "antd";
import React from "react";
import ManageWeapons from "./weapons/ManageWeapons";
import ManageArmor from "./armor/ManageArmor";
import ManageUnits from "./units/ManageUnits";
import ManageClasses from "./classes/ManageClasses";

function ManageRefData() {

    return <Tabs type={"card"} size={"small"} items={[
        {id: "Classes", key: "classes", label: "Classes", children: (<ManageClasses/>)},
        {id: "Units", key: "units", label: "Units", children: (<ManageUnits/>)},
        {id: "Weapons", key: "weapons", label: "Weapons", children: (<ManageWeapons/>)},
        {id: "Armor", key: "armor", label: "Armor", children: (<ManageArmor/>)},
    ]}>

    </Tabs>;

}


export default ManageRefData;