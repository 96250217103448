import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {Select} from "antd";

function DiceSelector(props: {label: string, value: string, onChange: (v: string) => void}) {

    return <FormItem>
        <FormItemLabel required={true} label={props.label} prefixCls={""}/>
        <Select value={props.value} onChange={(v) => props.onChange(v)}>
            <Select.Option value={"1d4"}>1d4</Select.Option>
            <Select.Option value={"1d6"}>1d6</Select.Option>
            <Select.Option value={"1d8"}>1d8</Select.Option>
            <Select.Option value={"1d10"}>1d10</Select.Option>
            <Select.Option value={"1d12"}>1d12</Select.Option>
            <Select.Option value={"1d20"}>1d20</Select.Option>
        </Select>
    </FormItem>

}

export default DiceSelector;