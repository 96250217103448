import React, {ChangeEvent, ReactNode, useRef, useState} from "react";
import {Button, Col, Divider, Row, Space} from "antd";
import {toPng} from 'html-to-image';
import HelpText from '../HelpText'

import {
    GiArrowsShield,
    GiBowArrow,
    GiBowman,
    GiCastle,
    GiElvenCastle,
    GiMailedFist,
    GiShieldReflect,
    GiSplitArrows,
    GiStrikingArrows,
    GiSwordClash,
    GiSwordsEmblem,
    GiThorHammer
} from "react-icons/gi";
import {stripBase64ImageDataFromImageUrl} from "../../utils/Toolbox";
import {RealmServiceContext} from "../../services/RealmServiceContext";
import {RealmAPI} from "../../App";
import useAuth from "../../hooks/useAuth";
import FlagPreview from "./FlagPreview";
import NetworkCallButton from "../NetworkCallButton";


interface FlagCreatorProps {
    onComplete: () => void
}

const FlagCreator = (props: FlagCreatorProps) => {
    const {onComplete} = props;
    const authDetail = useAuth();
    const [bgColor, setBgColor] = useState("#0088BB");
    const [iconColor, setIconColor] = useState("#000000");
    const [icon, setIcon] = useState<ReactNode | null>(null)
    const componentRef = useRef(null);
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [loading, setLoading] = React.useState<boolean>(false);
    const handleBgColorChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBgColor(e.target.value);
    };

    const handleIconColorChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIconColor(e.target.value);
    }


    function uploadImageData(base64ImageData: string): void {
        realmService
            .assignUserFlag({base64FlagData: base64ImageData, authData: authDetail.authData})
            .then(res => {
                setLoading(false);
                onComplete();
            })
    }

    const handleConvertToImage = () => {
        if (componentRef.current) {
            setLoading(true);
            toPng(componentRef.current)
                .then((dataUrl: string) => {
                    uploadImageData(stripBase64ImageDataFromImageUrl(dataUrl));
                })
                .catch((error) => {
                    console.error('oops, something went wrong!', error);
                })
        }
    }


    return (
        <div>
            <Divider orientation={"left"}>Flag</Divider>
            <HelpText message={"Your Flag is used to represent your armies and colonies on the map."}/>
            <br/>
            <Row>
                <Col span={2}></Col>
                <Col span={20}>
                    <div ref={componentRef}>
                        <FlagPreview bgColor={bgColor} iconColor={iconColor} icon={icon}/>
                    </div>
                </Col>
                <Col span={2}></Col>
            </Row>

            <Divider/>
            <Space direction={"vertical"}>
                <Space>
                    <div>
                        <Space>
                            <label htmlFor="bg-color">Background Color:</label>
                            <input type="color" id="bg-color" value={bgColor} onChange={handleBgColorChange}/>
                        </Space>
                    </div>
                    <Divider type={"vertical"}/>
                    <div>
                        <Space>
                            <label htmlFor="bg-color">Icon Color:</label>
                            <input type="color" id="bg-color" value={iconColor} onChange={handleIconColorChange}/>
                        </Space>
                    </div>
                </Space>
                <br/>

                <div style={{width: "inherit", overflow: 'auto'}}>
                    <Row>
                        <Space>
                            <Col span={2}> <Button
                                onClick={() => setIcon(<GiMailedFist color={iconColor} size={"100%"}/>)}
                                icon={<GiMailedFist/>}/></Col>
                            <Col span={2}> <Button
                                onClick={() => setIcon(<GiArrowsShield color={iconColor} size={"100%"}/>)}
                                icon={<GiArrowsShield/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiElvenCastle color={iconColor} size={"100%"}/>)}
                                        icon={<GiElvenCastle/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiCastle color={iconColor} size={"100%"}/>)}
                                        icon={<GiCastle/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiSwordsEmblem color={iconColor} size={"100%"}/>)}
                                        icon={<GiSwordsEmblem/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiSwordClash color={iconColor} size={"100%"}/>)}
                                        icon={<GiSwordClash/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiShieldReflect color={iconColor} size={"100%"}/>)}
                                        icon={<GiShieldReflect/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiThorHammer color={iconColor} size={"100%"}/>)}
                                        icon={<GiThorHammer/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiBowman color={iconColor} size={"100%"}/>)}
                                        icon={<GiBowman/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiBowArrow color={iconColor} size={"100%"}/>)}
                                        icon={<GiBowArrow/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiSplitArrows color={iconColor} size={"100%"}/>)}
                                        icon={<GiSplitArrows/>}/></Col>
                            <Col span={2}>
                                <Button onClick={() => setIcon(<GiStrikingArrows color={iconColor} size={"100%"}/>)}
                                        icon={<GiStrikingArrows/>}/></Col>
                        </Space>
                    </Row>
                </div>
                <Divider/>
                <NetworkCallButton idleText={"Assign Flag"} loading={loading} onClick={handleConvertToImage}/>
            </Space>
        </div>
    );
};

export default FlagCreator;
