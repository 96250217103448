import React from "react";

const FlagPreview = (props: { bgColor: string, iconColor: string, icon: React.ReactNode }) => {

    return <div style={{
        width: "400px",
        height: "240px",
        border: "1px solid black"
    }}>
        <div style={{backgroundColor: props.bgColor, width: "100%", height: "100%"}}>
            <div style={{margin: "auto", width: "50%", height: "100%", color: props.iconColor}}>
                {
                    // todo get the color of the icon to change when iconColor changes...
                    props.icon
                }
            </div>
        </div>
    </div>
}

export default FlagPreview