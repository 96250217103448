import React, {useEffect, useState} from "react";
import {ArmorResponse} from "../../../services/ReferenceServiceContext";
import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {Select} from "antd";
import useReferenceServiceContext from "../useReferenceServiceContext";

function ArmorSelector(props: { label: string, value: string | undefined, onChange: (v: string) => void }) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [armor, setArmor] = useState<ArmorResponse[]>([]);

    useEffect(() => {
        refService.listArmor(authDetail.authData)
            .then((response) => {
                setArmor(response);
            }).catch((error) => {
            console.error(error);
        })
    }, []);

    return <FormItem>
        <FormItemLabel prefixCls={""} label={props.label} required={true}/>
        <Select value={props.value} onChange={(id: string) => props.onChange(id)}>
            {armor.map((a: ArmorResponse) => <Select.Option value={a.id}>{a.name}</Select.Option>)}
        </Select>

                </FormItem>

}

export default ArmorSelector;