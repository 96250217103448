import {Button, Divider, message, Tag} from "antd";
import React from "react";
import {AiFillCopy} from "react-icons/ai";
import ReactJson from "react-json-view";

const showDebugData: boolean = true;

function DebugData(props: { info: any, title?: string }) {
    function copyToClipboard() {
        navigator.clipboard.writeText(JSON.stringify(props.info))
            .then(r => message.info("Copied!"));
    }

    return (<>
            {/* eslint-disable-next-line no-restricted-globals */}
            {showDebugData && (location.hostname === "localhost" || location.hostname === "127.0.0.1")
                ? <><Divider> Debug Data {props.title} </Divider>
                    <Tag color={"warning"}>
                        <Button type={"dashed"} icon={<AiFillCopy/>} onClick={copyToClipboard}>Copy</Button>
                        <ReactJson src={props.info}/>
                    </Tag>
                </>
                : <></>
            }
        </>
    )
}

export default DebugData