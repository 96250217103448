import {useRecoilState, useSetRecoilState} from "recoil";
import {
    ArmySizesResponse,
    CostDetailResponse,
    EntityPositionsResponse,
    EraTurnState,
    PlayerStatusResponse,
    RealmDetailResponse,
    RealmServiceContext,
    RulersResponse
} from "../services/RealmServiceContext";
import {
    activeRealm,
    armySizeChartState,
    currentCostsState,
    entityPositionsState,
    eraTurnState,
    playerStatusState,
    rulersOfTheRealm
} from "../atoms";
import React, {useContext, useEffect} from "react";
import useAuth, {AuthDetail} from "./useAuth";
import {RealmAPI} from "../App";
import {RealmDetail} from "../services/APIServiceTypes";
import {weSentABird} from "../services/APIResponseHandler";

interface LoadReferenceDataProps {
    realmId: string,
}

const useReferenceData = (props: LoadReferenceDataProps) => {
    const authDetail: AuthDetail = useAuth();
    const realmService = useContext<RealmServiceContext>(RealmAPI);
    const setArmySizes = useSetRecoilState<ArmySizesResponse>(armySizeChartState)
    const [selectedRealm, setSelectedRealm] = useRecoilState<RealmDetail>(activeRealm);
    const setEraTurnStatus = useSetRecoilState<EraTurnState>(eraTurnState)
    const setRulersOfRealm = useSetRecoilState<RulersResponse>(rulersOfTheRealm)
    const setPlayerStatus = useSetRecoilState<PlayerStatusResponse>(playerStatusState);
    const setPositions = useSetRecoilState<EntityPositionsResponse>(entityPositionsState)
    const setCurrentCosts = useSetRecoilState<CostDetailResponse>(currentCostsState)

    useEffect(() => {
        if (selectedRealm?.activeEraId) {
            loadRulersOfTheRealm();
            loadCurrentCosts();
            loadArmySizes();
        }
        loadEntityPositions();

    }, [selectedRealm?.activeEraId])

    useEffect(() => {
        if(props.realmId && authDetail.sessionIsActive())
            loadRealmDetail();
    }, [props.realmId])


    function loadEntityPositions() {
        weSentABird({message: "Assembling your Rangers to report on the whereabouts of our allies and enemies"})
        realmService.entityPositions({eraId: selectedRealm?.activeEraId, authData: authDetail.authData})
            .then((r: EntityPositionsResponse) => {
                setPositions(r);
            })
    }

    function loadRulersOfTheRealm() {
        weSentABird({message: "Assembling the Ambassadors representing the other rulers of the realm"})
        realmService
            .rulers({realmId: selectedRealm.id}, authDetail.authData)
            .then(res => {
                setRulersOfRealm(res);
            })
    }

    function loadCurrentCosts() {
        weSentABird({message: "Assembling your Bankers and Economists"})
        realmService.currentCostDetail(selectedRealm.activeEraId, authDetail.authData)
            .then((r: CostDetailResponse) => {
                setCurrentCosts(r);
            })
    }

    function loadRealmDetail() {
        weSentABird({message: "Gathering the Maps of the Realm"})
        realmService.realmDetail({realmId: props.realmId, authData: authDetail.authData})
            .then((r: RealmDetailResponse) => {
                setEraTurnStatus(r.eraTurnState)
                setPlayerStatus(r.playerStatus)
                setSelectedRealm(r)
            });
    }

    function loadArmySizes() {
        weSentABird({message: "Assembling your military strategists"})
        realmService.armySizes(authDetail.authData)
            .then((res: ArmySizesResponse) => setArmySizes(res))
    }


    return <></>

}

export default useReferenceData