import React, {useEffect} from "react";
import {ColumnsType} from "antd/es/table";
import {Army, ColonyDetailResponse, RealmServiceContext, RulersResponse} from "../../services/RealmServiceContext";
import {Button, Modal, Space, Table} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {activeRealm, colonyState, rulersOfTheRealm} from "../../atoms";
import TransferMilitaryForm from "../military/TransferMilitaryForm";
import {RealmAPI} from "../../App";
import {RealmDetail} from "../../services/APIServiceTypes";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import FieldArmyForm from "../FieldArmyForm";

interface ArmiesTableProps {
    armies: Army[]
}

const ArmiesTable = (props: ArmiesTableProps) => {
    const {armies} = props;
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm);
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [colonyDetail, setColonyDetail] = useRecoilState<ColonyDetailResponse>(colonyState)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI);
    const [action, setAction] = React.useState<{
        originatingEntityId: string,
        showFieldArmyModal: boolean,
        showModal: boolean
    }>({
        originatingEntityId: colonyDetail.detail.id,
        showFieldArmyModal: false,
        showModal: false
    });

    useEffect(() => {
        console.debug("Colony ID: ", colonyDetail.detail.id)
    }, [colonyDetail.detail.id])

    const columns: ColumnsType<Army> = [
        {title: "Owner", dataIndex: "ownerId", render: id => rulers[id].name}, // todo turn this into a flag and link
        {title: "Name", dataIndex: "name"},
        {
            title: "Actions",
            render: (value, army: Army) => {
                return <Button size={"small"}
                               type={"primary"}
                               onClick={() => setAction({
                                   ...action,
                                   showModal: true
                               })}>Transfer</Button>;
            }
        },
        {title: "Wages",
        //     todo get the army wages figured out here
        // render: (value, army: Army) => {
        //         return <>{army.wages} gold</>
        //     },
            dataIndex: "wages"},
    ];

    function reload() {
        realmService.colonyDetail({colonyId: colonyDetail.detail.id, eraId: realm.activeEraId}, authDetail.authData)
            .then(res => {
                setColonyDetail(res);
            })
    }


    return (
        <>
            <Space direction={"vertical"}>
                {armies && <Table size={"small"} dataSource={armies} columns={columns}/>}
                <Button size={"small"} type={"primary"} onClick={() => setAction({...action, showFieldArmyModal: true})}>Field Army</Button>
            </Space>
            <Modal width={300} open={action.showFieldArmyModal}
                   onCancel={() => setAction({...action, showFieldArmyModal: false})}
                   destroyOnClose={true}
                   footer={false}
                   title={"Field Army"}
            >
                <FieldArmyForm position={colonyDetail.detail.position} onOk={() => {
                    setAction({...action, showFieldArmyModal:false})
                }}/>
            </Modal>
            <Modal width={800}
                   onCancel={() => setAction({...action, showModal: false})}
                   afterClose={() => reload()}
                   title={"Transfer Units"}
                   footer={false}
                   open={action.showModal}
                   destroyOnClose={true}>
                <TransferMilitaryForm success={() => setAction({...action, showModal: false})}
                                      originatingEntityId={colonyDetail.detail.id}/>
            </Modal>
        </>
    );
}

export default ArmiesTable;