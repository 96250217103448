import {ActionCost, ColonyDetailResponse, PlayerStatusResponse} from "../../services/RealmServiceContext";
import React, {useEffect, useState} from "react";
import {Form, Input} from "antd";
import {GiBattleGear} from "react-icons/gi";
import {useRecoilState, useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {activeRealm, colonyState, playerStatusState} from "../../atoms";
import useRealmServiceContext from "../../useRealmServiceContext";
import useReferenceServiceContext from "../reference-data/useReferenceServiceContext";
import {MilitaryUnitResponse} from "../../services/ReferenceServiceContext";
import TrainingCapabilities from "./training-tab/TrainingCapabilities";
import DebugData from "../DebugData";
import SpendResourcesButton from "../../styled-components/Button/SpendResourcesButton";
import TrainMilitaryUnitFormItem from "./training-tab/TrainMilitaryUnitFormItem";
import Fuse, {FuseResult} from "fuse.js";
import {BiSearch} from "react-icons/bi";

interface QueuedUnit {
    unit: MilitaryUnitResponse,
    quantity: number
}

interface TrainingQueue {
    unitsInQueue: QueuedUnit[]
    // version: string
}

const initialState = {unitsInQueue: []}
const TrainingTab = () => {
    const [authDetail, realmService] = useRealmServiceContext();
    const [a, refServiceContext] = useReferenceServiceContext();
    const [colonyDetail, setColonyDetail] = useRecoilState<ColonyDetailResponse>(colonyState)
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const [unitData, setUnitData] = useState<MilitaryUnitResponse[]>([]);
    const [fuse, setFuse] = useState<Fuse<MilitaryUnitResponse>>();
    const [filteredUnitData, setFilteredUnitData] = useState<MilitaryUnitResponse[]>([]);
    const [playerStatus, setPlayerStatus] = useRecoilState<PlayerStatusResponse>(playerStatusState);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [trainingQueue, setTrainingQueue] = useState<TrainingQueue>(initialState)
    const [totalUnitsInQueue, setTotalUnitsInQueue] = useState<number>(0)
    const [unitFilterSearchTerm, setUnitFilterSearchTerm] = useState<string>("")

    useEffect(() => {
        setTotalUnitsInQueue(trainingQueue.unitsInQueue.filter(u => u.quantity > 0).map(u => u.quantity).reduce((a, b) => a + b, 0))
    }, [trainingQueue]);

    useEffect(() => {
        if (fuse && unitFilterSearchTerm !== "") {
            let result: FuseResult<MilitaryUnitResponse>[] = fuse.search(unitFilterSearchTerm);
            setFilteredUnitData(result.map((r) => r.item))
        } else {
            setFilteredUnitData(unitData)
        }
    }, [fuse, unitData, unitFilterSearchTerm]);

    useEffect(() => {
        if (filteredUnitData.length === 0 && unitFilterSearchTerm === "")
            refServiceContext.listUnits(a.authData)
                .then((r: MilitaryUnitResponse[]) => {
                    let res = r.filter((u) => u.name !== "Peasant")
                    setUnitData(res)
                    setFuse(new Fuse(res, {keys: ['type', 'classification.name', 'name']}))
                    setFilteredUnitData(res)
                })
    }, [a.authData, refServiceContext, filteredUnitData.length])

    function trainUnits() {
        setLoading(true);
        setLoading(true);
        realmService.queueUnits({
            queue: trainingQueue.unitsInQueue.map((u) => {
                return {unit: {id: u.unit.id, unitType: u.unit.type}, count: u.quantity}
            }),
            colonyId: colonyDetail?.detail.id,
            eraId: realm.activeEraId,
        }, authDetail.authData)
            .then(res => {
                setColonyDetail(res);
                setPlayerStatus({...playerStatus, resources: res.playerResources})
                setLoading(false);
                setTrainingQueue(initialState)
                setLoading(false);
            }).catch((e) => {
            console.error(e)
                setLoading(false)
        });
    }

    function calculateTotalCost(): ActionCost[] {
        return trainingQueue.unitsInQueue.flatMap((u) => new ActionCost(u.quantity, u.unit.costs));
    }

    function swapUnitsInQueue(unit: MilitaryUnitResponse, v: number) {
        let unitsInQueue = [...trainingQueue.unitsInQueue.filter((u) => u.unit.id !== unit.id), {
            unit: unit,
            quantity: v
        }];
        setTrainingQueue({
            unitsInQueue: unitsInQueue,
            // version: JSON.stringify(unitsInQueue)
        })
    }

    function quantityForUnit(unit: MilitaryUnitResponse): number {
        let queuedUnit = trainingQueue.unitsInQueue.filter((u) => u.unit.id === unit.id)[0];
        return queuedUnit?.quantity || 0
    }

    return (colonyDetail &&
        <>
            <TrainingCapabilities colony={colonyDetail}/>
            <Form>
                <Input prefix={<BiSearch/>}
                       value={unitFilterSearchTerm}
                       onChange={(v) => setUnitFilterSearchTerm(v.target.value)}
                />

                {filteredUnitData
                    .filter((unit: MilitaryUnitResponse) => unit.costs.length > 0) // filter out the worker peasants
                    // .map((unit: MilitaryUnitResponse) => {
                    //     setCostMap({...costMap, [unit.id]: unit.costs})
                    //     return unit;
                    // })
                    .map((unit: MilitaryUnitResponse) => {
                        return <TrainMilitaryUnitFormItem key={unit.id}
                                                          unit={unit}
                                                          onChange={(v) => swapUnitsInQueue(unit, v)}
                                                          value={quantityForUnit(unit)}
                        />
                    })
                }
                <SpendResourcesButton
                    resources={calculateTotalCost}
                    buttonMessage={"Train"}
                    onComplete={trainUnits}
                    icon={<GiBattleGear/>}
                    loading={loading}
                    disabled={
                        totalUnitsInQueue > colonyDetail.detail.population
                        || totalUnitsInQueue === 0
                        ||
                        loading
                    }
                    onCancel={() => {
                    }}
                />

                <DebugData info={trainingQueue}/>
            </Form>

        </>)


}
export default TrainingTab;