import {Button, Col, Divider, Progress, Row, Space, Statistic} from "antd";
import ToggleHelpButton from "./ToggleHelpButton";
import HelpText from "./HelpText";
import React from "react";
import {ErrorResponse, RealmDetailResponse, TutorialResponse} from "../services/RealmServiceContext";
import {AuthData, MapGenerationStatus} from "../services/APIServiceTypes";
import {delay} from "../utils/Toolbox";
import realmServiceContext from "../services/impl/RealmServiceContextImpl";
import {AxiosError} from "axios";
import useAuth, {AuthDetail} from "../hooks/useAuth";
import {useSetRecoilState} from "recoil";
import {authDataState, selectedRealmIdState} from "../atoms";


const TOTAL_TUTORIAL_STEPS: number = 5;

const RulerDetail = () => {
    const authDetail: AuthDetail = useAuth();
    const setAuthData = useSetRecoilState<AuthData | undefined>(authDataState);
    const setSelectedRealmId= useSetRecoilState<string>(selectedRealmIdState)
    const [tutorialStatus, setTutorialStatus]
        = React.useState<{ message: string, status: "idle" | "started" | "completed", step: number }>({
        message: "",
        status: "idle",
        step: 0
    });


    async function joinTheRealm(realm: RealmDetailResponse, authData: AuthData) {
        await delay(15000)
            .then(() => {
                realmServiceContext.realmDetail({realmId: realm.id, authData: authData})
                    .then((res) => {
                        if (res.activeEraId) {
                            setTutorialStatus({status: "started", message: "Joining the realm", step: 4})
                            setSelectedRealmId(realm.id);
                            setTutorialStatus({
                                status: "started",
                                message: "Tutorial setup completed!"
                                , step: 5
                            })
                        } else {
                            joinTheRealm(realm, authData);
                        }
                    })
            })
    }

    async function waitForMapCompletion(realm: RealmDetailResponse, authData: AuthData) {
        setTutorialStatus({...tutorialStatus, step: 2, status: "started", message: "Waiting for the Map creation..."})
        await delay(5000)
            .then(() => {
                realmServiceContext.realmDetail({realmId: realm.id, authData: authData})
                    .then((res: RealmDetailResponse) => {
                        if (res.mapGenerationStatus === MapGenerationStatus.COMPLETE) {
                            setTutorialStatus({
                                ...tutorialStatus,
                                status: "started",
                                message: "Starting the Tutorial Era",
                                step: 3
                            })
                            realmServiceContext.beginEra({realmId: realm.id}, authData)
                                .then(res => {
                                    setTutorialStatus({status: "started", step: 4, message: "Tutorial Era began"})
                                    joinTheRealm(realm, authData);
                                })
                        } else {
                            waitForMapCompletion(realm, authData)
                        }
                    }).catch((err: AxiosError<ErrorResponse>) => {
                    waitForMapCompletion(realm, authData)
                })
            });
    }

    function startTutorial() {
        if (authDetail.sessionIsActive()) {
            setTutorialStatus({...tutorialStatus, status: "started", message: "Setting up Tutorial..."})
            realmServiceContext
                .startTutorial(authDetail.authData)
                .then((res: TutorialResponse) => {
                    setTutorialStatus({
                        status: "started",
                        message: "Tutorial Realm setup, joining Tutorial Realm...",
                        step: 1
                    })
                    realmServiceContext
                        .joinRealm({id: res.realm.id}, authDetail.authData)
                        .then((realmDetailResponse: RealmDetailResponse) => {
                            setTutorialStatus({
                                status: "started",
                                message: "Joined Realm " + realmDetailResponse.name,
                                step: 2
                            })
                            waitForMapCompletion(realmDetailResponse, authDetail.authData);
                        })
                });
        }
    }


    return <>
        <Row>
            <Col span={8}>
                <ToggleHelpButton/>
            </Col>
            <Col span={8}>
            </Col>
            {/*todo - bring back the tutorial button when it is ready*/}
            {/*<Col span={8}>*/}
            {/*    <Button size={"large"} type={"primary"} onClick={startTutorial}>Tutorial</Button>*/}
            {/*</Col>*/}
        </Row>

        <Divider/>
        {
            tutorialStatus.status === "started"
                ? <Row><Col span={8}></Col><Col span={8}>
                    <Space direction={"vertical"}>
                        <Progress
                            type="circle"
                            percent={tutorialStatus.step / TOTAL_TUTORIAL_STEPS * 100}/>
                        <>{tutorialStatus.message}</>
                    </Space>
                </Col>
                    <Col span={8}>
                    </Col></Row>
                :
                <Space direction={"vertical"}>
                    <Statistic title={"Ruler Name"} value={authDetail.authData?.username}/>
                    <HelpText message={"Your ruler name is a public name viewable by other players."}/>
                    Email: {authDetail.authData?.email}
                    <HelpText
                        message={"Use your email to login, your email will never be shared with other players."}/>
                    <Button type={"default"} onClick={() => setAuthData(undefined)}>
                        Logout
                    </Button>
                </Space>
        }
    </>
}
export default RulerDetail;