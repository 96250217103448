import React, {useContext, useEffect} from 'react';
import {AuthServiceContext} from "./services/AuthServiceContext";
import {AuthAPI} from "./App";


const ConfirmEmail = () => {
    const authContext = useContext<AuthServiceContext>(AuthAPI);
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const userId = urlParams.get("id");
        const token = urlParams.get("token");
        if (userId && token) {
            authContext.confirmEmail({userId: userId, token: token})
                .then((response) => {
                    window.history.replaceState(null, "", "/")
                })
        }
    }, [authContext])

    return <>Thank you for confirming your email!</>
}

export default ConfirmEmail