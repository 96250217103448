import {MobileEntity} from "../../services/RealmServiceContext";
import {Card, Tag} from "antd";
import MobIcon from "./MobIcon";
import React from "react";
import Meta from "antd/es/card/Meta";
import BirdIcon from "../utility/BirdIcon";
import {useRecoilValue} from "recoil";
import {helpEnabled} from "../../atoms";
import HelpText from "../HelpText";


const MovingMOBDetail = (props: { mob: MobileEntity }) => {
    const displayHelp = useRecoilValue<boolean>(helpEnabled)


    return <Card title={<>{props.mob.header.name}</>}
                 style={{width: 400}}
                 size="small"
                 extra={<><Tag><MobIcon mob={props.mob}/>{" " + props.mob.size.name}</Tag></>}>

        <Meta
            avatar={<BirdIcon/>}
            description={"What are your orders?"}
        />

        {displayHelp &&
            <HelpText
                message={"Move your army by left clicking on the map. Each time you click it will set another waypoint on the army's path. When you are happy with the path you have set, simply double click to send your orders to the army."}/>
        }

    </Card>

}

export default MovingMOBDetail