import {Button, Divider, Popconfirm, Space} from "antd";
import {
    ActionCost,
    Building,
    BuildingsType,
    ColonyDetailResponse,
    CostDetailResponse,
    PlayerStatusResponse,
    QueueRequest,
    RealmServiceContext
} from "../../services/RealmServiceContext";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {GiCorn, GiMilitaryFort, GiStoneCrafting, GiTavernSign, GiWhiteTower, GiWoodPile} from "react-icons/gi";
import {HiHome} from "react-icons/hi";
import {activeRealm, colonyState, currentCostsState, playerStatusState} from "../../atoms";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {BsHammer} from "react-icons/bs";
import BuildingsFormControl from "./BuildingsFormControl";
import HelpText from "../HelpText";
import ResourcesBlock from "../resources/ResourcesBlock";
import {combineCosts} from "../utility/MathUtils";
import DebugData from "../DebugData";

const initialBuildings = {homes: 0, mines: 0, farms: 0, lumberMills: 0, taverns: 0, barracks: 0, guardTowers: 0,}

function ConstructionTab() {
    const authDetail: AuthDetail = useAuth();
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [totalBuildings, setTotalBuildings] = React.useState<number>(0);
    const [colony, setColony] = useRecoilState<ColonyDetailResponse>(colonyState)
    const [playerStatus, setPlayerStatus] = useRecoilState<PlayerStatusResponse>(playerStatusState);
    const currentCosts = useRecoilValue<CostDetailResponse>(currentCostsState)
    const [buildingsToBuild, setBuildingsToBuild] = React.useState<BuildingsType>(initialBuildings)

    const initialState = {
        eraId: "",
        colonyId: "",
        buildings: initialBuildings
    };
    const [queueRequest, setQueueRequest] = React.useState<QueueRequest>(initialState)

    useEffect(() => {
        console.debug("Loading Colony Construction Tab: ", colony.detail.name);
    }, [colony])

    useEffect(() => {
        setTotalBuildings(Object.values(queueRequest.buildings).reduce((a, b) => a + b, 0))
        setBuildingsToBuild({...queueRequest.buildings})
    }, [queueRequest.buildings])

    function queueBuildings() {
        if (colony)
            realmService.queueBuildings({
                ...queueRequest,
                eraId: realm.activeEraId,
                colonyId: colony.detail.id
            }, authDetail.authData)
                .then((c: ColonyDetailResponse) => {
                    setColony(c);
                    setQueueRequest(initialState)
                    setPlayerStatus({...playerStatus, resources: c.playerResources})
                })
    }

    function buildingOf(key: string): Building {
        switch (key) {
            case "lumberMills":
                return Building.LUMBER_MILL;
            case "guardTowers":
                return Building.GUARD_TOWER;
            case "homes":
                return Building.HOME;
            case "farms":
                return Building.FARM;
            case "mines":
                return Building.MINE;
            case "taverns":
                return Building.TAVERN;
            case "barracks":
                return Building.BARRACKS;
            default:
                console.error("Key not found in enum!", key)
                return Building.HOME;
        }
    }

    function totalCosts(): JSX.Element {
        if (Object.keys(buildingsToBuild).length > 0 && currentCosts.buildings) {
            const actionCosts: ActionCost[] =
                Object.keys(buildingsToBuild).map((key: string) =>
                    {
                        console.debug("key: ", key)
                        console.debug("Building Of: ", buildingOf(key))
                        //@ts-ignore todo why do I have to tsignore here?
                        return new ActionCost(buildingsToBuild[key], currentCosts.buildings[buildingOf(key)])
                    }
                )
            return <ResourcesBlock showZeroValues={false} resources={combineCosts(actionCosts)}/>
        } else {
            return <></>
        }
    }

    return <>
        <>
            <p>We can currently construct a maximum of <b>{colony.detail.maxNewBuildings - totalBuildings}</b> new
                buildings
                in {colony.detail.name}.</p>
            {/*<DebugData  info={buildingsToBuild}/>*/}
            <Space direction={"vertical"}>
                <Divider orientation={"left"}>
                    <Space>
                        <HiHome/>
                        Homes
                        <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["HOME"]}/>
                    </Space>
                </Divider>
                {/*<DebugData info={currentCosts.buildings}/>*/}

                <BuildingsFormControl currentBuildings={colony.detail.buildings.homes}
                                      underConstruction={colony.detail.buildingQueue.homes}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, homes: e}
                                      })}/>
                <HelpText message={"Each home can hold 4 peasants."}/>

                <Divider orientation={"left"}><Space><GiCorn/>Farms
                    <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["FARM"]}/>
                </Space></Divider>
                <BuildingsFormControl currentBuildings={colony.detail.buildings.farms}
                                      underConstruction={colony.detail.buildingQueue.farms}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, farms: e}
                                      })}/>
                <HelpText
                    message={"Each farm harvests 100 food per turn. Peasants consume food at a rate of 1 food per peasant per turn. If you don't have enough food to feed your peasants your population will not grow."}/>

                <Divider orientation={"left"}><Space><GiStoneCrafting/>Mines
                    <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["MINE"]}/>
                </Space></Divider>

                <BuildingsFormControl currentBuildings={colony.detail.buildings.mines}
                                      underConstruction={colony.detail.buildingQueue.mines}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, mines: e}
                                      })}/>
                <HelpText
                    message={"Each mine harvests 100 gold & ore and 300 stone per turn as long as there are enough peasants (4 per mine) to work the mines."}/>


                <Divider
                    orientation={"left"}><Space><GiWoodPile/>Lumber Mills
                    <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["LUMBER_MILL"]}/>
                </Space></Divider>

                <BuildingsFormControl currentBuildings={colony.detail.buildings.lumberMills}
                                      underConstruction={colony.detail.buildingQueue.lumberMills}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, lumberMills: e}
                                      })}/>
                <HelpText
                    message={"Each lumbermill harvests 100 lumber per turn as long as there are enough peasants (4 per lumbermill) to work the there."}/>

                <Divider orientation={"left"}><Space><GiMilitaryFort/>Barracks
                    <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["BARRACKS"]}/>
                </Space></Divider>

                <BuildingsFormControl currentBuildings={colony.detail.buildings.barracks}
                                      underConstruction={colony.detail.buildingQueue.barracks}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, barracks: e}
                                      })}/>
                <HelpText
                    message={"Barracks are used to train military units. Each Barracks decreases the amount of time required to train them and provides housing for military units at a rate of 100 units per Barracks."}/>


                <Divider orientation={"left"}>
                    <Space><GiTavernSign/>Taverns</Space>
                    <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["TAVERN"]}/>
                </Divider>

                <BuildingsFormControl currentBuildings={colony.detail.buildings.taverns}
                                      underConstruction={colony.detail.buildingQueue.taverns}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, taverns: e}
                                      })}/>
                <HelpText
                    message={"Taverns increase the population growth of the colony. They consume food to maintain operations at a rate of 10 food per tavern"}/>

                <Divider orientation={"left"}>
                    <Space><GiWhiteTower/>{"Guard Towers " + colony.detail.buildings.guardTowers}
                        <ResourcesBlock showZeroValues={true} resources={currentCosts.buildings["GUARD_TOWER"]}/>
                    </Space>
                </Divider>

                <BuildingsFormControl currentBuildings={colony.detail.buildings.guardTowers}
                                      underConstruction={colony.detail.buildingQueue.guardTowers}
                                      onChange={(e: number) => setQueueRequest({
                                          ...queueRequest,
                                          buildings: {...queueRequest.buildings, guardTowers: e}
                                      })}/>
                <HelpText message={"Guard towers add to the defense of a colony."}/>

                <Divider/>
                <Popconfirm
                    title={"Queue Buildings"}
                    description={<><Divider orientation={"left"}>Total Construction Cost</Divider>{totalCosts()}</>}
                    onConfirm={queueBuildings}
                    onCancel={() => {
                    }}
                    okText={"Let's go!"}
                    cancelText={"Nope! that's too rich for my blood!"}
                >
                    <Button
                        disabled={totalBuildings > colony.detail.maxNewBuildings || totalBuildings === 0}>
                        <Space><BsHammer/>{"Build"}</Space>
                    </Button>
                </Popconfirm>
            </Space>
            <DebugData info={colony}/>
        </>
    </>

}

export default ConstructionTab