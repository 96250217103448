import {MobileEntity} from "../../services/RealmServiceContext";
import {useSetRecoilState} from "recoil";
import {selectedMobileEntityState, UserAction, userActionState} from "../../atoms";
import {Button, Space} from "antd";
import MobIcon from "../Mob/MobIcon";
import React from "react";

const ArmyName = (props: { army: MobileEntity, variant?: "default" | "dark" | "light" }) => {
    const setSelectedMob = useSetRecoilState<MobileEntity>(selectedMobileEntityState)
    const setUserAction = useSetRecoilState<UserAction>(userActionState)

    function handleClick() {
        setSelectedMob(props.army);
        setUserAction(UserAction.INSPECT_MOB)
    }

    return <Button type={"text"} onClick={handleClick}
                   style={{color: props.variant === "dark" ? "#000" : "#FFF", cursor: "pointer"}}>
        <Space>
            <MobIcon mob={props.army}/>
            <b>{props.army.header.name}</b>
        </Space>
    </Button>

}
export default ArmyName