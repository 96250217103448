import {
    AuthServiceContext,
    LoginRequest,
    LoginResponse,
    UserRegistrationRequest
} from "../../services/AuthServiceContext";
import React, {useContext} from "react";
import {AuthAPI} from "../../App";
import {useSetRecoilState} from "recoil";
import {AuthData} from "../../services/APIServiceTypes";
import {authDataState} from "../../atoms";
import {Col, Form, Input, Row, Space} from "antd";
import FormItem from "antd/es/form/FormItem";
import NetworkCallButton from "../NetworkCallButton";

const LoginForm = () => {
    const [userProfile, setUserProfile] = React.useState<UserRegistrationRequest>({
        username: "",
        password: "",
        email: ""
    })
    const authContext = useContext<AuthServiceContext>(AuthAPI);
    const setAuthData = useSetRecoilState<AuthData | undefined>(authDataState);
    const [loading, setLoading] = React.useState<boolean>(false)

    function login() {
        setLoading(true);
        authContext
            .login(new LoginRequest(userProfile.email, userProfile.password))
            .then((r: LoginResponse) => {
                setLoading(false);
                setAuthData({
                    webSocketCredentials: r.websocketCredentials,
                    userId: r.userId,
                    username: r.username,
                    authToken: r.authToken,
                    flagId: r.flagId,
                    email: userProfile.email,
                    expiration: r.expiration
                })
            }).catch((r) => {
            setLoading(false);
        })
    }

    return <>
        <h2 style={{ letterSpacing:"2px", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", color: "#FFF"}}>Sign In</h2>
        <Space direction={"vertical"}>
            <Row>
                <Col span={24}>
                    <Form layout={"vertical"}>
                        <FormItem>
                            <Input placeholder={"Email Address"}
                                   onChange={(e) => setUserProfile({...userProfile, email: e.target.value})}
                                   value={userProfile.email}/>
                        </FormItem>
                        <FormItem>
                            <Input placeholder={"Password"} type={"password"} value={userProfile.password}
                                   onChange={(e) => setUserProfile({...userProfile, password: e.target.value})}/>
                        </FormItem>
                        <NetworkCallButton idleText={"Login"} loading={loading} onClick={login}/>
                    </Form>
                </Col>
            </Row>
        </Space>
    </>
}

export default LoginForm;