import {Button, Col, Collapse, Divider, Layout, Row, Space, Tag} from "antd";
import {AuthData, RealmDetail} from "../services/APIServiceTypes";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {ControlActivity, ControlState, currentControlActivity} from "./GameBoard";
import React, {useEffect} from "react";
import {EntityPositionsResponse, RulersResponse} from "../services/RealmServiceContext";
import {activeRealm, authDataState, entityPositionsState, rulersOfTheRealm} from "../atoms";
import WarRoom from "./colony/WarRoom";
import Title from "antd/es/typography/Title";
import EraControlPanel from "./era/EraControlPanel";
import useRealmServiceContext from "../useRealmServiceContext";
import {LuPanelRightClose} from "react-icons/lu";
import MiniMap from "./map/MiniMap";
import RealmStats from "./RealmStats";
import Paragraph from "antd/lib/typography/Paragraph";
import FlagCreator from "./player/FlagCreator";
import useAuthServiceContext from "./useAuthServiceContext";

const {Panel} = Collapse;

function RealmControlBoard(props: { onHide: () => void }) {
    const setAuthData = useSetRecoilState<AuthData | undefined>(authDataState)
    const [authDetail, realmService] = useRealmServiceContext();
    const [realm, setRealm] = useRecoilState<RealmDetail>(activeRealm);
    const [a, authService] = useAuthServiceContext();
    const [controlActivity, setControlActivity] = useRecoilState<ControlActivity>(currentControlActivity);
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    useEffect(() => {
        setControlActivity({controlState: ControlState.IDLE, controlObjectId: null})
    }, [realm, setControlActivity])


    function joinRealm() {
        realmService.joinRealm({id: realm.id}, authDetail.authData)
            .then((res) => {
                realmService.realmDetail({realmId: realm.id, authData: authDetail.authData})
                    .then(r => {
                        setRealm({...r, users: [...r.users, authDetail.authData.userId]})
                        }
                    )
            })
    }

    function playerHasArmies(): boolean {
        return positions.mobs.filter(m => m.header.owner === authDetail.authData.userId).length > 0
    }

    function playerHasColonies(): boolean {
        return positions.colonies.filter(c => c.ownerId === authDetail.authData.userId).length > 0
    }

    function userIsAPlayerOfThisRealm() {
        return realm.users.includes(authDetail.authData.userId);
    }

    function userFlagIsUndefined() {
        return authDetail.authData.flagId === undefined || authDetail.authData.flagId === "default-flag";
    }

    function reloadAuthData() {
        authService.refreshAuthData(authDetail.authData)
            .then(res => {
                setAuthData({
                    ...authDetail.authData,
                    flagId: res.flagId
                })
            })
    }

    return <Layout>
        {/*Control Board Close Button*/}
        <Row align={"stretch"}>
            <Col span={22}></Col>
            <Col span={1}>
                <Button type={"dashed"} onClick={props.onHide} icon={<LuPanelRightClose/>}/>
            </Col>
            <Col span={1}/>
        </Row>

        <Title level={3}>{realm.name}</Title>
        <RealmStats realm={realm}/>
        {userIsAPlayerOfThisRealm()
            ? <></>
            :
            <Space direction={"vertical"}>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <div style={{marginTop: "4rem", marginBottom: "4rem"}}>
                            <Button size={"large"} type={"primary"} onClick={joinRealm}>Join this Realm</Button>
                        </div>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </Space>
        }
        {userIsAPlayerOfThisRealm() && userFlagIsUndefined() &&
            <>
                <Paragraph>
                    <Row>
                        <Col span={2}></Col>
                        <Col span={20}>
                            Choose your Ruler's flag!
                            <FlagCreator onComplete={() => {
                                reloadAuthData()
                            }}/>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                </Paragraph>
                <Divider/>
            </>
        }
        {!userFlagIsUndefined() &&
            !playerHasArmies()

            &&
            <>
                <Paragraph style={{margin: "4rem"}}>
                    To field a new army select a position on the map and <Tag>right click</Tag>to
                    bring up the Field Army Window. Once you give your army a name and click Ok,
                    your Army should appear on the map in the location you selected.
                    <br/>
                    <br/>
                    Choose a location suitable for settling a colony (plenty of open space around it).
                    Colonies cannot expand into terrain like forest or mountains. Center your army on
                    a grassy area.
                </Paragraph>

            </>

        }
        {!userFlagIsUndefined() && playerHasArmies() && !playerHasColonies()
            &&
            <>
                <Paragraph style={{margin: "4rem"}}>
                    Nice work my lord! We have managed to field an army. Now we need to settle a colony.
                    <br/>
                    <br/>
                    To settle a colony, click on your army's Label on the map. (you might have to enable Army Labels in
                    the <Tag>Map Layers</Tag>menu. This should bring up the army's detail panel.
                    Click on the <Tag>Settle Colony</Tag>button to settle a colony.
                    <br/>
                    <br/>
                    Then <Tag>Transfer</Tag>your peasants from your starting Army to your Colony so they can get right
                    to work!
                </Paragraph>

            </>
        }

        {userIsAPlayerOfThisRealm() && playerHasArmies() && playerHasColonies() &&
            <Collapse defaultActiveKey={['minimap-panel', 'colony-control-panel']}>
                <Panel header={"War Room"} key="colony-control-panel">
                    {positions && <WarRoom colonyId={controlActivity.controlObjectId}/>}
                </Panel>
                {/*todo kingdom work */}
                {/*<Panel header={"Kingdom"} key={"kingdom-panel"}>*/}
                {/*    <KingdomPanel/>*/}
                {/*</Panel>*/}
                <Panel header="MiniMap" key="minimap-panel">
                    <MiniMap/>
                </Panel>
            </Collapse>

        }
        {authDetail.isAdmin() &&
            <Collapse>
                <Panel header={"Era Detail"} key="era-panel">
                    <EraControlPanel/>
                </Panel>
            </Collapse>
        }
    </Layout>

}

export default RealmControlBoard