import {Group, Layer, Rect} from "react-konva";
import React from "react";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../../../services/APIServiceTypes";
import {activeRealm, entityPositionsState, GlobalLayers, GlobalLayersState, rulersOfTheRealm} from "../../../../atoms";
import {EntityPositionsResponse, RulersResponse} from "../../../../services/RealmServiceContext";
import VisibleArea from "./VisibleArea";
import useAuth, {AuthDetail} from "../../../../hooks/useAuth";

interface RowsCols {
    rows: number
    cols: number
}

function FogOfWarLayer() {
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const authDetail: AuthDetail = useAuth();
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState)
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    function kingdomOfRuler(rulerId: string): string {
        return rulers[rulerId]?.kingdomId
    }

    return <Layer key={"fow-layer"} id={"fow-layer"} visible={globalLayers?.fogOfWar}>
        <Group>
            {<Rect x={0} y={0} opacity={.4} width={realm.realmMap.map.length * 16}
                   height={realm.realmMap.map[0].length * 16}
                   fill="black"/>}
            {positions && positions.mobs.filter(mob => kingdomOfRuler(authDetail.authData.userId) === kingdomOfRuler(mob.header.owner))
                .map(mob => <VisibleArea key={mob.header.id}
                                         radius={mob.header.visibilityRadius}
                                         coordinates={mob.header.vector.position}/>)}
            {positions && positions.colonies.filter(c => kingdomOfRuler(authDetail.authData.userId) === kingdomOfRuler(c.ownerId))
                .map(colony => <VisibleArea key={colony.id}
                                            radius={colony.visibilityRadius}
                                            coordinates={colony.position}/>)}
        </Group>
    </Layer>
}

export default FogOfWarLayer;