import {Button, InputNumber, Space, Spin, Tooltip} from "antd";
import React, {useEffect} from "react";
import {MdOutlineDownloadDone} from "react-icons/md";


interface BuildingsFormControlProps {
    currentBuildings: number
    underConstruction: number
    onChange: (e: number) => void
}

const BuildingsFormControl = (props: BuildingsFormControlProps) => {
    const {currentBuildings, underConstruction, onChange} = props
    const [newValue, setNewValue] = React.useState<number | null>()

    useEffect(() => {
        setNewValue(null);
    }, [currentBuildings, underConstruction])

    return (<>
        <Space.Compact block>
            <Tooltip title="Existing">
                <Button disabled={true} icon={<MdOutlineDownloadDone/>}>{currentBuildings}</Button>
            </Tooltip>
            <Tooltip title="Under Construction">
                <Button disabled={true}
                        icon={<Spin size={"small"} spinning={underConstruction > 0}></Spin>}>
                    {underConstruction}
                </Button>
            </Tooltip>
            <Tooltip title="Build">
                <InputNumber min={0} type={"number"} value={newValue} onChange={(e) => {
                    setNewValue(e) // todo this is garbage how this state is being managed, fix it.
                    onChange(e || 0)
                }}/>
            </Tooltip>
        </Space.Compact>
    </>)

}

export default BuildingsFormControl