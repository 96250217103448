import React, {useState} from "react";
import {NewRealmRequest, RealmServiceContext} from "../services/RealmServiceContext";
import {Form, Input, Radio, Select, Space} from "antd";
import FormItem from "antd/es/form/FormItem";
import {RealmAPI} from "../App";
import useAuth, {AuthDetail} from "../hooks/useAuth";
import NetworkCallButton from "./NetworkCallButton";
import {useRecoilState} from "recoil";
import {RealmHeader} from "../services/APIServiceTypes";
import {realmListState} from "../atoms";

export interface Props {
    onOk(): void
}

function NewRealmModal({onOk}: Props) {
    const [loading, setLoading] = useState<boolean>(false)
    const authDetail: AuthDetail = useAuth();
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const [realmList, setRealmList] = useRecoilState<RealmHeader[]>(realmListState);
    const [newRealmRequest, setNewRealmRequest] = React.useState<NewRealmRequest>({
        name: "",
        mapSize: 128,
        privateGame: true,
        resourceAbundance: "LOW",
        turnsPerHour: 1,
        authData: authDetail.authData
    })


    function createNewRealm() {
        setLoading(true)
        realmService
            .newRealm({...newRealmRequest, authData: authDetail.authData})
            .then(r => {
                setRealmList([...realmList, {id: r.realm.id, name: r.realm.name}])
                setLoading(false);
                onOk();
            })
    }


    return <Space direction={"vertical"}>
        <Form labelCol={{span: 12}} labelAlign={"right"}>
            <FormItem label={"Realm Name"}>
                <Input onChange={(e) => setNewRealmRequest({...newRealmRequest, name: e.target.value})}
                       value={newRealmRequest.name}/>
            </FormItem>
            <FormItem label={"Map Size"}>
                <Select placeholder={"Map Size"}
                        value={newRealmRequest.mapSize}
                        onChange={(v) => setNewRealmRequest({...newRealmRequest, mapSize: v as number})}>
                    <Select.Option value={"64"}>64</Select.Option>
                    <Select.Option value={"128"}>128</Select.Option>
                    <Select.Option value={"256"}>256</Select.Option>
                </Select>
            </FormItem>
            {/*todo add some Tooltip here for the meaning of Public / Private*/}
            <FormItem label={"Join Rule"}>
                <Radio onChange={(v) => setNewRealmRequest({
                    ...newRealmRequest,
                    privateGame: false
                })} checked={!newRealmRequest.privateGame}>Public</Radio>
                <Radio onChange={(v) => setNewRealmRequest({
                    ...newRealmRequest,
                    privateGame: true
                })} checked={newRealmRequest.privateGame}>Private</Radio>
            </FormItem>
            <FormItem label={"Resource Abundance"}>
                <Select value={newRealmRequest.resourceAbundance}
                        onChange={(v) => setNewRealmRequest({...newRealmRequest, resourceAbundance: v})}>
                    <Select.Option value={"LOW"}>Low</Select.Option>
                    <Select.Option value={"MED"}>Medium</Select.Option>
                    <Select.Option value={"HIGH"}>High</Select.Option>
                </Select>
            </FormItem>
            <FormItem label={"Turns per Hour"}>
                <Select value={newRealmRequest.turnsPerHour}
                        onChange={(v) => setNewRealmRequest({...newRealmRequest, turnsPerHour: v as number})}>
                    <Select.Option value={"1"}>1</Select.Option>
                    <Select.Option value={"2"}>2</Select.Option>
                    <Select.Option value={"3"}>3</Select.Option>
                    <Select.Option value={"6"}>6</Select.Option>
                    <Select.Option value={"12"}>12</Select.Option>
                </Select>
            </FormItem>
            <NetworkCallButton idleText={"Create New Realm"} loading={loading} onClick={createNewRealm}/>
        </Form>
    </Space>
}

export default NewRealmModal;