import useReferenceServiceContext from "../useReferenceServiceContext";
import {useEffect, useState} from "react";
import {ClassResponse} from "../../../services/ReferenceServiceContext";
import ClassTable from "./ClassTable";


function ManageClasses() {
    const [authDetail, refService] = useReferenceServiceContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [classes, setClasses] = useState<ClassResponse[]>([]);

    useEffect(() => {
        setLoading(true);
        refService.listClasses(authDetail.authData)
            .then((response) => {
                setClasses(response);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
            })
    }, []);

    return <ClassTable loading={loading} classes={classes}/>

}

export default ManageClasses;