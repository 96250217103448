import {MobileEntity, RulersResponse} from "../../services/RealmServiceContext";
import {Space, Tooltip} from "antd";
import {useRecoilValue} from "recoil";
import {rulersOfTheRealm} from "../../atoms";
import React from "react";
import RulerName from "../player/RulerName";
import ArmyName from "../military/ArmyName";

interface MobNameProps {
    mob: MobileEntity
    variant?: "default" | "dark" | "light"
}

const MobName = (props: MobNameProps) => {
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    return <>
        <Tooltip title={<Space><RulerName flagOnly={true}
                                          ruler={rulers[props.mob.header.owner]}/>{props.mob.size.name}{props.mob.header.name}
        </Space>}>
            <Space>
                <ArmyName variant={"dark"} army={props.mob}/>
            </Space>
        </Tooltip>
    </>
}

export default MobName;