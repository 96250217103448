import React, {useEffect, useState} from "react";
import useRealmServiceContext from "../useRealmServiceContext";
import {RealmHeader} from "../services/APIServiceTypes";
import {Carousel, Col, Row} from "antd";
import RealmDetailCard from "./RealmDetailCard";


function RealmSelectionCarousel() {
    const [authDetail, realmContext] = useRealmServiceContext();
    const [realmList, setRealmList] = useState<RealmHeader[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        if (authDetail.sessionIsActive()) {
            console.debug("fetching realm list");
            realmContext
                .listRealms({authData: authDetail.authData})
                .then(realms => {
                    setRealmList(realms)
                    setLoading(false)
                })
                .catch(e => {
                    console.error("Error fetching realm list: " + e)
                    setLoading(false)
                });
        }

    }, [authDetail.authData, realmContext]);


    return <>
        <Row justify="center" align="middle" style={{marginTop: "5rem"}}>
            <Col span={8}/>
            <Col span={8}>
                <Carousel afterChange={(currentSlide: number) => console.debug("Current slide: ", currentSlide)}
                          dots={true}
                          pauseOnDotsHover={true}
                          autoplay={true}
                          autoplaySpeed={5000}
                          dotPosition={"top"}>
                    {realmList.map(r => <RealmDetailCard key={r.id} realm={r}/>)}
                </Carousel>
            </Col>
            <Col span={8}/>
        </Row>
    </>

}

export default RealmSelectionCarousel