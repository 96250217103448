import FormItem from "antd/es/form/FormItem";
import {Button, Form, Input, Select, Space} from "antd";
import React from "react";
import {
    CostDetailResponse,
    EntityPositionsResponse,
    MobileEntity,
    RealmServiceContext,
    SettleColonyRequest
} from "../../services/RealmServiceContext";
import {RealmAPI} from "../../App";
import {activeRealm, currentCostsState, entityPositionsState, UserAction, userActionState} from "../../atoms";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import DebugData from "../DebugData";
import ResourcesBlock from "../resources/ResourcesBlock";

const SettleColonyForm = (props: { mobId: string }) => {
    const authDetail: AuthDetail = useAuth();
    const [loading, setLoading] = React.useState<boolean>(false);
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI);
    const setUserAction = useSetRecoilState<UserAction>(userActionState)
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const costs = useRecoilValue<CostDetailResponse>(currentCostsState)
    const [request, setRequest] = React.useState<SettleColonyRequest>({
        realmId: realm.id,
        mobId: props.mobId,
        authData: authDetail.authData,
        name: ""
    })

    function handleOk() {
        setLoading(true)
        realmService.settleColony(request)
            .then(r => {
                setLoading(false);
                setUserAction(UserAction.IDLE)
            })
    }

    function armySelected(value: string) {
        setRequest({...request, mobId: value})
    }

    return <Form layout={"vertical"}>
        Settling a new colony will cost: <ResourcesBlock showZeroValues={false} resources={costs.settleColony}/>
        {positions.mobs.filter((c: MobileEntity): boolean => c.header.owner === authDetail.authData.userId).length > 0 &&
            <>
                <div style={{marginTop: "2rem"}}/>
                <FormItem label={"Which Army will Settle the new colony?"}>
                    <Select style={{width: 256}}
                            value={request.mobId}
                            onChange={armySelected}
                            options={positions.mobs.filter((c: MobileEntity): boolean => c.header.owner === authDetail.authData.userId)
                                .map((m: MobileEntity) => {
                                    return {value: m.header.id, label: m.header.name}
                                })}/></FormItem>
                <FormItem label={"What should we name our new Colony?"}>
                    <Input value={request.name} onChange={(e) => setRequest({...request, name: e.target.value})}/>
                </FormItem>
                <Space>
                    <Button loading={loading} onClick={() => setUserAction(UserAction.IDLE)}>Cancel</Button>
                    <Button loading={loading} type={"primary"} onClick={handleOk}>Ok</Button>
                </Space>
            </>
        }
        <DebugData info={request}/>
    </Form>
}

export default SettleColonyForm