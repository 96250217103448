import FormItemLabel from "antd/es/form/FormItemLabel";
import {Select} from "antd";
import FormItem from "antd/es/form/FormItem";
import {WeaponResponse} from "../../../services/ReferenceServiceContext";
import {useEffect, useState} from "react";
import useReferenceServiceContext from "../useReferenceServiceContext";

function WeaponSelector(props: {label: string, value: string | undefined, onChange: (v: string) => void}) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [weapons, setWeapons] = useState<WeaponResponse[]>([]);

    useEffect(() => {
        refService.listWeapons(authDetail.authData)
            .then((response) => {
                setWeapons(response);
            }).catch((error) => {
                console.error(error);
            })
    }, []);



    return <FormItem>
            <FormItemLabel required={true} label={props.label} prefixCls={""}/>
            <Select value={props.value} onChange={(v) => props.onChange(v)}>
                {weapons.map((weapon) => <Select.Option value={weapon.id}>{weapon.name}</Select.Option>)}
            </Select>
        </FormItem>

}
export default WeaponSelector;