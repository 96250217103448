import useAuth, {AuthDetail} from "../hooks/useAuth";
import {AuthServiceContext} from "../services/AuthServiceContext";
import {useContext} from "react";
import {AuthAPI} from "../App";

function useAuthServiceContext(): [AuthDetail, AuthServiceContext] {
    const authDetail: AuthDetail = useAuth();
    const authService = useContext<AuthServiceContext>(AuthAPI)

    return [authDetail, authService]

}

export default useAuthServiceContext;