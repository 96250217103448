import {useRecoilState, useRecoilValue} from "recoil";
import {ColonyDetailResponse, EntityPositionsResponse, RealmServiceContext} from "../../services/RealmServiceContext";
import {activeRealm, colonyState, entityPositionsState} from "../../atoms";
import {RealmDetail} from "../../services/APIServiceTypes";
import {Form, Select} from "antd";
import React from "react";
import FormItem from "antd/es/form/FormItem";
import {RealmAPI} from "../../App";
import useAuth, {AuthDetail} from "../../hooks/useAuth";

const ColonySelector = () => {
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState);
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const authDetail: AuthDetail = useAuth();
    const realmService = React.useContext<RealmServiceContext>(RealmAPI);
    const [colony, setColony] = useRecoilState<ColonyDetailResponse>(colonyState)

    function handleSelectedColony(colonyId: string) {
        realmService.colonyDetail({colonyId: colonyId, eraId: realm.activeEraId}, authDetail.authData)
            .then((c) => {
                console.debug("Colony: ", c)
                setColony(c);
            })
    }

    return <Form>
        <FormItem label={"Colony"} tooltip={"Select a Colony"}>
            <Select
                onChange={(e) => handleSelectedColony(e)}
                value={colony?.detail.id}
                options={positions?.colonies
                    .filter(c => c.ownerId === authDetail.authData?.userId)
                    .map(c => {
                        return {value: c.id, label: c.name}
                    })}/>
        </FormItem>
    </Form>

}

export default ColonySelector;