import {ColonyDetailResponse} from "../../services/RealmServiceContext";
import React from "react";
import MilitiaDetail from "./MilitiaDetail";
import {useRecoilState, useRecoilValue} from "recoil";
import {activeRealm, colonyState} from "../../atoms";
import ArmiesTable from "./ArmiesTable";
import {Divider, Segmented} from "antd";
import {GiClosedDoors, GiOpenGate} from "react-icons/gi";
import useRealmServiceContext from "../../useRealmServiceContext";
import {RealmDetail} from "../../services/APIServiceTypes";
import DebugData from "../DebugData";
import HelpText from "../HelpText";
import Paragraph from "antd/lib/typography/Paragraph";
import {SegmentedValue} from "antd/lib/segmented";

export interface Props {
    colony: ColonyDetailResponse
}


function ColonyGatesToggle() {
    const [colonyDetail, setColonyDetail] = useRecoilState<ColonyDetailResponse>(colonyState)
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [authDetail, realmService] = useRealmServiceContext();

    function handleToggleGates(value: SegmentedValue) {
        setLoading(true)
        console.debug("Toggling gates: ", value)
        if (value === "closed") {
            realmService.closeGates({eraId: realm.activeEraId, colonyId: colonyDetail.detail.id}, authDetail.authData)
                .then((res: ColonyDetailResponse) => {
                        setColonyDetail(res)
                        setLoading(false)
                    }
                )
        } else {
            realmService.openGates({eraId: realm.activeEraId, colonyId: colonyDetail.detail.id}, authDetail.authData)
                .then((res: ColonyDetailResponse) => {
                        setColonyDetail(res)
                        setLoading(false)
                    }
                )
        }
    }

    return <>
        <Paragraph>
            <b>Gates:</b>
        </Paragraph>
        <Segmented
        value={colonyDetail.detail.gates.closed ? "closed" : "open"}
        disabled={loading}
        onChange={(e: SegmentedValue) => handleToggleGates(e)}
        options={[
            {
                label: 'Open',
                value: 'open',
                icon: <GiOpenGate/>,
            },
            {
                label: 'Closed',
                value: 'closed',
                icon: <GiClosedDoors/>,
            },
        ]}
    />
        <HelpText message={"When a colony's gates are open, armies (including enemy armies) can pass through unopposed. If your gates are closed, enemy armies must find a way around the colony."}/>
        <DebugData info={colonyDetail.detail.gates}/>
    </>
}

const DefensesTab = () =>
    {
        const colonyDetail = useRecoilValue<ColonyDetailResponse>(colonyState)

        return (
            colonyDetail &&
            <>
                <Divider orientation={"left"}>Colony Defenses</Divider>
                <ColonyGatesToggle/>
                <MilitiaDetail colony={colonyDetail}/>
                <Divider orientation={"left"}>Armies Stationed in the Colony</Divider>
                <HelpText message={"Armies stationed in the colony, will increase population growth, do not require housing or food and will defend the colony when under attack."}/>
                <ArmiesTable armies={colonyDetail.stationedArmies}/>
                <DebugData info={colonyDetail}/>
            </>)

    }

    export default DefensesTab;