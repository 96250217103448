import {APIResponse, AuthData, AuthorizedAPIRequest, RealmDetail, RealmHeader} from "./APIServiceTypes";
import {distance} from "../utils/Toolbox";
import {MilitaryUnitResponse} from "./ReferenceServiceContext";
import {combineCosts} from "../components/utility/MathUtils";

// export interface JoinRealmRequest {
//     realmId: string;
//     realmPassword: string;
// }

// export interface JoinRealmResponse extends APIResponse {
//
// }
export interface ErrorResponse {
    failureReason: string;
    errors: string[];
    causes: string[];
}

export interface UnitTypeId {
    id: string
    unitType: string
}

export interface TransferQuantityResponse {
    unitTypeId: UnitTypeId
    unit: MilitaryUnitHeaderResponse
    quantity: number
}

export interface TransferQuantity {
    unitTypeId: UnitTypeId
    unit?: MilitaryUnitHeaderResponse // this is provided by the server, but not the client. maybe I should just extend this object on the client side...
    quantity: number
}

export interface TransferUnitsRequest {
    eraId: string
    originationId: string
    destinationId: string
    transferredUnits: Map<string, number> // Map<unitId, number>
}

export interface UnitRetainer {
    id: string
    name: string
    transferableUnits: TransferQuantity[]
}

export enum Building {
    HOME = "HOME",
    MINE = "MINE",
    FARM = "FARM",
    LUMBER_MILL = "LUMBER_MILL",
    TAVERN = "TAVERN",
    BARRACKS = "BARRACKS",
    GUARD_TOWER = "GUARD_TOWER",
}

export enum Resource {
    GOLD = "GOLD",
    ORE = "ORE",
    STONE = "STONE",
    WOOD = "WOOD",
    FOOD = "FOOD",
}

export interface Cost {
    resourceType: Resource
    quantity: number
}

export interface UnitCostMatrix {

}

export interface CostDetailResponse {
    military: any; // todo type this correctly
    buildings: any;
    fieldArmy: Cost[]
    settleColony: Cost[];
}

export interface MilitaryUnit {
    name: string
    description: string
    imageUrl: string
    costs: Cost[]

}

export interface NewRealmRequest extends AuthorizedAPIRequest {
    name: string;
    turnsPerHour: number; // valid values: 1,2,3,5,10
    privateGame: boolean;
    mapSize: number; // valid values: 128, 256, 1024
    resourceAbundance: string; // valid values: LOW, MED, HIGH
}

export interface RealmDetailRequest extends AuthorizedAPIRequest {
    realmId: string
}

export interface EntityPositionsRequest extends AuthorizedAPIRequest {
    eraId: string
}

export interface MoveMobRequest extends AuthorizedAPIRequest {
    realmId: string
    mobId: string
    path: Coordinate[]
}

export interface NewRealmResponse extends APIResponse {
    realm: RealmHeader;
}

export interface RealmDetailResponse extends RealmDetail, APIResponse {
    playerStatus: PlayerStatusResponse
    eraTurnState: EraTurnState
}

export class Coordinate {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    distanceTo(c2: Coordinate): number {
        return distance(this, c2);
    }
}

export enum EntityStatus {
    MOVING = "MOVING", STATIONARY = "STATIONARY"
}

export interface Velocity {
    heading: number // degree rotation from 12:00 - 0 degrees
    speed: number // a number representing pixels per second speed of the unit in question
}

export interface Path {
    path: Coordinate[]
}

export interface Vector {
    position: Coordinate
    velocity: Velocity
    path: Path;
    journey: Coordinate[]
}

export interface EntityHeader {
    id: string
    owner: string
    name: string
    status: EntityStatus
    vector: Vector
    icon: string // url of the image used to represent this mob
    visibilityRadius: number
}

export interface MobileEntity {
    header: EntityHeader
    size: ArmySize
}

export interface GatesType {
    closed: boolean
}

export interface ColonyDTOType {
    id: string
    name: string
    ownerId: string
    kingdomId: string
    position: Coordinate
    gates: GatesType // open | closed
    visibilityRadius: number
    size: number
}

export interface GroupOfUnits {
    SCOUT?: number;
    PEASANT?: number;
}

export interface ColonyDetailType {
    //{"owner":{"id":"14922674-c093-4f14-954e-1f982283fe17","name":"jtango"},"detail":{
    // "id":"2b31ffd0ce2ba66fc8f1361d98745aa2",
    // "name":"First Colony",
    // "ownerId":"14922674-c093-4f14-954e-1f982283fe17",
    // "maxSize":16,"currentSize":39,
    // "position":{"x":552,"y":57},
    // "population":691,
    // "maxNewBuildings":1575,
    // "buildings":{"homes":200,"mines":150,"farms":150,"lumberMills":150,"taverns":60,"barracks":50,"guardTowers":26},
    // "buildingQueue":{"homes":0,"mines":0,"farms":0,"lumberMills":0,"taverns":0,"barracks":0,"guardTowers":-3}
    // },"stationedArmies":[]}
    id: string
    name: string
    ownerId: string
    currentSize: string
    maxSize: number
    position: Coordinate
    population: number
    maxNewBuildings: number
    buildings: BuildingsType
    buildingQueue: BuildingsType
    stationedMilitaryUnits: { units: any } // todo could not get the Map<String, number> to work correctly here...
    queuedUnits: { units: any }
    gates: GatesType // open | closed
}

export interface EntityPositionsResponse extends APIResponse {
    mobs: MobileEntity[]
    colonies: ColonyDTOType[]
}

export enum TimeOfDay {
    DAY = "DAY", NIGHT = "NIGHT"
}

export interface EraTurnState {
    id: string
    nextTurn: number
    turn: number
    timeOfDay: TimeOfDay
}

export interface EraStateUpdatedNotification {
    entityPositions: EntityPositionsResponse
    playerStatus: PlayerStatusResponse
    eraTurnState: EraTurnState
}

export interface ColonyResponse extends APIResponse {
    colony: ColonyDetailType
}

export interface SettleColonyRequest extends AuthorizedAPIRequest {
    realmId: string
    mobId: string
    name: string
}

export interface StopMobRequest extends AuthorizedAPIRequest {
    realmId: string
    mobId: string
}

export interface JoinRealmRequest {
    id: string
}

export enum EraStatus {
    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export interface ArmyHeader {
    id: string
    ownerId: string
    name: string
    position: Coordinate
    direction?: number
    size: ArmySize
}

export interface EnemyArmyHeader extends ArmyHeader {
    probabilityOfDefeating: number
}

export interface ColonyHeader {
    id: string
    ownerId: string
    name: string
    position: Coordinate
    probabilityOfDefeating: number
    stationedArmies: number
    population: number
    size: number
}

export interface Army {
    id: string
    ownerId: string
    name: string
    currentPath: Coordinate[]
    stepsOnPath: number
}

export interface EraDTO {
    id: string
    name: string
    realmId: string
    startedBy: string
    startTime: number
    firstTurn: number
    endTime: number
    status: EraStatus
    turn: number
    armies: Army[]
}

export interface BeginEraRequest {
    realmId: string
}

export interface JoinRealmResponse {
    id: string
}

export interface EndEraRequest {
    id: string
    generateNewMap: boolean
}

export interface FieldArmyRequest {
    realmId: string
    armyName: string
    position: Coordinate
}

export interface Ruler {
    id: string
    kingdomId: string
    name: string
    flagId: string
}

export interface ArmySize {
    name: string
    symbol: string
}

export class ActionCost {
    get combinedCosts(): Map<Resource, number> {
        return this._combinedCosts;
    }

    public combine(actionCost: Map<Resource, number>) {
        actionCost.forEach((value: number, key: Resource) => {
            this._combinedCosts.set(key, (this._combinedCosts.get(key) || 0) + value)
        })
    }

    private readonly _combinedCosts: Map<Resource, number>


    constructor(multiplier: number, costs: Cost[]) {
        this._combinedCosts = new Map<Resource, number>();
        if (costs)
            costs.forEach((c: Cost) => this._combinedCosts.set(c.resourceType, c.quantity * multiplier))
    }

    public asCostList(): Cost[] {
        const costs: Cost[] = []
        this._combinedCosts.forEach((v: number, key: Resource) => costs.push({resourceType: key, quantity: v}))
        return costs;
    }
}

export interface MilitaryUnitHeaderResponse {
    type: string,
    classId: string
    id: string
    name: string
}

export interface UnitsInTrainingResponse {
    unit: MilitaryUnitHeaderResponse;
    quantity: number;
}

export interface ColonyDetailResponse extends APIResponse {
    owner: Ruler
    detail: ColonyDetailType
    stationedArmies: Army[]
    militia: TransferQuantityResponse[]
    inTraining: UnitsInTrainingResponse[]
    playerResources: Cost[]
}

export interface BuildingsType {
    homes: number
    mines: number
    farms: number
    lumberMills: number
    taverns: number
    barracks: number
    guardTowers: number
}

export interface QueueRequest {
    eraId: string
    colonyId: string
    buildings: BuildingsType
}

export interface IUnitTrainingQueue {
    updateQuantity: (unitId: string, quantity: number) => IUnitTrainingQueue
    totalCosts: () => Cost[]
    quantityOf: (id: string) => number
    total: () => number;
    allUnitsInQueue: () => Map<string, number>;
}

export class UnitTrainingQueue implements IUnitTrainingQueue {
    unitsInQueue: Map<string, number>
    costMap: Map<string, Cost[]>
    updateQuantity: (unitId: string, quantity: number) => UnitTrainingQueue
    totalCosts: () => Cost[]
    quantityOf: (id: string) => number
    total: () => number
    allUnitsInQueue: () => Map<string, number>


    constructor(militaryUnitQuantityResponse: MilitaryUnitResponse[]) {
        this.unitsInQueue = new Map<string, number>();
        this.costMap = new Map<string, Cost[]>();
        militaryUnitQuantityResponse.forEach((u: MilitaryUnitResponse) => {
            this.unitsInQueue.set(u.id, 0)
        });

        this.updateQuantity = (unitId: string, quantity: number): UnitTrainingQueue => {
            console.debug("Adding " + quantity + " to " + unitId + " Unit to Queue")
            this.unitsInQueue.set(unitId, quantity)
            return {...this}
        }

        this.totalCosts = (): Cost[] => {
            let actionCosts: ActionCost[] = []
            console.debug("Calculating total Cost for Training Queue")
            this.unitsInQueue
                .forEach((quantity: number, key: string) => {
                    actionCosts.push(new ActionCost(quantity, this.costMap.get(key) || []))
                })

            return combineCosts(actionCosts);
        }

        this.quantityOf = (id: string): number => {
            return this.unitsInQueue.get(id) || 0
        }

        this.total = (): number => {
            let total: number = 0;
            this.unitsInQueue.forEach((quantity: number, key: string) => {
                total += quantity
            })
            return total
        }
        this.allUnitsInQueue = (): Map<string, number> => {
            return this.unitsInQueue;
        }
    }

}

export interface UnitsInQueue {
    unit: UnitTypeId
    count: number
}

export interface QueueUnitsRequest {
    eraId: string;
    colonyId: string;
    queue: UnitsInQueue[]
}

export interface CloseGatesRequest {
    eraId: string
    colonyId: string
}

export interface OpenGatesRequest {
    eraId: string
    colonyId: string
}

export interface ResourceType {
    current: number
    spent: number
}

interface Resources {
    WOOD: ResourceType
    ORE: ResourceType
    FOOD: ResourceType
    GOLD: ResourceType
    STONE: ResourceType
}

export interface PlayerStatusResponse {
    ownerId: string
    resources: Cost[]
    flagId: string // todo use this to load the flag somewhere
}

export interface PlayerStatusRequest {
    eraId: string
}

export interface MilitaryUnitQuantityResponse {
    unitTypeId: UnitTypeId
    unit: MilitaryUnitHeaderResponse
    quantity: number
}

export interface ArmyDetail extends ArmyHeader {
    experience: number
    fatigue: number
    units: MilitaryUnitQuantityResponse[]
    injuredUnits: number;
}

export interface ArmyDetailResponse {
    armyDetail: ArmyDetail
    armiesWithinRange: ArmyHeader[]
    coloniesWithinRange: ColonyHeader[]
}

export interface ArmyDetailRequest {
    eraId: string
    armyId: string
}

export interface RulersResponse {
    [index: string]: Ruler
}

export interface ArmySizesResponse {
    [index: string]: ArmySize
}

export interface RulersOfTheRealmRequest {
    realmId: string
}

export interface AttackArmyRequest {
    eraId: string,
    aggressorId: string,
    defenderId: string,
}

export interface AttackColonyRequest {
    eraId: string;
    aggressorId: string;
    defenderId: string
}

export interface ColonyEconomyResponse {
    key: string
    colonyName: string
    productivityRate: number
    gold: number
    food: number
    stone: number
    ore: number
    wood: number
}

export interface AssignUserFlagRequest {
    authData: AuthData
    base64FlagData: string
}

interface RealmDTO {
    id: string
    name: string
    owner: string
    activeEraId: string
    turn: number
    eraCount: number
    privateGame: boolean
    users: string[]
    mapGenerationStatus: string
}

export interface TutorialResponse {
    realm: RealmDTO
}

export interface BattleResultResponse {
    victory: boolean
    survivalRate: number
    xpGained: number
    averageEnemySurvivalRate: number
}

export interface RealmServiceContext {

    armySizes(authData: AuthData): Promise<ArmySizesResponse>

    rulers(request: RulersOfTheRealmRequest, authData: AuthData): Promise<RulersResponse>

    listRealms(request: AuthorizedAPIRequest): Promise<RealmHeader[]>

    newRealm(request: NewRealmRequest): Promise<NewRealmResponse>

    joinRealm(request: JoinRealmRequest, authData: AuthData): Promise<RealmDetailResponse>

    realmDetail(request: RealmDetailRequest): Promise<RealmDetailResponse>

    entityPositions(request: EntityPositionsRequest): Promise<EntityPositionsResponse>

    moveMob(request: MoveMobRequest): Promise<APIResponse>

    settleColony(request: SettleColonyRequest): Promise<ColonyResponse>

    stop(request: StopMobRequest): Promise<APIResponse>

    joinRealm(request: JoinRealmRequest, authData: AuthData): Promise<JoinRealmResponse>

    beginEra(request: BeginEraRequest, authData: AuthData): Promise<EraDTO>;

    endEra(request: EndEraRequest, authData: AuthData): Promise<EraDTO>;

    fieldArmy(request: FieldArmyRequest, authData: AuthData): Promise<APIResponse>;

    colonyDetail(request: { colonyId: string; eraId: string }, authData: AuthData): Promise<ColonyDetailResponse>;

    queueBuildings(queueRequest: QueueRequest, authData: AuthData): Promise<ColonyDetailResponse>;

    queueUnits(queueUnitsRequest: QueueUnitsRequest, authData: AuthData): Promise<ColonyDetailResponse>;

    closeGates(request: CloseGatesRequest, authData: AuthData): Promise<ColonyDetailResponse>;
    openGates(request: OpenGatesRequest, authData: AuthData): Promise<ColonyDetailResponse>;

    playerStatus(request: PlayerStatusRequest, authData: AuthData): Promise<PlayerStatusResponse>;

    armyDetail(request: ArmyDetailRequest, authData: AuthData): Promise<ArmyDetailResponse>;

    attackArmy(request: AttackArmyRequest, authData: AuthData): Promise<BattleResultResponse>

    attackColony(request: AttackColonyRequest, authData: AuthData): Promise<BattleResultResponse>;

    economyDetail(eraId: string, authData: AuthData): Promise<ColonyEconomyResponse[]>;

    currentCostDetail(eraId: string, authData: AuthData): Promise<CostDetailResponse>;

    assignUserFlag(request: AssignUserFlagRequest): Promise<APIResponse>;

    unitRetainers(eraId: string, mobId: string, authData: AuthData): Promise<UnitRetainer[]>;

    transferUnits(transferUnitsRequest: TransferUnitsRequest, authData: AuthData): Promise<void>;

    startTutorial(authData: AuthData): Promise<TutorialResponse>;
}