import React, {useEffect, useState} from "react";
import {TransferQuantity, TransferUnitsRequest, UnitRetainer} from "../../services/RealmServiceContext";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../services/APIServiceTypes";
import {activeRealm} from "../../atoms";
import {Col, Form, Row, Select, Skeleton} from "antd";
import FormItem from "antd/es/form/FormItem";
import useRealmServiceContext from "../../useRealmServiceContext";
import DebugData from "../DebugData";
import SaveButton from "../reference-data/form-components/SaveButton";
import TransferForm from "./TransferForm";
import {mapKeySerializer, prepMapForSerialization} from "../../utils/Toolbox";

interface TransferMilitaryFormProps {
    originatingEntityId: string,
    success: () => void
}

interface RetainerOption {
    value: string,
    label: string
}

const TransferMilitaryForm = (props: TransferMilitaryFormProps) => {
    const [authDetail, realmService] = useRealmServiceContext();
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [loading, setLoading] = useState<boolean>(false);
    const [unitRetainers, setUnitRetainers] = useState<UnitRetainer[]>([]);
    const [retainerOptions, setRetainerOptions] = useState<RetainerOption[]>([])
    const [stagedUnitsMap, setStagedUnitsMap] = useState<Map<string, number>>(new Map<string, number>())
    const initialState = {
        eraId: realm.activeEraId,
        originationId: props.originatingEntityId,
        destinationId: "",
        transferredUnits: new Map<string, number>()
    };
    const [transferUnitsRequest, setTransferUnitsRequest]
        = useState<TransferUnitsRequest>(initialState);

    useEffect(() => {
        setLoading(true)
        realmService
            .unitRetainers(realm.activeEraId, props.originatingEntityId, authDetail.authData)
            .then(res => {
                setUnitRetainers(res);
                setRetainerOptions(
                    res.map((u: UnitRetainer) => {
                        return {value: u.id, label: u.name}
                    }))
                setLoading(false);
            })
    }, [authDetail.authData, props.originatingEntityId, realm.activeEraId, realmService])

    function transferUnits() {
        setLoading(true);
        realmService.transferUnits({...transferUnitsRequest, transferredUnits: stagedUnitsMap}, authDetail.authData)
            .then(res => {
                setLoading(false);
                props.success();
            });
    }

    function stageUnitsForTransfer(tq: TransferQuantity) {
        console.debug("Staging for transfer", tq)
        setStagedUnitsMap(new Map(stagedUnitsMap.set(mapKeySerializer(tq.unitTypeId), tq.quantity || 0)))
        console.debug("Staged units map", stagedUnitsMap)
    }

    return <Skeleton loading={loading}>
        <Form layout="vertical">
            <Row>
                <Col span={11}>
                    <FormItem label={"Originator"} required tooltip={"Where the units are now"}>
                        <Select
                            defaultValue={props.originatingEntityId}
                            onChange={(v) => {
                                setTransferUnitsRequest({...transferUnitsRequest, originationId: v})
                            }}
                            options={retainerOptions}
                        />
                    </FormItem>
                </Col>
                <Col span={2}/>
                <Col span={11}>
                    <FormItem label={"Destination"} required tooltip={"Where you want to transfer the units to"}>
                        <Select
                            onChange={(v) => {
                                setTransferUnitsRequest({...transferUnitsRequest, destinationId: v})
                            }}
                            options={retainerOptions}
                        />
                    </FormItem>
                </Col>
            </Row>
            <TransferForm
                stageForTransfer={stageUnitsForTransfer}
                unitRetainers={unitRetainers.filter(u => u.id === transferUnitsRequest.originationId)}/>
            <FormItem style={{marginTop: "2rem"}}>
                <SaveButton save={transferUnits} label={"Order the Transfer"}/>
            </FormItem>
        </Form>
        <DebugData info={prepMapForSerialization(stagedUnitsMap)}/>
    </Skeleton>
        ;
}


export default TransferMilitaryForm;