import {APIResponse, AuthData} from "./APIServiceTypes";

export interface ClusterDetail {
    cluster: string
}

export interface WebsocketCredentials {
    key: string,
    clusterDetail: ClusterDetail
}

export interface LoginResponse extends APIResponse {
    websocketCredentials: WebsocketCredentials
    userId: string
    username: string
    authToken: string
    flagId: string
    expiration: number
}


export interface RegistrationResponse extends APIResponse {
}

export interface UserRegistrationRequest {
    username: string;
    password: string;
    email: string;
}

export class LoginRequest {
    private email: string;
    private password: string;

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
}

export class RegistrationRequest extends LoginRequest {
    private username: string;

    constructor(username: string, email: string, password: string) {
        super(email, password);
        this.username = username;
    }
}


export interface ConfirmEmailResponse {
}

export interface ConfirmEmailRequest {
    token: string
    userId: string
}

export interface ApplicationVersionResponse {
    applicationVersion: string
    uiVersion: string
}

export interface AuthServiceContext {
    login(request: LoginRequest): Promise<LoginResponse>

    register(registrationRequest: RegistrationRequest): Promise<RegistrationResponse>

    confirmEmail(confirmEmailRequest: ConfirmEmailRequest): Promise<string>

    fetchApplicationVersion(): Promise<ApplicationVersionResponse>

    refreshAuthData(authData: AuthData): Promise<LoginResponse>;

}