import {ColonyDetailResponse, TransferQuantityResponse} from "../../services/RealmServiceContext";
import React from "react";
import UnitQuantityDoodad from "../UnitQuantityDoodad";
import {Divider} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import HelpText from "../HelpText";

const ColonyMilitiaDetail = (props: { colony: ColonyDetailResponse }) => {

    return (
        <>
            <Paragraph>
                <Divider orientation={"left"}>Stationed Units</Divider>
                <HelpText message={"Units stationed in a colony require housing and food, they will defend the colony when under attack."}/>
                {props.colony?.militia
                    .filter((m: TransferQuantityResponse) => m.unit.name !== "Peasant")
                    .map(m => <UnitQuantityDoodad key={m.unit.id} militaryUnit={m.unit} quantity={m.quantity}/>)}

            </Paragraph>
            <Paragraph>
                <Divider orientation={"left"}> In Training</Divider>
                {props.colony?.inTraining.map(m => <UnitQuantityDoodad key={m.unit.id} militaryUnit={m.unit}
                                                                       quantity={m.quantity}/>)}
            </Paragraph>
        </>
    )
}

export default ColonyMilitiaDetail;