import {MilitaryUnitResponse} from "../../../services/ReferenceServiceContext";
import React from "react";
import MilitaryUnitFormControl from "../MilitaryUnitFormControl";
import {InputNumber} from "antd";

function TrainMilitaryUnitFormItem(props: {
    unit: MilitaryUnitResponse,
    onChange: (e: number) => void,
    value: number
}) {

    return (<>
            <MilitaryUnitFormControl
                inputNumber={<InputNumber min={0}
                                          type={"number"}
                                          value={props.value}
                                          onChange={(e) => {
                                              props.onChange(e || 0)
                                          }}/>}
                unit={props.unit}/>
            {/*<DebugData info={props.unit}/>*/}
        </>
    )
}

export default TrainMilitaryUnitFormItem;