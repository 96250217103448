import {Button, InputNumber, Modal} from "antd";
import numeral from "numeral";
import FormItemLabel from "antd/es/form/FormItemLabel";
import React from "react";
import FormItem from "antd/es/form/FormItem";
import {MilitaryUnitResponse} from "../services/ReferenceServiceContext";
import {MilitaryUnitHeaderResponse} from "../services/RealmServiceContext";
import MilitaryUnitDetail from "./military/MilitaryUnitDetail";

interface UnitQuantityDoodadProps {
    militaryUnit: MilitaryUnitResponse | MilitaryUnitHeaderResponse
    quantity: number,
    showZeroQuantity?: boolean,
    onChange?: (v: number) => void
}

const UnitQuantityDoodad = (props: UnitQuantityDoodadProps) => {
    const [showMilitaryUnitDetail, setShowMilitaryUnitDetail] = React.useState<boolean>(false);

    return props.onChange ?
        <>
            <FormItem>
                <FormItemLabel prefixCls={""} label={props.militaryUnit.name}/>
                <InputNumber min={0}
                             type={"number"}
                             value={props.quantity}
                             onChange={(e) => props.onChange?.(e || 0)}/>
            </FormItem>
        </>
        :
        !props.showZeroQuantity && props.quantity === 0 ? <></> :
            <>
                <Modal open={showMilitaryUnitDetail}
                       footer={null}
                       closeIcon={<></>}
                       onCancel={() => setShowMilitaryUnitDetail(false)}
                >
                    <MilitaryUnitDetail militaryUnitId={props.militaryUnit.id}/>
                </Modal>
                <Button type={"dashed"} style={{cursor: "pointer"}} onClick={() => setShowMilitaryUnitDetail(true)}>
                    {props.militaryUnit.name}
                    <>:&nbsp;</>
                    {numeral(props.quantity).format()}
                </Button>
            </>
}

export default UnitQuantityDoodad;