import {Coordinate, UnitTypeId} from "../services/RealmServiceContext";


// todo change this to use an endpoint asking if the user has xyz permission
export const ADMIN_EMAIL = "jeff@wemojema.com";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export function titleCase(s: string): string {
    let sentence = s.toLowerCase().split(" ").flatMap(splitStr => splitStr.split("_"));

    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.toString();
}

export function compareStringIgnoreCase(a: string | undefined, b: string | undefined): number {
    if (a === b)
        return 0;

    if (a === undefined && b !== undefined)
        return 1;

    if (a !== undefined && b === undefined)
        return -1;

    if (a !== undefined && b !== undefined) {
        const nameA = a.toUpperCase(); // ignore upper and lowercase
        const nameB = b.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    } else {
        throw new Error("Unable to compare!");
    }
}


export function distance(c1: Coordinate, c2: Coordinate): number {
    return Math.sqrt(Math.pow(c2.x - c1.x, 2) + Math.pow(c2.y - c1.y, 2));
}

export function stripBase64ImageDataFromImageUrl(imageUrl: string): string {
    return imageUrl.split(",")[1].trim();
}


export function mapKeySerializer(unitTypeId: UnitTypeId): string {
    return unitTypeId.id + "::" + unitTypeId.unitType;
}

/**
 * This cockamamie thing is because the backend javascript doesn't serialize maps
 * properly, so we have to convert it to an object. Use this when the Keys of an
 * object are unknown at compile time, thus cannot be typed. This is useful for
 * situations when the backend expects an object of n (unknown) keys.
 */
export function prepMapForSerialization(map: Map<string, any>): any {
    const result: any = {};
    map.forEach((v, k) => {
        result[k] = v;
    });
    return result;
}