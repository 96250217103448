import {Image, Layer} from "react-konva";
import React, {useEffect} from "react";
import useImage from "use-image";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../../services/APIServiceTypes";
import {activeRealm} from "../../../atoms";


function BaseMapLayer() {
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const [mapImage] = useImage(`${process.env.REACT_APP_ASSET_HOST}/assets/maps/` + realm?.id + ".png");

    useEffect(() => {
        console.debug("rendering BaseMapLayer");
    }, [])


    return <Layer key={"map-layer"} id={"map-layer"} visible={true}>
        <Image id={realm.id} image={mapImage}></Image>
    </Layer>
}

export default BaseMapLayer