interface ColonySizeNameProps {
    size: number
}
export function colonySizeName(size: number): string {
    switch (size) {
        case 16:
            return "Hamlet";
        case 32:
            return "Village";
        case 48:
            return "Town";
        case 64:
            return "City";
        case 80:
            return "Metropolis";
        default:
            return "Unknown Size - report this!";
    }
}
const ColonySizeName = (props: ColonySizeNameProps) => {



    return <>{colonySizeName(props.size)}</>;

}

export default ColonySizeName