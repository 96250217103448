import {Ruler} from "../../services/RealmServiceContext";
import React from "react";
import {Tooltip} from "antd";

interface FlagProps {
    ruler: Ruler
    size?: "small" | "medium" | "large" | undefined
}

const Flag = (props: FlagProps) => {
    function size(): number {
        switch (props.size) {
            case "small" :
                return 24
            case "medium":
                return 32
            case "large":
                return 64
            case undefined:
                return 24
        }
    }

    return <Tooltip title={props.ruler.name}>
        <div style={{border: 1, borderColor: "black", verticalAlign: "middle"}}>
        <img width={size()}
             alt={props.ruler.name + "'s flag"}
             src={`${process.env.REACT_APP_ASSET_HOST}/assets/flags/` + (props.ruler.flagId || "army") + ".png"}/>
        </div>
    </Tooltip>
}

export default Flag