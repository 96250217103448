import {Button} from "antd";

interface SaveButtonProps {
    save: () => void
    label?: string | JSX.Element
}

function SaveButton(props: SaveButtonProps) {

    return <div style={{float: "right", padding: ".5rem"}}>
        <Button type={"primary"} onClick={props.save}>{props.label || "Save"}</Button>
    </div>
}

export default SaveButton
