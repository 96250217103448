import {
    ColonyDTOType,
    Coordinate,
    CostDetailResponse,
    EntityPositionsResponse,
    FieldArmyRequest,
    RealmServiceContext
} from "../services/RealmServiceContext";
import React, {useState} from "react";
import {Divider, Form, Input, Select} from "antd";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../services/APIServiceTypes";
import {RealmAPI} from "../App";
import {activeRealm, currentCostsState, entityPositionsState} from "../atoms";
import useAuth, {AuthDetail} from "../hooks/useAuth";
import DebugData from "./DebugData";
import FormItem from "antd/es/form/FormItem";
import ResourcesBlock from "./resources/ResourcesBlock";
import NetworkCallButton from "./NetworkCallButton";

export interface Props {
    position: Coordinate
    onOk: () => void
}

function FieldArmyForm({position, onOk}: Props) {
    const authDetail: AuthDetail = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const costs = useRecoilValue<CostDetailResponse>(currentCostsState)
    const [request, setRequest] = React.useState<FieldArmyRequest>({
        armyName: "",
        position: new Coordinate(Math.round(position.x), Math.round(position.y)),
        realmId: realm.id
    })

    function fieldArmy() {
        setLoading(true);
        realmService.fieldArmy(request, authDetail.authData)
            .then(r => {
                setLoading(false);
                    onOk();
                }
            )
            .catch(e => {
                setLoading(false);
                console.error(e)
            });
    }

    function colonySelected(value: string) {
        const pos = positions.colonies.find(c => c.id === value)?.position;
        if (pos)
            setRequest({...request, position: pos})
    }

    if (position.x+position.y === 0 && positions.colonies.filter((c: ColonyDTOType): boolean => c.ownerId === authDetail.authData.userId).length === 0) {
        return <>You have no colonies, to field an army on the map, right click on the map in the location for which you
            want to field it, then select Field Army</>
    }

    return (<>
        A new Army will cost: <ResourcesBlock showZeroValues={false} resources={costs.fieldArmy}/>
        <Divider/>
        <Form layout={"vertical"}>
            {positions.colonies.filter((c: ColonyDTOType): boolean => c.ownerId === authDetail.authData.userId).length !== 0 &&
                <FormItem label={"Colony"}>
                <Select style={{width: 256}}
                        onChange={colonySelected}
                        options={positions.colonies.filter(c => c.ownerId === authDetail.authData.userId).map((c: ColonyDTOType) => {
                            return {value: c.id, label: c.name}
                        })}/>
                </FormItem>
            }
            <FormItem label={"Name"}>
                <Input value={request.armyName}
                       required={true}
                       title={"Name"}
                       onChange={(e) => setRequest({...request, armyName: e.target.value})}/>
            </FormItem>
            <NetworkCallButton idleText={"Field Army"} loading={loading} onClick={fieldArmy}/>
            <DebugData info={request}/>
        </Form>
    </>)

}

export default FieldArmyForm