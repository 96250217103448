import {Resource} from "../../services/RealmServiceContext";
import {GiCorn, GiGoldBar, GiOre, GiStonePile, GiWoodPile} from "react-icons/gi";
import {Tag, Tooltip} from "antd";
import numeral from "numeral";

export interface Props {
    name: Resource
    quantity: number
}

function ResourceTag({name, quantity}: Props) {

    function resourceIcon() {
        switch (name) {
            case Resource.WOOD:
                return <GiWoodPile/>
            case Resource.ORE:
                return <GiOre/>
            case Resource.GOLD:
                return <GiGoldBar/>
            case Resource.FOOD:
                return <GiCorn/>
            case Resource.STONE:
                return <GiStonePile/>
            default:
                return <></>
        }
    }

    function resourceColor() {
        switch (name) {
            case Resource.WOOD:
                return "#693e23"
            case Resource.ORE:
                return "silver"
            case Resource.GOLD:
                return "gold"
            case Resource.FOOD:
                return "green"
            case Resource.STONE:
                return "gray"
            default:
                return "black"
        }
    }

    return <>
        <Tooltip title={name?.toLowerCase()}>
            <Tag color={resourceColor()} style={{cursor: "help"}}>
                {resourceIcon()}
                {" "}
                {numeral(quantity).format('0,0')}
            </Tag>
        </Tooltip>
    </>
}

export default ResourceTag;