import {APIResponse, AuthData} from "./APIServiceTypes";

export type Edge = {
    cp1?: TerrainType,
    cp2?: TerrainType,
    cp3?: TerrainType,
}

export interface Tile {
    tileSet?: string;
    id?: string;
    name?: string;
    center?: TerrainType
    north?: Edge
    south?: Edge
    east?: Edge
    west?: Edge
}

export enum TerrainType {
    GRASS = "GRASS", SEA = "SEA", FOREST = "FOREST", MOUNTAIN = "MOUNTAIN", BLANK = "BLANK"
}

export interface TilesResponse extends APIResponse {
    tiles: Tile[]
}

export interface FetchPresignedPutUrlRequest {
    fileName: string
    contentType: string
}

export interface PresignedPutUrlResponse extends APIResponse {
    presignedUrl: string
}

export interface UploadRequest {
    presignedUrl: string
    file: File
}

export interface TileResponse {
    tile: Tile
}

export interface CommitTileRequest {
    tile: Tile
    fileName: string
}

export interface AmendTileRequest {
    tile: Tile
}

export interface IdRequest {
    id: string
}

export interface MapServiceContext {
    fetchTiles(authData: AuthData): Promise<TilesResponse>

    fetchPresignedPutUrl(request: FetchPresignedPutUrlRequest, auth: AuthData): Promise<PresignedPutUrlResponse>

    uploadFile(request: UploadRequest): void;

    commitTile(request: CommitTileRequest, authData: AuthData): Promise<TileResponse>;

    amendTile(request: AmendTileRequest, authData: AuthData): Promise<TileResponse>;

    removeTile(request: IdRequest, authData: AuthData): void;

}