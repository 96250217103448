import {Coordinate, MobileEntity, RealmServiceContext, RulersResponse} from "../../services/RealmServiceContext";
import React, {useEffect} from "react";
import useImage from "use-image";
import {Group, Image, Line, Rect} from "react-konva";
import Konva from "konva";
import {RecoilBridge, useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilState, useRecoilValue} from "recoil";
import {APIResponse, RealmDetail} from "../../services/APIServiceTypes";
import {message} from "antd";
import {
    activeRealm,
    GlobalLayers,
    GlobalLayersState,
    mobileEntityPropertyVisibilityState,
    rulersOfTheRealm,
    selectedMobileEntityState,
    UserAction,
    userActionState
} from "../../atoms";
import {RealmAPI} from "../../App";
import Journey from "../Journey";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import MobMenu from "./MobMenu";
import {Html} from "react-konva-utils";
import MobName from "./MobName";
import MobHelp from "./MobHelp";
import DirectionalArrow from "./DirectionalArrow";
import KonvaEventObject = Konva.KonvaEventObject;

export interface Props {
    mob: MobileEntity
    mousePosition: Coordinate
}

const X_OFFSET = 8;
const Y_OFFSET = 6;

export interface MobileEntityPropertyVisibility {
    path: boolean
}



function Mob({mob, mousePosition}: Props) {
    const authDetail: AuthDetail = useAuth();
    const [selectedMob, setSelectedMob] = useRecoilState<MobileEntity>(selectedMobileEntityState)
    const [userAction, setUserAction] = useRecoilState<UserAction>(userActionState)
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const [path, setPath] = React.useState<Coordinate[]>([])
    // todo remove this hard coded host name
    const [mobImage] = useImage(`${process.env.REACT_APP_ASSET_HOST}/assets/flags/` + (rulers[mob.header.owner]?.flagId || "army") + ".png");
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    const mobProps = useRecoilValue<MobileEntityPropertyVisibility>(mobileEntityPropertyVisibilityState)
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState);
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()

    useEffect(() => {
        console.debug("Selected Mob:", selectedMob);
    }, [selectedMob])

    useEffect(() => {
        console.debug("showing path for mob:")
        console.debug(mob)
        if (mobProps.path && mob.header.vector.path.path)
            setPath(mob.header.vector.path.path)
        if (!mobProps.path)
            setPath([])
    }, [mob, mobProps.path])

    useEffect(() => {
        console.debug(userAction)
        if (selectedMob?.header.owner === authDetail.authData.userId && userAction === UserAction.MOVING)
            setPath([mob.header.vector.position]);
    }, [mob.header.vector.position, userAction, selectedMob?.header.owner, authDetail.authData.userId])

    function handleMoveMob(e: KonvaEventObject<globalThis.MouseEvent>) {
        if (e.evt.button !== 2 && authDetail.authData.userId === mob.header.owner) {
            if (userAction === UserAction.MOVING) {
                setPath([...path, mousePosition])
            } else {
                setSelectedMob(mob)
                setPath([...path, mob.header.vector.position])
                setUserAction(UserAction.MOVING);
            }
        } else {
            console.debug("handle left click on mob")
        }
    }

    function handleDoubleClick() {
        console.debug("Handling Double Click")
        if (userAction === UserAction.MOVING) {
            setPath([...path, mousePosition])
            setUserAction(path.length > 0 ? UserAction.PATH_SET : UserAction.IDLE)
            realmService.moveMob({mobId: mob.header.id, realmId: realm.id, path: path, authData: authDetail.authData})
                .then((r: APIResponse) => {
                    console.debug(r)
                    if (r.errors && r.errors.length > 0) {
                        r.errors.forEach(e => message.error(e))
                        setUserAction(UserAction.IDLE);
                        setPath([]);
                    }
                })
        }
    }

    function mobYPosition(): number {
        if (selectedMob?.header.id === mob.header.id && userAction === UserAction.MOVING) {
            return mousePosition.y - Y_OFFSET
        } else {
            return mob.header.vector.position.y - Y_OFFSET
        }
    }

    function mobXPosition(): number {
        if (selectedMob?.header.id === mob.header.id && userAction === UserAction.MOVING) {
            return mousePosition.x - X_OFFSET
        } else {
            return mob.header.vector.position.x - X_OFFSET
        }
    }


    function handleContextMenu(e: KonvaEventObject<globalThis.MouseEvent>) {
        if (e.evt.button === 2) {
            console.debug("right click")
            setSelectedMob(mob)
            // todo do something different here this is a hack
            setTimeout(() => {
                setUserAction(UserAction.INSPECT_MOB);
            }, 100);
        }
    }

    function pathPoints() {
        if (userAction === UserAction.MOVING)
            return [...path.map(c => [c.x, c.y]).flat(1), mobXPosition() + X_OFFSET, mobYPosition() + Y_OFFSET];
        else
            return path.map(c => [c.x, c.y]).flat(1);
    }



    useEffect(() => {
        console.debug("Should Journey be visible: ", path.length > 0 && mob.header.vector.journey.length > 0)
    }, [path, mob])

    return (<>
        {/*<Circle x={mobXPosition()} y={mobYPosition()} offset={new Coordinate(8, 6)} width={10} stroke={"black"}/>*/}
        <Line
            visible={selectedMob && selectedMob.header.id === mob.header.id && userAction === UserAction.MOVING && path.length > 0}
            stroke={"black"}
            tension={0.125}
            points={pathPoints()}/>
        <Journey points={mob.header.vector.journey}
                 visible={selectedMob && selectedMob.header.id === mob.header.id && userAction !== UserAction.MOVING && path.length > 0 && mob.header.vector.journey.length > 0}/>
        <Rect
            visible={selectedMob && authDetail.authData.userId === selectedMob.header.owner && selectedMob && mob.header.id === selectedMob.header.id}
            stroke={"#339999"}
            shadowBlur={10}
            x={mobXPosition() - 2}
            y={mobYPosition() - 2}
            width={20}
            height={16}
        />
        {/*todo this arrow stopped showing up after moving the army recently, what's going on here?*/}
        {mob.header.vector.path.path.length > 0 &&
            <DirectionalArrow mob={mob}/>}
        {mob.header.vector.position &&
            <Image
                onContextMenu={handleContextMenu}
                onDblClick={handleDoubleClick}
                onClick={handleMoveMob}
                x={mobXPosition()}
                y={mobYPosition()}
                width={16}
                height={12}
                alt={mob.header.name}
                image={mobImage}/>
        }
        {mob.header.vector.position &&
            globalLayers.armyLabelLayer &&
            <Group id={"army-label-" + mob.header.id}
                   x={mob.header.vector.position.x}
                   y={mob.header.vector.position.y + 8}>
                <Html>
                    <RecoilBridge>
                    <MobName mob={mob}/>
                    </RecoilBridge>
                </Html>
            </Group>
        }
        {selectedMob &&
            selectedMob === mob &&
            userAction === UserAction.MOVING &&
            <MobHelp mousePosition={mousePosition} mob={mob}/>
        }
        {selectedMob &&
            selectedMob === mob &&
            userAction === UserAction.INSPECT_MOB
            && selectedMob.header.owner === authDetail.authData.userId &&
            <MobMenu mob={selectedMob}
                     mobProps={mobProps}
                     setSelectedMob={setSelectedMob}
                     setUserAction={setUserAction}
            />}
    </>)

}

export default Mob