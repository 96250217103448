import React, {useEffect, useState} from "react";
import {WeaponResponse} from "../../../services/ReferenceServiceContext";
import WeaponTable from "./WeaponTable";
import useReferenceServiceContext from "../useReferenceServiceContext";


function ManageWeapons() {
    const [authDetail, refService] = useReferenceServiceContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [weapons, setWeapons] = useState<WeaponResponse[]>([]);

    useEffect(() => {
        setLoading(true);
        refService.listWeapons(authDetail.authData)
            .then(r => {
                setLoading(false);
                setWeapons(r);
            }).catch(e => {
                setLoading(false);
        })
    }, []);

    return <div>
        <WeaponTable loading={loading} weapons={weapons}/>
    </div>;

}

export default ManageWeapons;