import {APIResponse, AuthData} from "./APIServiceTypes";


export interface MemberApplication {
    userId: string;
    message: string;
    status: string;
    statusReason: string;
}

export interface KingdomDTOResponse extends APIResponse {
    id: string
    realmId: string
    name: string
    leaderId: string
    officerIds: string[]
    memberIds: string[]
    applications: MemberApplication[]
}

export interface FormKingdomRequest {
    realmId: string
    name: string
}

export interface ListKingdomsRequest {
    realmId: string
}

export interface JoinKingdomRequest {
    id: string
    message: string
}

export interface DispositionKingdomApplicationRequest {
    kingdomId: string
    userId: string
}

export enum Rank {
    LEADER = "LEADER",
    OFFICER = "OFFICER",
    MEMBER = "MEMBER"
}

export interface TransitionMemberRequest {
    kingdomId: string
    userId: string
    rank?: Rank
}

export interface LeaveKingdomRequest {
    kingdomId: string
}

export interface KingdomServiceContext {

    listKingdoms(request: ListKingdomsRequest, authData: AuthData): Promise<KingdomDTOResponse[]>;

    detail(kingdomId: string, authData: AuthData): Promise<KingdomDTOResponse>;

    formKingdom(request: FormKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    joinKingdom(joinKingdomRequest: JoinKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    acceptApplication(request: DispositionKingdomApplicationRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    declineApplication(request: DispositionKingdomApplicationRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    promote(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    demote(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    expel(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse>;

    leave(request: LeaveKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse>;
}

