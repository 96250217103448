import {RealmHeader} from "../services/APIServiceTypes";
import {Button, Card, Col, Image, Row, Skeleton, Statistic} from "antd";
import React, {useEffect} from "react";
import {useSetRecoilState} from "recoil";
import {selectedRealmIdState} from "../atoms";
import useRealmServiceContext from "../useRealmServiceContext";
import {RealmDetailResponse} from "../services/RealmServiceContext";

function RealmDetailCard(props: { realm: RealmHeader }) {
    const setSelectedRealmId = useSetRecoilState<string>(selectedRealmIdState)
    const [authDetail, realmContext] = useRealmServiceContext();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [realmDetail, setRealmDetail] = React.useState<RealmDetailResponse | undefined>(undefined);

    useEffect(() => {
        setLoading(true)
        realmContext.realmDetail({realmId: props.realm.id, authData: authDetail.authData})
            .then(realmDetail => {
                setRealmDetail(realmDetail)
                setLoading(false)
            })
    }, [props.realm.id, authDetail.authData, realmContext]);

    return <Card loading={loading}
                 title={<>
                     <Row justify={"space-evenly"} align={"middle"}>
                         <Col span={6}>{props.realm.name}</Col>
                         <Col span={12}/>
                         <Col span={6}>
                             <Row justify={"end"}>
                                 <Button type={"primary"} onClick={() => setSelectedRealmId(props.realm.id)}>
                                     Join
                                 </Button>
                             </Row>
                         </Col>
                     </Row>
                 </>}

                 cover={<Image loading={"eager"} alt={props.realm.name + " map"}
                               key={"minimap"}
                               src={`${process.env.REACT_APP_ASSET_HOST}/assets/maps/${props.realm.id}-minimap.png`}
                 />}>
        <Skeleton loading={loading}>
            {/*todo use the RealmStats component*/}
            <Row justify={"space-around"}>
                <Statistic title={"Current Era"} value={realmDetail?.eraCount}/>
                <Statistic title={"Status"} value={realmDetail?.activeEraId ? "Active" : "Between Games"}/>
                <Statistic title={"Current Era Turn"} value={realmDetail?.eraTurnState?.turn}/>
                <Statistic title={"Map Size"} value={realmDetail?.realmMap.map.length}/>
                <Statistic title={"Players"} value={realmDetail?.users?.length}/>
            </Row>
        </Skeleton>
    </Card>
}

export default RealmDetailCard;
