import React, {useEffect, useState} from "react";
import {ArmorResponse} from "../../../services/ReferenceServiceContext";
import ArmorTable from "./ArmorTable";
import useReferenceServiceContext from "../useReferenceServiceContext";

function ManageArmor() {
    const [authDetail, refService] = useReferenceServiceContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [armor, setArmor] = useState<ArmorResponse[]>([]);

    useEffect(() => {
        setLoading(true);
        refService.listArmor(authDetail.authData)
            .then(r => {
                setArmor(r);
                setLoading(false);
            }).catch(e => {
            setLoading(false);
        })
    }, []);

    return <ArmorTable loading={loading} armor={armor}/>;
}

export default ManageArmor;