import React from "react";
import {ArmyDetailResponse, MilitaryUnitQuantityResponse} from "../../services/RealmServiceContext";
import {Col, Row, Space, Statistic} from "antd";
import UnitQuantityDoodad from "../UnitQuantityDoodad";
import DebugData from "../DebugData";

interface ArmyUnitDetailProps {
    armyDetail: ArmyDetailResponse
}

const unitDisplay = (list: MilitaryUnitQuantityResponse[]): JSX.Element => {
    const result: JSX.Element[] = []
    list.forEach((u: MilitaryUnitQuantityResponse) => {
        if(u.quantity !== 0) {
            result.push(<UnitQuantityDoodad key={u.unitTypeId.id} militaryUnit={u.unit} quantity={u.quantity}/>)
        }
    })
    return <Space direction={"vertical"}>{result}</Space>;
}

const ArmyUnitDetail = (props: ArmyUnitDetailProps) => {

    return <><Row>
        <Col span={8}><Statistic title={"Experience"} value={props.armyDetail.armyDetail.experience}/></Col>
        <Col span={8}><Statistic title={"Fatigue"} value={props.armyDetail.armyDetail.fatigue}/></Col>
        <Col span={8}><Statistic title={"Injured Units"} value={props.armyDetail.armyDetail.injuredUnits}/></Col>
    </Row>
        <>{unitDisplay(props.armyDetail.armyDetail.units)}</>
        <DebugData info={props.armyDetail}/>
    </>
}

export default ArmyUnitDetail;