import {KingdomDTOResponse} from "../../services/KingdomServiceContext";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";
import RulerName from "../player/RulerName";

const KingdomLeader = (props: { kingdom: KingdomDTOResponse }) => {
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    return <RulerName ruler={rulers[props.kingdom.leaderId]}/>
}

export default KingdomLeader