import {Layer, Line} from "react-konva";
import React, {useEffect} from "react";
import {useRecoilValue} from "recoil";
import {RealmDetail} from "../../../services/APIServiceTypes";
import {activeRealm, GlobalLayers, GlobalLayersState} from "../../../atoms";


function GridLayer() {
    const [mapGrid, setMapGrid] = React.useState<JSX.Element[]>([])
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState)

    useEffect(() => {
        // disable the right click menu on the map
        if (document && document.getElementById(realm.id) !== null)
            //@ts-ignore
            document.getElementById(realm.id)
                .addEventListener("contextmenu", function (e) {
                    e.preventDefault();
                });
        console.debug("establishing Map Grid")
        const rows: number = realm.realmMap.map.length;
        const cols: number = realm.realmMap.map[0].length;
        const lines: JSX.Element[] = [];
        for (let i = 0; i < rows; i++) {
            lines.push(<Line key={`grid-row-${i}`} visible={globalLayers?.grid}
                             stroke={"black"}
                             strokeWidth={.25}
                             points={[0, i * 16, cols * 16, i * 16]}/>)
        }
        for (let i = 0; i < cols; i++) {
            lines.push(<Line key={`grid-col-${i}`} visible={globalLayers?.grid}
                             stroke={"black"}
                             strokeWidth={.25}
                             points={[i * 16, 0, i * 16, rows * 16]}/>)
        }
        setMapGrid(lines);
    }, [realm.id, realm.realmMap])

    return <Layer key={"grid-layer"} id={"grid-layer"} visible={globalLayers?.grid}>
        {mapGrid}
    </Layer>
}

export default GridLayer;