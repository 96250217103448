import {Layer} from "react-konva";
import {Coordinate, EntityPositionsResponse, MobileEntity} from "../../../services/RealmServiceContext";
import Mob from "../../Mob/Mob";
import React, {useEffect} from "react";
import {useRecoilValue} from "recoil";
import {entityPositionsState, GlobalLayers, GlobalLayersState} from "../../../atoms";

export interface Props {
    mousePosition: Coordinate
}

function MobLayer({mousePosition}: Props) {
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState);
    const [mobs, setMobs] = React.useState<MobileEntity[]>([])
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState)

    useEffect(() => {
        if (positions) {
            console.debug("New positions loaded!")
            console.debug(positions.mobs.map(m => m.header.vector.position))
            setMobs(positions.mobs)
        }
    }, [positions])


    return <><Layer key={"mob-layer"} id={"mob-layer"} visible={globalLayers.mobLayer}>
        {mobs.map((m: MobileEntity) => <Mob key={`mob-${m.header.id}`} mousePosition={mousePosition} mob={m}/>)}
    </Layer>
    </>

}

export default MobLayer