import {Button, Dropdown, MenuProps, Space, Switch} from "antd";
import {useRecoilState} from "recoil";
import {GlobalLayers, GlobalLayersState} from "../atoms";
import React from "react";
import {IoLayersOutline} from "react-icons/io5";

interface LayersMenuProps {

}

const LayersMenu = (props: LayersMenuProps) => {
    const [globalLayers, setGlobalLayers] = useRecoilState<GlobalLayers>(GlobalLayersState)
    const [open, setOpen] = React.useState<boolean>(false);

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === '3') {
            setOpen(false);
        }
    };


    const items: MenuProps['items'] = [
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, grid: e})}
                                  checked={globalLayers.grid} title={"Grid"}/>Grid</Space>,
            key: '1',
        },
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, fogOfWar: e})}
                                  checked={globalLayers.fogOfWar} title={"Fog of War"}/>Fog of War</Space>,
            key: '2',
        },
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, mobLayer: e})}
                                  checked={globalLayers.mobLayer} title={"Armies"}/>Armies</Space>,
            key: '3',
        },
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, colonyLabelLayer: e})}
                                  checked={globalLayers.colonyLabelLayer} title={"Colony-Labels"}/>Colony Labels</Space>,
            key: '4',
        },
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, armyLabelLayer: e})}
                                  checked={globalLayers.armyLabelLayer} title={"Army-Labels"}/>Army Labels</Space>,
            key: '5',
        },
        {
            label: <Space><Switch size={"small"}
                                  onChange={(e: boolean) => setGlobalLayers({...globalLayers, debugLayer: e})}
                                  checked={globalLayers.debugLayer} title={"Debug-Layer"}/>Debug Layer</Space>,
            key: '6',
        }
    ];

    return (
        <Dropdown
            open={open}
            menu={{items, onClick: handleMenuClick}}
        >
            <Button size={"small"} onClick={() => setOpen(!open)}>
                <Space><IoLayersOutline/>Map Layers</Space>
            </Button>
        </Dropdown>
    )
}

export default LayersMenu