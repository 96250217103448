import SettleColony from "../SettleColony";
import {Button, Modal, Space} from "antd";
import React, {useEffect} from "react";
import {GiCastle} from "react-icons/gi";

const SettleColonyButton = (props: { mobId: string, mobName: string }) => {
    const [visible, setVisible] = React.useState<boolean>(false);

    useEffect(() => {
        console.debug("Rendering Settle Colony Button", visible)
    }, [visible]);

    function handleClick() {
        console.debug("Settle Colony Button Clicked")
        setVisible(true)
    }

    return <>
        <Modal title={"Settle Colony"}
               footer={false}
               width={800}
               open={visible}
               onCancel={() => setVisible(false)}
        >
            <SettleColony mobId={props.mobId} mobName={props.mobName}/>
        </Modal>
        <Button size={"small"} onClick={handleClick}>
            <Space><GiCastle/>Settle Colony</Space>
        </Button>
    </>;
}

export default SettleColonyButton