import {Button, Space, Tooltip, Typography} from "antd";
import ColonySizeName from "./ColonySizeName";
import ColonySizeIcon from "./ColonySizeIcon";
import React from "react";
import Flag from "../player/Flag";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";

interface ColonyIconProps {
    size: number
    name: string
    ownerId: string

}

const ColonyIcon = (props: { colony: ColonyIconProps, variant?: "default" | "dark" | "light" }) => {
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    // todo the Tag color could be red for black for enemy, green for friendly
    return <Button type={"text"} style={{color: props.variant === "dark" ? "#FFF" : "#000", cursor: "pointer"}}>
        <Space>
            <Flag size={"medium"} ruler={rulers[props.colony.ownerId]}/>
            <Tooltip
                title={<ColonySizeName size={props.colony.size}/>}
            >
                <b>{props.colony.name}</b>
                <ColonySizeIcon size={props.colony.size}/>
            </Tooltip>
        </Space>
    </Button>


}

export default ColonyIcon