import {Ruler} from "../../services/RealmServiceContext";
import Flag from "./Flag";
import {Space} from "antd";
import React from "react";

const RulerName = (props: { ruler: Ruler, flagOnly?:boolean }) => {

    return <Space><Flag ruler={props.ruler}/> {props.flagOnly ? <></> : props.ruler.name}</Space>
}

export default RulerName