import {atom, RecoilState} from "recoil";
import {AuthData, RealmDetail, RealmHeader} from "./services/APIServiceTypes";
import {
    ArmySizesResponse,
    ColonyDetailResponse,
    CostDetailResponse,
    EntityPositionsResponse,
    EraTurnState,
    MobileEntity,
    PlayerStatusResponse,
    RulersResponse
} from "./services/RealmServiceContext";
import {KingdomDTOResponse} from "./services/KingdomServiceContext";
import LocalStorageKeys, {localStorageKeys} from "./LocalStorageKeys";
import {MobileEntityPropertyVisibility} from "./components/Mob/Mob";
import {RulerNotification} from "./Notification";

export enum UserAction {
    IDLE = "IDLE",
    CONTEXT_MENU = "CONTEXT_MENU",
    FIELD_ARMY = "FIELD_ARMY",
    MOVING = "MOVING",
    INSPECT_MOB = "INSPECT_MOB",
    PATH_SET = "PATH_SET"
}

export const receivedMessagesState: RecoilState<RulerNotification[]> = atom<RulerNotification[]>({
    key: 'receivesMessagesState',
    default: []
})

export const eraTurnState: RecoilState<EraTurnState> = atom<EraTurnState>({
    key: 'eraTurnState',
    default: undefined
})
export const activeRealm: RecoilState<RealmDetail> = atom<RealmDetail>({
    key: 'realmDetail',
    default: undefined,
})

export const entityPositionsState: RecoilState<EntityPositionsResponse> = atom<EntityPositionsResponse>({
    key: 'entityPositions',
    default: undefined,
})

export const realms: RecoilState<RealmHeader[]> = atom<RealmHeader[]>({
    key: 'realmHeaders',
    default: undefined,
})

export const playerStatusState: RecoilState<PlayerStatusResponse> = atom<PlayerStatusResponse>({
    key: 'playerStatus',
    default: undefined,
})

export const kingdoms: RecoilState<KingdomDTOResponse[]> = atom<KingdomDTOResponse[]>({
    key: 'kingdoms',
    default: []
})

export const authDataState: RecoilState<AuthData | undefined> = atom<AuthData | undefined>({
    key: 'authData',
    default: localStorage.getItem(LocalStorageKeys.AUTH_DATA) !== null ? JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH_DATA) || "{}") : undefined
})

export const helpEnabled: RecoilState<boolean> = atom<boolean>({
    key: 'helpEnabled',
    default: localStorage.getItem(LocalStorageKeys.DISPLAY_HELP) !== null ? (localStorage.getItem(LocalStorageKeys.DISPLAY_HELP) || "1") !== "0" : true
})

export const realmListState: RecoilState<RealmHeader[]> = atom<RealmHeader[]>({
    key: 'realmList',
    default: []
})

export const selectedRealmIdState: RecoilState<string> = atom<string>({
    key: 'selectedRealmId',
    default: localStorage.getItem(localStorageKeys.REALM_ID) || ""
})

export const userActionState: RecoilState<UserAction> = atom<UserAction>({
    key: 'userAction',
    default: UserAction.IDLE
})

export const selectedMobileEntityState: RecoilState<MobileEntity> = atom<MobileEntity>({
    key: 'selectedMob',
    default: undefined
})

export const mobileEntityPropertyVisibilityState: RecoilState<MobileEntityPropertyVisibility> = atom<MobileEntityPropertyVisibility>({
    key: 'mobileEntityPropertyVisibilityState',
    default: {path: false}
})

export const rulersOfTheRealm: RecoilState<RulersResponse> = atom<RulersResponse>({
    key: 'rulersOfRealm',
    default: {}
})

export const armySizeChartState: RecoilState<ArmySizesResponse> = atom<ArmySizesResponse>({
    key: 'armySizes',
    default: {}
})

export const colonyState: RecoilState<ColonyDetailResponse> = atom<ColonyDetailResponse>({
    key: 'colony',
    default: undefined
})
export const currentCostsState: RecoilState<CostDetailResponse> = atom<CostDetailResponse>({
    key: 'currentCosts',
    default: undefined
})

export interface GlobalLayers {
    armyLabelLayer: boolean;
    colonyLabelLayer: boolean;
    mobLayer: boolean;
    grid: boolean;
    fogOfWar: boolean;
    debugLayer: boolean;
}

export const GlobalLayersState: RecoilState<GlobalLayers> = atom<GlobalLayers>({
    key: 'globalLayers',
    default: localStorage.getItem(LocalStorageKeys.LAYER_VISIBILITY) !== null
        ? JSON.parse(localStorage.getItem(LocalStorageKeys.LAYER_VISIBILITY) || "{}")
        : {
        armyLabelLayer: true,
        colonyLabelLayer: true,
        mobLayer: true,
            grid: false,
        fogOfWar: true,
            debugLayer: false
    }
})