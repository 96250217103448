import React, {useEffect, useState} from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {RealmDetail} from "../services/APIServiceTypes";
import {useRecoilState, useRecoilValue} from "recoil";
import {Coordinate, EntityPositionsResponse, MobileEntity} from "../services/RealmServiceContext";
import {Stage} from "react-konva";
import {Card, Modal} from "antd";
import Konva from "konva";
import {
    activeRealm,
    entityPositionsState,
    GlobalLayers,
    GlobalLayersState,
    selectedMobileEntityState,
    UserAction,
    userActionState
} from "../atoms";
import GridLayer from "./map/layers/GridLayer";
import ColonyLayer from "./map/layers/ColonyLayer";
import MobLayer from "./map/layers/MobLayer";
import BaseMapLayer from "./map/layers/BaseMapLayer";
import FieldArmyForm from "./FieldArmyForm";
import FogOfWarLayer from "./map/layers/fogofWar/FogOfWarLayer";
import useAuth, {AuthDetail} from "../hooks/useAuth";
import DebugLayer from "./map/layers/DebugLayer";
import KonvaEventObject = Konva.KonvaEventObject;

interface Props {
    contentHeight: number
}


function MapContent({contentHeight}: Props) {
    const realm = useRecoilValue<RealmDetail>(activeRealm)
    const authDetail: AuthDetail = useAuth();
    const [userAction, setUserAction] = useRecoilState<UserAction>(userActionState)
    const [mousePosition, setMousePosition] = React.useState<Coordinate>(new Coordinate(0, 0))
    const [mob, setMob] = useRecoilState<MobileEntity>(selectedMobileEntityState)
    const mappedEntities = useRecoilValue<EntityPositionsResponse>(entityPositionsState);
    const [affixedMousePosition, setAffixedMousePosition] = useState<Coordinate>(mousePosition);
    const [globalLayers, setGlobalLayers] = useRecoilState<GlobalLayers>(GlobalLayersState)

    useEffect(() => {
        console.debug("MapContent UserAction: ", userAction);
        if (userAction === UserAction.MOVING)
            setGlobalLayers({...globalLayers, armyLabelLayer: false, colonyLabelLayer: false})
    }, [setGlobalLayers, userAction])

    useEffect(() => {
        console.debug("Adjusting Map Height: " + contentHeight + "px;")
    }, [contentHeight])

    function handleMouseMove(e: KonvaEventObject<PointerEvent>): void {
        let stage = e.target.getStage();
        //@ts-ignore // todo figure out what the Type actually is here
        setMousePosition({x: stage.getPointerPosition().x, y: stage.getPointerPosition().y});
    }

    function handleContextMenu(e: KonvaEventObject<MouseEvent>) {
        e.evt.preventDefault()
        if (mappedEntities.mobs.filter(m => m.header.owner === authDetail.authData.userId).length > 0) {
            setUserAction(UserAction.CONTEXT_MENU)
        } else {
            setAffixedMousePosition(mousePosition);
            setUserAction(UserAction.FIELD_ARMY);
        }
    }

    function handleFieldArmy() {
        setUserAction(UserAction.IDLE)
    }

    useEffect(() => {
        console.debug(userAction);
        console.debug(mob)
    }, [userAction, mob])

    return (
        <ScrollContainer hideScrollbars={true} style={{position: "relative", overflow: "auto", height: contentHeight}}>
            <Stage onMouseMove={handleMouseMove}
                   onContextMenu={handleContextMenu}
                   width={realm.realmMap.map[0].length * 16}
                   height={realm.realmMap.map.length * 16}>
                <BaseMapLayer/>
                <GridLayer/>
                <ColonyLayer/>
                <FogOfWarLayer/>
                <MobLayer mousePosition={mousePosition}/>
                {globalLayers.debugLayer && <DebugLayer mousePosition={mousePosition}/>}
            </Stage>
            {<><Modal destroyOnClose footer={null} open={userAction === UserAction.FIELD_ARMY}
                      onCancel={() => setUserAction(UserAction.IDLE)}>
                <Card title={"Field Army"} bordered={false}>
                    <FieldArmyForm onOk={handleFieldArmy} position={affixedMousePosition}/>
                </Card>
            </Modal>
            </>}
        </ScrollContainer>
    );
}

export default MapContent;