import FormItem from "antd/es/form/FormItem";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {Input} from "antd";


function FreeformShortTextField(props: { label: string, value: string, onChange: (v: string) => void }) {

    return <FormItem>
        <FormItemLabel required={true} label={props.label} prefixCls={""}/>
        <Input value={props.value}
               onChange={(v) => props.onChange(v.target.value)}/>
    </FormItem>

}

export default FreeformShortTextField;