import {Modal, Space} from "antd";
import Flag from "../player/Flag";
import MobIcon from "./MobIcon";
import {InspectEnemyArmy} from "./InspectEnemyArmy";
import {ArmyHeader, EnemyArmyHeader, MobileEntity, RulersResponse} from "../../services/RealmServiceContext";
import React from "react";
import {useRecoilValue} from "recoil";
import {rulersOfTheRealm} from "../../atoms";

const EnemyArmyInspectorModal = (props: {
    open: boolean,
    inspectingArmy: MobileEntity,
    enemyArmy: EnemyArmyHeader | ArmyHeader,
    onCancel: () => void
}) => {
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    return <Modal title={<Space><Flag ruler={rulers[props.enemyArmy.ownerId]}/>{props.enemyArmy.name}<MobIcon
        mob={props.enemyArmy}/></Space>}
                  footer={false}
                  open={props.open}
                  onCancel={props.onCancel}>

        <InspectEnemyArmy
            army={{
                ...props.inspectingArmy.header,
                size: props.inspectingArmy.size,
                ownerId: props.inspectingArmy.header.owner,
                position: props.inspectingArmy.header.vector.position
            }}
            enemy={{
                ...props.enemyArmy,
                probabilityOfDefeating: (props.enemyArmy as EnemyArmyHeader).probabilityOfDefeating
            }}/>
    </Modal>

}

export default EnemyArmyInspectorModal;