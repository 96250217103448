import {RxDotFilled} from "react-icons/rx";
import {MdModeStandby, MdOutlineCircle} from "react-icons/md";
import {BsRecordCircle} from "react-icons/bs";
import {FaBullseye} from "react-icons/fa";
import {BiError} from "react-icons/bi";
import React from "react";

interface ColonySizeIconProps {
    size:number
}

const ColonySizeIcon = (props: ColonySizeIconProps) => {
    function size(size:number):JSX.Element {
        switch (size) {
            case 16: return <RxDotFilled/>;
            case 32: return <MdOutlineCircle/>;
            case 48: return <MdModeStandby/>;
            case 64: return <BsRecordCircle/>;
            case 80: return <FaBullseye/>;
            default: return <BiError/>;
        }
    }

    return size(props.size);

}

export default ColonySizeIcon