import React, {useEffect} from "react";
import {Notification, RulerNotification} from "../Notification";
import {
    EntityPositionsResponse,
    EraStateUpdatedNotification,
    EraTurnState,
    PlayerStatusResponse
} from "../services/RealmServiceContext";
import Pusher from "pusher-js";
import {weReceivedABird} from "../services/APIResponseHandler";
import useAuth, {AuthDetail} from "./useAuth";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {RealmDetail} from "../services/APIServiceTypes";
import {activeRealm, entityPositionsState, eraTurnState, playerStatusState, receivedMessagesState} from "../atoms";

const useWebsocket = (): void => {
    const authDetail: AuthDetail = useAuth();
    const [pusher, setPusher] = React.useState<Pusher>()
    const selectedRealm = useRecoilValue<RealmDetail>(activeRealm);
    const setPlayerStatus = useSetRecoilState<PlayerStatusResponse>(playerStatusState);
    const setPositions = useSetRecoilState<EntityPositionsResponse>(entityPositionsState)
    const setEraTurnStatus = useSetRecoilState<EraTurnState>(eraTurnState)
    const [receivedMessages, setReceivedMessages] = useRecoilState<RulerNotification[]>(receivedMessagesState)

    useEffect(() => {
        if (authDetail.sessionIsActive() && pusher === undefined) {
            console.debug("Establishing websocket connection");
            setPusher(new Pusher(authDetail.authData.webSocketCredentials.key, authDetail.authData.webSocketCredentials.clusterDetail))
        }
    })
    useEffect(() => {
        console.debug("--------------- SELECTED REALM ----------------", selectedRealm)
        if (pusher && authDetail.sessionIsActive()) {
            pusher.bind(Notification.ERA_STATE_UPDATED_NOTIFICATION, (notification: EraStateUpdatedNotification) => {
                console.debug("[" + Date.now() + "] Received Notification: " + Notification.ERA_STATE_UPDATED_NOTIFICATION, notification)
                if (notification.eraTurnState.id === selectedRealm?.activeEraId) {
                    setPositions(notification.entityPositions)
                    setPlayerStatus(notification.playerStatus)
                    setEraTurnStatus(notification.eraTurnState)
                }
            })
        }
    })

    useEffect(() => {
        if (pusher && authDetail.sessionIsActive()) {
            console.debug("Binding Websocket callback for Global Notification Channels")
            pusher.subscribe("GLOBAL")
            pusher.subscribe(authDetail.authData.userId);
            pusher.bind(Notification.RULER_NOTIFICATION, (notification: RulerNotification): void => {
                weReceivedABird(notification);
                setReceivedMessages([...receivedMessages, notification])
            })
        }
    })


}

export default useWebsocket