import React from "react";
import {EntityPositionsResponse} from "../../services/RealmServiceContext";
import {useRecoilValue} from "recoil";
import {entityPositionsState} from "../../atoms";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import ArmiesTable from "./ArmiesTable";
import {Divider} from "antd";

const ArmiesTab = (props: {}) => {
    const authDetail: AuthDetail = useAuth();
    const armyPositions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)

    return (
        <>
            {armyPositions?.mobs.filter(m => m.header.owner === authDetail.authData.userId).length === 0 &&
                armyPositions.colonies.filter(c => c.ownerId === authDetail.authData.userId).length === 0 &&
                <>
                    You have no armies on the field and no colonies, to field a new army select a position on the map
                    and right click to bring up the context menu.
                    From there you will be able to field a new army at the location you select.
                    <Divider/>
                    <ArmiesTable
                        armies={armyPositions.mobs.filter(m => m.header.owner === authDetail.authData.userId)}/>
                </>
            }
            {armyPositions?.mobs.filter(m => m.header.owner === authDetail.authData.userId).length !== 0 &&
                <ArmiesTable armies={armyPositions.mobs.filter(m => m.header.owner === authDetail.authData.userId)}/>
            }

        </>)

}
export default ArmiesTab