import {ColonyDTOType} from "../services/RealmServiceContext";
import {Group, Image} from "react-konva";
import React from "react";
import useImage from "use-image";
import {Html} from 'react-konva-utils';
import Konva from "konva";
import {Button, Card, Segmented, Space} from "antd";
import {useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilValue, useSetRecoilState} from "recoil";
import {ControlActivity, ControlState, currentControlActivity} from "./GameBoard";
import {GiCastle, GiClosedDoors, GiOpenGate} from "react-icons/gi";
import {GlobalLayers, GlobalLayersState, UserAction, userActionState} from "../atoms";
import ColonyName from "./colony/ColonyName";
import {AiOutlineCloseSquare} from "react-icons/ai";
import KonvaEventObject = Konva.KonvaEventObject;

export interface Props {
    colony: ColonyDTOType
}

enum LocalUserAction {
    IDLE = "IDLE", INSPECT = "INSPECT"
}

function Colony({colony}: Props) {
    const [img] = useImage(`assets/colony/human/human-${colony.size}.png`);
    const [userAction, setUserAction] = React.useState<LocalUserAction>(LocalUserAction.IDLE)
    const setControlActivity = useSetRecoilState<ControlActivity>(currentControlActivity);
    const setTopLevelUserAction = useSetRecoilState<UserAction>(userActionState)
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState);
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()

    function fieldArmy() {
        console.debug("fielding army");
        setTopLevelUserAction(UserAction.FIELD_ARMY)
    }

    function handleContextMenu(e: KonvaEventObject<globalThis.MouseEvent>) {
        console.debug("handling context menu", e.target)
        if (e.evt.button === 2) {
            console.debug("right click on colony: " + colony.id)
            e.evt.preventDefault()
            setUserAction(LocalUserAction.INSPECT);
        }
    }

    function handleManageBuildingQueue() {
        console.debug("Handling Building Queue")
        setControlActivity({controlState: ControlState.MANAGE_CITY, controlObjectId: colony.id})
    }

    function handleToggleGates(e: any) {
        console.debug("handling gate toggle", e.target);
    }

    function colonyContextMenu() {
        if (userAction === LocalUserAction.INSPECT) {
            return (
                <Group id={"colony-menu-" + colony.id}
                       x={colony.position.x}
                       y={colony.position.y}>
                    <Html>
                        <Card size={"small"}
                              extra={<AiOutlineCloseSquare onClick={() => setUserAction(LocalUserAction.IDLE)}/>}
                              title={colony.name} onMouseLeave={() => setUserAction(LocalUserAction.IDLE)}>
                            {/*todo - Add Menu Items here for Mob Menu*/}
                            <Space direction={"vertical"}>
                                <Button block
                                        onClick={handleManageBuildingQueue}><Space><GiCastle/> {colony.name + " detail"}
                                </Space></Button>
                                <Segmented
                                    value={"closed"} // todo derive the state of the gates and indicate the selected gate here
                                    onClick={(e) => handleToggleGates(e)}
                                    options={[
                                        {
                                            label: 'Open',
                                            value: 'open',
                                            icon: <GiOpenGate/>,
                                        },
                                        {
                                            label: 'Closed',
                                            value: 'closed',
                                            icon: <GiClosedDoors/>,
                                        },
                                    ]}
                                />
                                <Button key={"field-army-button"} onClick={fieldArmy}>Field Army</Button>
                            </Space>

                        </Card>
                    </Html>
                </Group>)
        } else {
            return <></>
        }
    }

    // todo border the selected city with a rectangle using this: controlActivity.controlState
    return (<>
            {colonyContextMenu()}
            {globalLayers.colonyLabelLayer &&
                <Group id={"colony-menu-" + colony.id}
                       x={colony.position.x - (colony.size)}
                       y={colony.position.y - (colony.size)}>
                    <Html>
                        <RecoilBridge>
                        <ColonyName colony={colony}/>
                        </RecoilBridge>
                    </Html>
                </Group>}
            <Image
                onContextMenu={handleContextMenu}
                x={colony.position.x - (colony.size)}
                y={colony.position.y - (colony.size)}
                alt={colony.name}
                width={colony.size * 2}
                height={colony.size * 2}
                image={img}/>
        </>
    )
}

export default Colony