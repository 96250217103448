import {WeaponRequest} from "../../../services/ReferenceServiceContext";
import {useState} from "react";
import {Divider, Form, Space} from "antd";
import CostForm from "../form-components/CostForm";
import DebugData from "../../DebugData";
import DiceSelector from "../form-components/DiceSelector";
import DeleteButton from "../form-components/DeleteButton";
import SaveButton from "../form-components/SaveButton";
import FreeformShortTextField from "../form-components/FreeformShortTextField";
import RangeSelector from "../form-components/RangeSelector";
import useReferenceServiceContext from "../useReferenceServiceContext";


function WeaponForm(props: { weapon: WeaponRequest, onFinishCallback: () => void }) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [weaponRequest, setWeaponRequest] = useState<WeaponRequest>({...props.weapon});

    function save() {
        console.debug("saving weapon: ", weaponRequest)
        refService
            .defineWeapon(weaponRequest, authDetail.authData)
            .then(() => {
                props.onFinishCallback();
            }).catch(() => {
            console.error("error saving weapon")
        })
    }

    function remove(): void {
        if(weaponRequest.id) {
            refService.deleteWeapon(weaponRequest.id, authDetail.authData)
            props.onFinishCallback();
        }
    }

    return <Form>
        <Divider orientation={"left"}>Properties</Divider>
        <FreeformShortTextField label={"Name"} value={weaponRequest.name} onChange={(v) => setWeaponRequest({...weaponRequest, name: v})}/>
        <DiceSelector label={"Offense"} value={weaponRequest.offense} onChange={(v) => setWeaponRequest({...weaponRequest, offense: v})}/>
        <RangeSelector value={weaponRequest.range} onChange={(v) => setWeaponRequest({...weaponRequest, range: v})}/>
        <CostForm costs={weaponRequest.costs} setCosts={(costs) => setWeaponRequest({...weaponRequest, costs: costs})}/>
        <Space>
            <SaveButton save={save}/>
            {weaponRequest.id && <DeleteButton name={weaponRequest.name} onConfirm={remove}/>}
        </Space>
        <DebugData info={weaponRequest}/>
    </Form>
}

export default WeaponForm;