import {ClassRequest, ClassResponse} from "../../../services/ReferenceServiceContext";
import React, {useState} from "react";
import {Button, Modal, Table} from "antd";
import {MdEdit} from "react-icons/md";
import ClassForm from "./ClassForm";
import ResourcesBlock from "../../resources/ResourcesBlock";

const columns = [
    {title: 'Actions', dataIndex: 'actions', key: 'actions'},
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {title: 'Costs', dataIndex: 'costs', key: 'costs'},
    {title: 'Training Days', dataIndex: 'trainingDays', key: 'trainingDays'},
    {title: 'Wages', dataIndex: 'wages', key: 'wages'},
    {title: 'Description', dataIndex: 'description', key: 'description'},
]


function Truncate(props: { text: string }) {
    return <>{props.text}</>;
}

function ClassTable(props: { loading: boolean, classes: ClassResponse[] }) {
    const [selectedClass, setSelectedClass] = useState<ClassRequest | undefined>(undefined);

    return <>
        {selectedClass &&
            <Modal open={true}
                   footer={false}
                   onCancel={() => setSelectedClass(undefined)}
                   title={selectedClass.id ? "Edit " + selectedClass.name : "New Class"}
            >
                <ClassForm onFinishCallback={() => setSelectedClass(undefined)} class={{...selectedClass}}/>
            </Modal>
        }
        <Table loading={props.loading}
               columns={columns}
               dataSource={props.classes.sort((a,b) => a.unitType.localeCompare(b.unitType))
                   .map((classification) => {
                   return {
                       ...classification,
                       key: classification.id,
                       actions:
                           <Button onClick={() => setSelectedClass(classification)} size={"small"} icon={<MdEdit/>}/>,
                       description: <Truncate text={classification.description}/>,
                       costs: <ResourcesBlock showZeroValues={false} resources={classification.trainingCosts}/>,
                   }
               })}
        />
    </>

}

export default ClassTable;