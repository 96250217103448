import {KingdomDTOResponse} from "../../services/KingdomServiceContext";
import {Button, Modal} from "antd";
import KingdomDetailPanel from "./KingdomDetailPanel";
import React from "react";
import JoinKingdomForm from "./JoinKingdomForm";

const KingdomName = (props: { kingdom: KingdomDTOResponse }) => {
    const [openDetail, setOpenDetail] = React.useState<boolean>(false)
    const [joinKingdom, setJoinKingdom] = React.useState<boolean>(false)

    return <>
        <Button type={"dashed"} onClick={() => setOpenDetail(true)}>{props.kingdom.name}</Button>
        <Modal open={openDetail} footer={false} title={props.kingdom.name} onCancel={() => setOpenDetail(false)}>
            <KingdomDetailPanel kingdom={props.kingdom} joinKingdomCallback={() => setJoinKingdom(true)}/>
        </Modal>
        <Modal open={joinKingdom} footer={false} title={"Join" + props.kingdom.name} onCancel={() => setJoinKingdom(false)}>
            <JoinKingdomForm kingdom={props.kingdom} onFinished={() => setJoinKingdom(false)}/>
        </Modal>
    </>

}

export default KingdomName