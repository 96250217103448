import {useRecoilValue} from "recoil";
import {RulerNotification} from "../../Notification";
import {receivedMessagesState} from "../../atoms";

function MessagesPanel() {
    const receivedMessages = useRecoilValue<RulerNotification[]>(receivedMessagesState)

    return <>
        {receivedMessages.map((m) => {
            return <div key={m.timestamp}>{m.message}</div>
        })}
    </>
}


export default MessagesPanel