import {Button} from "antd";
import React, {MouseEventHandler, ReactNode} from "react";

const NetworkCallButton = (props: {
    idleText: ReactNode,
    loading: boolean,
    onClick: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>
}) => {

    return <Button loading={props.loading} type={"primary"} onClick={props.onClick}>
        {props.idleText}
    </Button>

}

export default NetworkCallButton;