import {
    DispositionKingdomApplicationRequest,
    FormKingdomRequest,
    JoinKingdomRequest,
    KingdomDTOResponse,
    KingdomServiceContext,
    LeaveKingdomRequest,
    ListKingdomsRequest,
    TransitionMemberRequest
} from "../KingdomServiceContext";
import {AuthData, authHeaderFrom} from "../APIServiceTypes";
import axios, {AxiosError, AxiosResponse} from "axios";
import {handleError, weSentABird} from "../APIResponseHandler";
import {ErrorResponse} from "../RealmServiceContext";




export const kingdomServiceContext: KingdomServiceContext = {

    listKingdoms(request: ListKingdomsRequest, authData: AuthData): Promise<KingdomDTOResponse[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/kingdoms/" + request.realmId, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the listKingdoms request");
            })
    },
    detail(kingdomId: string, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.get(process.env.REACT_APP_API_HOST + "/kingdom/" + kingdomId, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the Kingdom Detail request");
            })
    },

    formKingdom(request: FormKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/form", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the formKingdom request");
            })
    },

    joinKingdom(request: JoinKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/join", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the joinKingdom request");
            })
    },
    acceptApplication(request: DispositionKingdomApplicationRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        weSentABird({message: "We have replied with our own bird, tonight we celebrate for our Kingdom has become stronger still!"})
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/accept-application", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the accept-application request");
            })
    },
    declineApplication(request: DispositionKingdomApplicationRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        weSentABird({message: "We have replied with our own bird, there is no room in our Kingdom for weakness."})
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/decline-application", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the decline-application request");
            })
    },
    promote(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/promote", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the promote member request");
            })
    },
    demote(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/demote", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the demote officer request");
            })
    },
    expel(request: TransitionMemberRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/expel", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the expel member request");
            })
    },
    leave(request: LeaveKingdomRequest, authData: AuthData): Promise<KingdomDTOResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/kingdom/leave", request, authHeaderFrom(authData))
            .then((res: AxiosResponse<KingdomDTOResponse>) => {
                return res.data
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the leave request");
            })
    }


}