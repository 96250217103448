import {
    ArmorResponse,
    ClassRequest,
    ClassResponse,
    MilitaryUnitResponse,
    ReferenceServiceContext,
    UnitRequest,
    WeaponRequest,
    WeaponResponse
} from "../ReferenceServiceContext";
import axios, {AxiosError, AxiosResponse} from "axios";
import {APIResponse, AuthData, authHeaderFrom} from "../APIServiceTypes";
import {ErrorResponse} from "../RealmServiceContext";
import {handleError} from "../APIResponseHandler";
import {FetchPresignedPutUrlRequest, PresignedPutUrlResponse, UploadRequest} from "../MapServiceContext";

export const referenceServiceContext: ReferenceServiceContext = {

    listWeapons(authData: AuthData): Promise<WeaponResponse[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/ref/weapons", authHeaderFrom(authData))
            .then((res: AxiosResponse<WeaponResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the listWeapons request");
            })
    },

    defineWeapon(weaponRequest: WeaponRequest, authData: AuthData): Promise<WeaponResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/ref/define-weapon", weaponRequest, authHeaderFrom(authData))
            .then((res: AxiosResponse<WeaponResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the defineWeapon request");
            })
    },

    deleteWeapon(id: string, authData: AuthData) {
        axios.post(process.env.REACT_APP_API_HOST + "/ref/delete-weapon/" + id, {}, authHeaderFrom(authData))
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the deleteWeapon request");
            })
    },

    listArmor(authData: AuthData): Promise<ArmorResponse[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/ref/armor", authHeaderFrom(authData))
            .then((res: AxiosResponse<ArmorResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the listArmor request");
            })
    },

    defineArmor(armorRequest: ArmorResponse, authData: AuthData): Promise<ArmorResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/ref/define-armor", armorRequest, authHeaderFrom(authData))
            .then((res: AxiosResponse<ArmorResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the defineArmor request");
            })
    },

    deleteArmor(id: string, authData: AuthData) {
        axios.post(process.env.REACT_APP_API_HOST + "/ref/delete-armor/" + id, {}, authHeaderFrom(authData))
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the deleteArmor request");
            })
    },

    militaryUnitDetail(militaryUnitId: string, authData: AuthData): Promise<MilitaryUnitResponse> {
        return axios.get(process.env.REACT_APP_API_HOST + "/ref/unit/" + militaryUnitId, authHeaderFrom(authData))
            .then((res: AxiosResponse<MilitaryUnitResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the militaryUnitDetail request");
            })
    },

    listUnits(authData: AuthData): Promise<MilitaryUnitResponse[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/ref/units", authHeaderFrom(authData))
            .then((res: AxiosResponse<MilitaryUnitResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the listUnits request");
            })
    },

    listClasses(authData: AuthData): Promise<ClassResponse[]> {
        return axios.get(process.env.REACT_APP_API_HOST + "/ref/classes", authHeaderFrom(authData))
            .then((res: AxiosResponse<ClassResponse[]>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the listUnitTypes request");
            })
    },

    defineClass(classRequest: ClassRequest, authData: AuthData): Promise<ClassResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/ref/define-class", classRequest, authHeaderFrom(authData))
            .then((res: AxiosResponse<ClassResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the defineClass request");
            })
    },

    defineUnit(unitRequest: UnitRequest, authData: AuthData): Promise<MilitaryUnitResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/ref/define-unit", unitRequest, authHeaderFrom(authData))
            .then((res: AxiosResponse<MilitaryUnitResponse>) => {
                return res.data;
            }).catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the defineUnit request");
            })
    }
    ,
    deleteUnit(id: string, authData: AuthData) {
        axios.post(process.env.REACT_APP_API_HOST + "/ref/delete-unit/" + id, {}, authHeaderFrom(authData))
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error("There was an error calling the deleteUnit request");
            })
    },

    fetchPresignedPutUrl(request: FetchPresignedPutUrlRequest, auth: AuthData): Promise<PresignedPutUrlResponse> {
        return axios.post(process.env.REACT_APP_API_HOST + "/ref/presigned-upload-url",
            request,
            authHeaderFrom(auth)
        ).then((res: AxiosResponse<PresignedPutUrlResponse>) => {
                return {...res.data, errorMessages: []}
            }
        ).catch((err: AxiosError<APIResponse>) => {
            throw new Error("Not Yet Implemented!");
        })
    },

    uploadFile(request: UploadRequest): Promise<void> {
        return axios.put(request.presignedUrl, request.file, {
            headers: {'Content-Type': request.file.type}
        }).then((res: AxiosResponse) => {
            console.debug("Upload complete...");
        }).catch((err: AxiosError) => {
            console.error(err);
            throw new Error("An error occurred!")
        });
    },


}
