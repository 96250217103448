import React, {useContext, useState} from "react";
import {FormKingdomRequest, KingdomServiceContext} from "../../services/KingdomServiceContext";
import {KingdomAPI} from "../../App";
import {RealmDetail} from "../../services/APIServiceTypes";
import {Button, Form, Input, Space} from "antd";
import {useRecoilValue} from "recoil";
import {activeRealm} from "../../atoms";
import useAuth, {AuthDetail} from "../../hooks/useAuth";

function FormKingdomForm() {
    const authDetail: AuthDetail = useAuth();
    const realmDetail = useRecoilValue<RealmDetail>(activeRealm)
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)
    const [request, setRequest] = useState<FormKingdomRequest>({realmId: realmDetail.id, name: ""})
    const [loading, setLoading] = React.useState<boolean>(false);

    function submitForm() {
        setLoading(true)
        kingdomService.formKingdom(request, authDetail.authData)
            .then(r => setLoading(false));
    }

    return <Form>
        <Space direction={"vertical"}>
            <Input placeholder={"Kingdom Name"}
                   value={request.name}
                   onChange={(e) => setRequest({...request, name: e.target.value})}/>
            <Button loading={loading} onClick={submitForm}>Form Kingdom</Button>
        </Space>
    </Form>;
}

export default FormKingdomForm