import {WeaponRequest, WeaponResponse} from "../../../services/ReferenceServiceContext";
import {Avatar, Button, Modal, Space, Table} from "antd";
import WeaponForm from "./WeaponForm";
import React, {useState} from "react";
import {BiPlus} from "react-icons/bi";
import {MdEdit} from "react-icons/md";
import ResourcesBlock from "../../resources/ResourcesBlock";
import ImageUploader from "../../utility/ImageUploader";


const columns = [
    {title: 'Actions', dataIndex: 'actions', key: 'actions',},
    {title: 'Image', dataIndex: 'image', key: 'image',},
    {title: 'Name', dataIndex: 'name', key: 'name',},
    {title: 'Offense', dataIndex: 'offense', key: 'offense',},
    {title: 'Range', dataIndex: 'range', key: 'range',},
    {title: 'Costs', dataIndex: 'costs', key: 'costs',}
]

const emptyWeapon: WeaponRequest = {
    id: undefined,
    name: "",
    offense: "",
    range: 0,
    costs: [],
    requiredTech: []
};

function WeaponTable(props: { loading: boolean, weapons: WeaponResponse[] }) {
    const [selectedWeapon, setSelectedWeapon] = useState<WeaponRequest | undefined>(undefined);

    return <>
        <Button icon={<BiPlus/>} type={"primary"} onClick={() => setSelectedWeapon(emptyWeapon)}>New Weapon</Button>
        {selectedWeapon &&
            <Modal open={true}
                   footer={false}
                   onCancel={() => setSelectedWeapon(undefined)}
                   title={selectedWeapon.id ? "Edit " + selectedWeapon.name : "New Weapon"}
            >
                <WeaponForm onFinishCallback={() => setSelectedWeapon(undefined)} weapon={selectedWeapon}/>
            </Modal>}
        <Table loading={props.loading} columns={columns}
               dataSource={props.weapons
                   .map((w: WeaponResponse) => {
                       return {
                           ...w,
                           image: <Avatar shape={"square"}
                                          src={`${process.env.REACT_APP_ASSET_HOST}/assets/weapons/${w.id}.png`}/>,
                           actions:
                               <Space>
                                   <Button onClick={() => setSelectedWeapon(w)} size={"small"} icon={<MdEdit/>}/>
                                   <ImageUploader fileId={`assets/weapons/${w.id}.png`}/>
                               </Space>
                           ,
                           costs: <ResourcesBlock showZeroValues={false} resources={w.costs}/>
                       }
                   })}
        />
    </>;
}

export default WeaponTable;