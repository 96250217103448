import useAuth, {AuthDetail} from "../../hooks/useAuth";
import {useContext} from "react";
import {ReferenceServiceContext} from "../../services/ReferenceServiceContext";
import {ReferenceAPI} from "../../App";


function useReferenceServiceContext(): [AuthDetail, ReferenceServiceContext] {
    const authDetail: AuthDetail = useAuth();
    const refService = useContext<ReferenceServiceContext>(ReferenceAPI)

    return [authDetail, refService]
}
export default useReferenceServiceContext;