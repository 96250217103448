export const localStorageKeys =
    {
        DISPLAY_HELP: "displayHelp",
        REALM: "realm",
        PUSHER_TRANSPORT_TLS: "pusherTransportTLS",
        AUTH_DATA: "authData",
        REALM_ID: "realmId",
        LAYER_VISIBILITY: "layerVisibility",
    };

export default localStorageKeys;