import {RealmDetail} from "../services/APIServiceTypes";
import {FloatButton, Layout} from "antd";
import MapContent from "./MapContent";
import Sider from "antd/es/layout/Sider";
import React, {useState} from "react";
import RealmControlBoard from "./RealmControlBoard";
import {atom, RecoilState, useRecoilValue} from "recoil";
import {activeRealm, entityPositionsState} from "../atoms";
import {EntityPositionsResponse} from "../services/RealmServiceContext";
import {MdCastle} from "react-icons/md";


interface Props {
    contentHeight: number
}

export enum ControlState {
    IDLE = "IDLE", MANAGE_CITY = "MANAGE_CITY"
}

export interface ControlActivity {
    controlState: ControlState
    controlObjectId: string | null
}

export const currentControlActivity: RecoilState<ControlActivity> = atom<ControlActivity>({
    key: 'controlActivity',
    default: {controlState: ControlState.IDLE, controlObjectId: null}
})

function GameBoard({contentHeight}: Props) {
    const realmDetail = useRecoilValue<RealmDetail>(activeRealm);
    const positions = useRecoilValue<EntityPositionsResponse>(entityPositionsState)
    const [hideControlPanel, setHideControlPanel] = useState<boolean>(false);

    return (
        <Layout style={{maxHeight: contentHeight + "px"}}>
            {hideControlPanel && <FloatButton icon={<MdCastle/>} onClick={() => setHideControlPanel(false)}/>}
            {positions && realmDetail && <MapContent contentHeight={contentHeight}/>}
            <Sider collapsible={true}
                   collapsedWidth={0}
                   collapsed={hideControlPanel}
                   onCollapse={(collapsed, type) => setHideControlPanel(collapsed)}
                   width={contentHeight / 1.5} style={{maxHeight: contentHeight + "px", overflow: "auto"}}>
                {realmDetail && <RealmControlBoard onHide={() => setHideControlPanel(!hideControlPanel)}/>}
            </Sider>
        </Layout>
    )
}

export default GameBoard;
