import {Button, message, Upload, UploadFile} from "antd";
import React, {useEffect} from "react";
import {BiUpload} from "react-icons/bi";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import useReferenceServiceContext from "../reference-data/useReferenceServiceContext";


/**
 * This component is used to upload a png file to S3.
 * Use the fileId prop to specify the file name.
 * fileId should include forward slashes to represent the path.
 * @param props fileId: string
 */
function ImageUploader(props: { fileId: string, onComplete?: (fileId: string) => void }) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [authDetail, service] = useReferenceServiceContext();
    const [presignedPutUrl, setPresignedPutUrl] = React.useState<string | null>(null);
    const [file, setFile] = React.useState<File | null>(null);

    useEffect(() => {
        if (file !== null && presignedPutUrl !== null) {
            setLoading(true);
            service.uploadFile({file: file, presignedUrl: presignedPutUrl})
                .then(() => {
                    setLoading(false);
                    message.success("Upload complete!");
                    props.onComplete && props.onComplete(presignedPutUrl);
                    setPresignedPutUrl(null);
                })
        }
    }, [file, presignedPutUrl, service]);

    function fetchPresignedUploadUrlForFile(f: RcFile) {
        console.debug(f);
        setLoading(true);
        service.fetchPresignedPutUrl({contentType: f.type, fileName: props.fileId}, authDetail.authData)
            .then((r) => {
                setPresignedPutUrl(r.presignedUrl)
                setLoading(false)
            })
    }

    return <Upload showUploadList={false}
                   beforeUpload={(f: RcFile) => {
                       fetchPresignedUploadUrlForFile(f);
                   }}
                   onChange={(a: UploadChangeParam<UploadFile<any>>) => {
                       if (a.fileList.length > 1)
                           a.fileList.reverse().pop();
                   }}
                   customRequest={(options) => {
                       console.debug(options);
                       //@ts-ignore
                       setFile(options.file);
                   }}>
        <Button disabled={loading} icon={<BiUpload/>}>Upload Image</Button>
    </Upload>
}

export default ImageUploader;
/*
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';

const props: UploadProps = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.debug(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const App: React.FC = () => (
  <Upload {...props}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>
);

export default App;
 */