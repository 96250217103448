import {Avatar, Button, Divider, Drawer, Modal, Space} from "antd";
import RealmMenu from "./RealmMenu";
import React, {useContext, useEffect, useState} from "react";
import {AuthAPI} from "../App";
import {PlayerStatusResponse, RulersResponse} from "../services/RealmServiceContext";
import {useRecoilValue} from "recoil";
import {playerStatusState, rulersOfTheRealm} from "../atoms";
import ResourcesBlock from "./resources/ResourcesBlock"
import useAuth, {AuthDetail} from "../hooks/useAuth";
import {AuthServiceContext} from "../services/AuthServiceContext";
import ToggleHelpButton from "./ToggleHelpButton";
import LayersMenu from "./LayersMenu";
import {Documentation} from "./documentation/Documentation";
import QuickStartGuide from "./documentation/QuickStartGuide";
import EraStatus from "./era/EraStatus";
import LoginOrRegisterForm from "./LoginOrRegisterForm";
import RulerDetail from "./RulerDetail";
import MapConfiguration from "./map/MapConfiguration";
import NewRealmModal from "./NewRealmModal";
import FlagCreator from "./player/FlagCreator";
import ManageRefData from "./reference-data/ManageRefData";

function UserActionMenu() {
    const authDetail: AuthDetail = useAuth();
    const authContext = useContext<AuthServiceContext>(AuthAPI);
    const playerStatus = useRecoilValue<PlayerStatusResponse>(playerStatusState);
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const [applicationVersion, setApplicationVersion] = React.useState<string>("");
    const [UIVersion, setUIVersion] = React.useState<string>("");
    const [showAbout, setShowAbout] = React.useState<boolean>(!authDetail.sessionIsActive());
    const [showQuickStartGuide, setShowQuickStartGuide] = React.useState<boolean>(false);
    const [showMapConfiguration, setShowMapConfiguration] = React.useState<boolean>(false);
    const [manageRefData, setManageRefData] = React.useState<boolean>(false);
    const [newRealmModalOpen, setNewRealmModalOpen] = React.useState<boolean>(false);
    const [showFlagConfiguration, setShowFlagConfiguration] = React.useState<boolean>(false);
    const [inspectProfileOpen, setInspectProfileOpen] = useState<boolean>(false);
    const [loginOpen, setLoginOpen] = useState<boolean>(false);

    useEffect(() => {
        console.debug("Rendering UserActionMenu");
    }, [])

    useEffect(() => {
        authContext.fetchApplicationVersion().then(response => {
            setApplicationVersion(response.applicationVersion)
            setUIVersion(response.uiVersion)
        })
    }, [authContext])

    useEffect(() => {
        if (!authDetail.sessionIsActive())
            setLoginOpen(!showAbout);
        else
            setLoginOpen(false);
    }, [showAbout, authDetail]);

    return (
        <>
            <Space style={{marginBottom: "0.1rem", marginTop: "0.1rem", marginLeft: "1rem"}}>
                <Avatar
                    style={{cursor: "pointer"}}
                    size={"small"}
                    onClick={() => setInspectProfileOpen(true)}
                    src={authDetail.sessionIsActive() &&
                        <img
                            src={`${process.env.REACT_APP_ASSET_HOST}/assets/flags/` + authDetail?.authData?.flagId + ".png"}
                            alt={"Flag"}/>}/>
                <Button size={"small"} onClick={() => setShowFlagConfiguration(true)}>
                    Flag
                </Button>
                <RealmMenu/>
                {authDetail.isAdmin() &&
                    <>
                        <Button style={{cursor: "pointer"}} size={"small"} type={"primary"}
                                onClick={() => setNewRealmModalOpen(true)}>New Realm</Button>
                        <Button size={"small"} type={"primary"} onClick={() => setShowMapConfiguration(true)}>
                            Configure Map
                        </Button>
                        <Button size={"small"} type={"primary"} onClick={() => setManageRefData(true)}>
                            Manage Ref Data
                        </Button>
                    </>
                }
                <Divider type={"vertical"}/>
                <Button size={"small"} type={"default"} onClick={() => setShowAbout(!showAbout)}>About</Button>
                <Button size={"small"} type={"default"}
                        onClick={() => setShowQuickStartGuide(!showQuickStartGuide)}>Guide</Button>
                <ToggleHelpButton/>
                <LayersMenu/>
                {playerStatus?.resources &&
                    <><Divider type={"vertical"}/>
                        <ResourcesBlock showZeroValues={true} resources={playerStatus.resources}/>
                    </>
                }
                <Divider type={"vertical"}/>
                <EraStatus/>
                <Modal open={showAbout}
                       title={"MithTerra"}
                       onOk={() => setShowAbout(false)}
                       onCancel={() => setShowAbout(false)}>
                    <div>Application Version: {applicationVersion}</div>
                    <div>Client Version: {UIVersion}</div>
                    <Documentation/>
                </Modal>
                <Modal destroyOnClose={true}
                       open={newRealmModalOpen}
                       footer={null}
                       onCancel={() => setNewRealmModalOpen(false)}>
                    <NewRealmModal onOk={() => setNewRealmModalOpen(false)}/>
                </Modal>
                <Modal open={showFlagConfiguration}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => setShowFlagConfiguration(false)}>
                    <FlagCreator onComplete={() => setShowFlagConfiguration(false)}/>
                </Modal>
                <Modal destroyOnClose={true}
                       width={600}
                       open={loginOpen}
                       footer={null}>
                    <LoginOrRegisterForm/>
                </Modal>
                <Modal
                    open={inspectProfileOpen}
                    onCancel={() => setInspectProfileOpen(false)}
                    onOk={() => setInspectProfileOpen(false)}
                >
                    <RulerDetail/>
                </Modal>

                <Drawer open={showMapConfiguration}
                        height={"100%"}
                        width={"100%"}
                        mask={true}
                        placement={"top"}
                        onClose={() => setShowMapConfiguration(false)}
                        destroyOnClose={true}
                >
                    <MapConfiguration/>
                </Drawer>
                <Drawer open={manageRefData}
                        mask={true}
                        height={"100%"}
                        width={"100%"}
                        placement={"top"}
                        onClose={() => setManageRefData(false)}
                        destroyOnClose={true}
                >
                    <ManageRefData/>
                </Drawer>
                <Drawer open={showQuickStartGuide}
                        mask={false}
                        zIndex={0}
                        size={"large"}
                        placement={"left"}
                        title={"Quick Start Guide"}
                        onClose={() => setShowQuickStartGuide(false)}>
                    <QuickStartGuide/>
                </Drawer>

            </Space>
        </>
    )
}

export default UserActionMenu;