import React from 'react';
import {AuthServiceContext} from "./services/AuthServiceContext";
import authServiceContext from "./services/impl/AuthServiceContextImpl";
import {RealmServiceContext} from "./services/RealmServiceContext";
import realmServiceContext from "./services/impl/RealmServiceContextImpl";
import {MapServiceContext} from "./services/MapServiceContext";
import mapServiceContextImpl from "./services/impl/MapServiceContextImpl";
import {KingdomServiceContext} from "./services/KingdomServiceContext";
import {kingdomServiceContext} from "./services/impl/KingdomServiceImpl";
import {IconContext} from "react-icons";
import ContentWindow from "./ContentWindow";
import {RecoilRoot} from "recoil";
import {ReferenceServiceContext} from "./services/ReferenceServiceContext";
import {referenceServiceContext} from "./services/impl/ReferenceServiceContextImpl";


export const AuthAPI = React.createContext<AuthServiceContext>(authServiceContext)
export const RealmAPI = React.createContext<RealmServiceContext>(realmServiceContext)
export const ReferenceAPI = React.createContext<ReferenceServiceContext>(referenceServiceContext)
export const MapAPI = React.createContext<MapServiceContext>(mapServiceContextImpl);
export const KingdomAPI = React.createContext<KingdomServiceContext>(kingdomServiceContext)

function App() {

    return (
        <><IconContext.Provider value={{style: {verticalAlign: 'middle'}}}>
            <ReferenceAPI.Provider value={referenceServiceContext}>
                <KingdomAPI.Provider value={kingdomServiceContext}>
                    <MapAPI.Provider value={mapServiceContextImpl}>
                        <RealmAPI.Provider value={realmServiceContext}>
                            <AuthAPI.Provider value={authServiceContext}>
                                <RecoilRoot>

                                    <ContentWindow renderCount={1}/>
                                </RecoilRoot>
                            </AuthAPI.Provider>
                        </RealmAPI.Provider>
                    </MapAPI.Provider>
                </KingdomAPI.Provider>
            </ReferenceAPI.Provider>
        </IconContext.Provider>
        </>
    );
}

export default App;
