import React from "react";
import {ColonyDetailResponse, ColonyDTOType} from "../../services/RealmServiceContext";
import ColonyIcon from "./ColonyIcon";
import {useRecoilValue, useSetRecoilState} from "recoil";
import useRealmServiceContext from "../../useRealmServiceContext";
import {activeRealm, colonyState} from "../../atoms";
import {RealmDetail} from "../../services/APIServiceTypes";

interface ColonyNameProps {
    colony: ColonyDTOType
}

const ColonyName = (props: ColonyNameProps) => {
    const [authDetail, realmService] = useRealmServiceContext();
    const realm = useRecoilValue<RealmDetail>(activeRealm);
    const setColony = useSetRecoilState<ColonyDetailResponse>(colonyState);

    function handleSelectedColony(colonyId: string) {
        realmService.colonyDetail({colonyId: colonyId, eraId: realm.activeEraId}, authDetail.authData)
            .then((c) => {
                setColony(c);
            })
    }

    return <div onClick={() => handleSelectedColony(props.colony.id)}>
        <ColonyIcon variant={"dark"} colony={props.colony}/>
    </div>

}
export default ColonyName