import {ColonyDetailResponse} from "../../../services/RealmServiceContext";
import {Col, Row, Space, Statistic} from "antd";
import {GiMedievalBarracks} from "react-icons/gi";
import {MdPeopleAlt} from "react-icons/md";
import ToggleHelpButton from "../../ToggleHelpButton";
import HelpText from "../../HelpText";
import numeral from "numeral";
import React from "react";

function TrainingCapabilities(props: { colony: ColonyDetailResponse }) {

    return <><Row>
        <Col span={6}><Statistic title={<Space><GiMedievalBarracks/>{"Barracks"}</Space>}
                                 value={props.colony.detail.buildings.barracks}/></Col>
        <Col span={6}><Statistic title={<Space><MdPeopleAlt/>{"Population"}</Space>}
                                 value={props.colony.detail.population}/></Col>
        <Col><ToggleHelpButton/></Col>
    </Row>
        <HelpText
            message={"We can train up to " + numeral(props.colony.detail.population).format() + " peasants into Military Units. Peasants must also work in economic production buildings in order to harvest resources."}/>
    </>
}

export default TrainingCapabilities;