import {
    ApplicationVersionResponse,
    AuthServiceContext,
    ConfirmEmailRequest,
    LoginRequest,
    LoginResponse,
    RegistrationRequest,
    RegistrationResponse
} from "../AuthServiceContext";
import axios, {AxiosError, AxiosResponse} from "axios";
import {handleError} from "../APIResponseHandler";
import {ErrorResponse} from "../RealmServiceContext";
import {AuthData, authHeaderFrom} from "../APIServiceTypes";


const authServiceContext: AuthServiceContext = {

    fetchApplicationVersion(): Promise<ApplicationVersionResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/meta/version`)
            .then((response: AxiosResponse<ApplicationVersionResponse>) => {
                return response.data
            })
    },

    confirmEmail(request: ConfirmEmailRequest): Promise<string> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/confirm-email/${request.userId}/${request.token}`)
            .then((response: AxiosResponse<string>) => {
                return response.data;
            }).catch((error: AxiosError) => {
                console.error(error)
                throw error
            });
    },


    login(request: LoginRequest): Promise<LoginResponse> {

        return axios.post(`${process.env.REACT_APP_API_HOST}/login`,
            request
        ).then((response: AxiosResponse<LoginResponse>) => {
            return response.data;
        }).catch((err: AxiosError<ErrorResponse>) => {
            handleError(err.response?.data);
            throw new Error();
        });
    }


    , register(request: RegistrationRequest): Promise<RegistrationResponse> {
        return axios.post(`${process.env.REACT_APP_API_HOST}/register`,
            request
        ).then((response: AxiosResponse<RegistrationResponse>) => {
            return {errorMessages: []}
        }).catch((err: AxiosError<ErrorResponse>) => {
            handleError(err.response?.data);
            throw new Error();
        })
    },

    refreshAuthData(authData: AuthData): Promise<LoginResponse> {
        return axios.get(`${process.env.REACT_APP_API_HOST}/refresh-auth-data`, authHeaderFrom(authData))
            .then((response: AxiosResponse<LoginResponse>) => {
                return response.data;
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                handleError(err.response?.data);
                throw new Error();
            })
    }

}

export default authServiceContext