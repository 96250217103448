import {Alert} from "antd";
import React from "react";
import {useRecoilValue} from "recoil";
import {helpEnabled} from "../atoms";

interface HelpTextProps {
    message: string | React.ReactNode
}

const HelpText = (props: HelpTextProps) => {
    const displayHelp = useRecoilValue<boolean>(helpEnabled)
    const {message} = props

    return displayHelp ? <Alert message={message} type="info" showIcon/> : <></>;
}
export default HelpText