import React from "react";
import {RealmServiceContext} from "./services/RealmServiceContext";
import {RealmAPI} from "./App";
import useAuth, {AuthDetail} from "./hooks/useAuth";

function useRealmServiceContext(): [AuthDetail, RealmServiceContext] {
    const authDetail: AuthDetail = useAuth();
    const realmService = React.useContext<RealmServiceContext>(RealmAPI)
    return [authDetail, realmService]
}

export default useRealmServiceContext