import {Button, Col, Divider, InputNumber, Row, Slider, Space} from "antd";
import React, {useEffect} from "react";
import {TransferQuantity} from "../../services/RealmServiceContext";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {AiFillFastForward} from "react-icons/ai";


interface MilitaryUnitTransferSliderProps {
    available: number
    transferQuantity: TransferQuantity
    setTransferQuantity: (transferQuantity: TransferQuantity) => void
}

const MilitaryUnitTransferSlider = (props: MilitaryUnitTransferSliderProps) => {
    const [available, setAvailable] = React.useState<number>(props.available)
    const [staged, setStaged] = React.useState<number>(0)

    useEffect(() => {
        props.setTransferQuantity({
            unitTypeId: props.transferQuantity.unitTypeId,
            quantity: staged > props.available ? props.available : staged
        })
    }, [staged]);

    useEffect(() => {
        setAvailable(props.available - staged)
    }, [staged]);

    return <>

        <Row align={"bottom"}>
            <Divider type={"horizontal"}
                     orientation={"left"}><b>{props.transferQuantity.unit?.name || props.transferQuantity.unitTypeId.unitType}</b></Divider>
            <Col span={16}>
                <FormItemLabel prefixCls={""} label={"Available: " + (available)}/>
                <Slider min={0}
                        max={props.available}
                        value={staged}
                        onChange={(v) => setStaged(v)}
                />
            </Col>
            <Col span={1}></Col>
            <Col span={2}>
                <Space>
                    <Button
                        onClick={() => setStaged(available)}
                        icon={<AiFillFastForward size={"23"}/>}
                        type={"dashed"}
                        size={"middle"}
                    />
                </Space>
            </Col>
            <Col span={4}>
                <FormItemLabel prefixCls={""} label={"Staged for Transfer"}/>
                <InputNumber min={0}
                             type={"number"}
                             value={staged}
                             onChange={(v) => setStaged(v || 0)}/>
            </Col>
        </Row>
    </>
}

export default MilitaryUnitTransferSlider