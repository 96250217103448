import {Tile} from "./MapServiceContext";
import {EraTurnState, ErrorResponse, PlayerStatusResponse} from "./RealmServiceContext";
import {WebsocketCredentials} from "./AuthServiceContext";

export interface ErrorReportRequest {
    descriptionOfTheProblem: string;
    attemptedAction?: string;
    errorMessage?: string;
    errorResponseFromSubmission?: ErrorResponse;
}

export interface APIResponse { // todo stop extending this DO NOT USE, This is an ERRORResponse, refactor to use it appropriately
    failureReason?: string
    errors?: string[]
    causes?: string[]
    errorMessages: string[]
}

export interface AuthorizedAPIRequest {
    authData: AuthData
}

export enum MapGenerationStatus {
    PENDING = "PENDING", COMPLETE = "COMPLETE"
}

export interface RealmHeader {
    id: string;
    name: string;
}

export interface AuthData {
    webSocketCredentials: WebsocketCredentials
    userId: string;
    authToken: string;
    email: string;
    username: string;
    flagId: string | undefined;
    expiration: number;
}

export interface RealmMap {
    map: number[][]
    tileSize: number
    tiles: Record<number, Tile>
}

export interface RealmDetail extends RealmHeader {
    playerStatus: PlayerStatusResponse;
    owner: string;
    activeEraId: string;
    eraCount: number;
    turn: number;
    users: string[];
    mapGenerationStatus: MapGenerationStatus;
    mapUrl: string
    minimapUrl: string
    realmMap: RealmMap
    eraTurnState: EraTurnState
}

export function authHeader(request: AuthorizedAPIRequest) {
    return {headers: {'Authorization': `Bearer ${request.authData.authToken}`}};
}

export function authHeaderFrom(authData: AuthData) {
    return {headers: {'Authorization': `Bearer ${authData.authToken}`}};
}