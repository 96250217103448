import {KingdomDTOResponse} from "../../services/KingdomServiceContext";
import React, {useState} from "react";
import {Button, Divider, List} from "antd";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";
import RulerName from "../player/RulerName";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import KingdomMember from "./KingdomMember";
import KingdomOfficer from "./KingdomOfficer";

export interface Props {
    kingdom: KingdomDTOResponse

    joinKingdomCallback(kingdom: KingdomDTOResponse): void
}
function KingdomDetailPanel({kingdom, joinKingdomCallback}: Props) {
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const [kingdomDetail, setKingdomDetail] = useState<KingdomDTOResponse>(kingdom);

    function joinKingdom() {
        joinKingdomCallback(kingdomDetail);
    }

    function kingdomChanged(newKingdom: KingdomDTOResponse) {
        setKingdomDetail(newKingdom);
    }

    return (<>
        { !(rulers[authDetail.authData.userId].kingdomId) && <Button onClick={joinKingdom}>Request to Join</Button>}
        <Divider orientation={"left"}>Leader</Divider>
        <RulerName ruler={rulers[kingdom.leaderId]}/>
        <Divider orientation={"left"}>Officers</Divider>
        <List
            size="small"
            bordered
            dataSource={kingdomDetail.officerIds}
            renderItem={(oId) => <List.Item><KingdomOfficer onChange={kingdomChanged} kingdom={kingdomDetail}
                                                            memberId={oId}/></List.Item>}
        />
        <Divider orientation={"left"}>Members</Divider>
        <List
            size="small"
            bordered
            dataSource={kingdomDetail.memberIds}
            renderItem={(mId) => <List.Item><KingdomMember onChange={kingdomChanged} kingdom={kingdomDetail}
                                                           memberId={mId}/></List.Item>}
        />
    </>)
}

export default KingdomDetailPanel