import {Coordinate} from "../../../../services/RealmServiceContext";
import {Circle} from "react-konva";
import React from "react";


interface VisibleAreaProps {
    coordinates: Coordinate
    radius: number
}

const VisibleArea = (props: VisibleAreaProps) => {
    const {coordinates, radius} = props

    return <Circle
        x={coordinates.x}
        y={coordinates.y}
        radius={radius}
        fill={'black'}
        strokeEnabled={false}
        shadowBlur={100}
        globalCompositeOperation={'destination-out'}
    />
}
export default VisibleArea