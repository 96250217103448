import {ArmorRequest} from "../../../services/ReferenceServiceContext";
import DebugData from "../../DebugData";
import {useState} from "react";
import {Divider, Form, Space} from "antd";
import FreeformShortTextField from "../form-components/FreeformShortTextField";
import DiceSelector from "../form-components/DiceSelector";
import CostForm from "../form-components/CostForm";
import SaveButton from "../form-components/SaveButton";
import DeleteButton from "../form-components/DeleteButton";
import useReferenceServiceContext from "../useReferenceServiceContext";


function ArmorForm(props: { armor: ArmorRequest, onFinishCallback: () => void }) {
    const [authDetail, refService] = useReferenceServiceContext();
    const [armorRequest, setArmorRequest] = useState<ArmorRequest>({...props.armor});

    function save() {
        console.debug("saving armor: ", armorRequest)
        refService.defineArmor(armorRequest, authDetail.authData)
            .then(() => {
                props.onFinishCallback();
            }).catch(() => {
            console.error("error saving armor")
        })
    }

    function remove() {
        console.debug("removing armor: ", armorRequest)
        if(armorRequest.id) {
            refService.deleteArmor(armorRequest.id, authDetail.authData)
            props.onFinishCallback();
        }
    }

    return <>
        <Form>
            <Divider orientation={"left"}>Properties</Divider>
            <FreeformShortTextField label={"Name"} value={armorRequest.name}
                                    onChange={(v) => setArmorRequest({...armorRequest, name: v})}/>
            <DiceSelector label={"Defense"} value={armorRequest.defense}
                          onChange={(v) => setArmorRequest({...armorRequest, defense: v})}/>
            <CostForm costs={armorRequest.costs}
                      setCosts={(costs) => setArmorRequest({...armorRequest, costs: costs})}/>
            <Space>
                <SaveButton save={save}/>
                {armorRequest.id && <DeleteButton name={armorRequest.name} onConfirm={remove}/>}
            </Space>
        </Form>
        <DebugData info={armorRequest}/>
    </>;

}

export default ArmorForm;