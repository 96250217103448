import {Dropdown} from "antd";
import React, {useEffect} from "react";
import {RealmHeader} from "../services/APIServiceTypes";
import {Button} from "antd/lib";
import useAuth, {AuthDetail} from "../hooks/useAuth";
import {RealmAPI} from "../App";
import {EntityPositionsResponse, RealmServiceContext} from "../services/RealmServiceContext";
import {useRecoilState, useSetRecoilState} from "recoil";
import {entityPositionsState, realmListState, selectedRealmIdState} from "../atoms";
import localStorageKeys from "../LocalStorageKeys";
import {BiChevronDown} from "react-icons/bi";

function RealmMenu() {
    const authDetail: AuthDetail = useAuth();
    const realmContext = React.useContext<RealmServiceContext>(RealmAPI);
    const [realmList, setRealmList] = useRecoilState<RealmHeader[]>(realmListState);
    const [selectedRealmId, setSelectedRealmId] = useRecoilState<string>(selectedRealmIdState)
    const setEntityPositions = useSetRecoilState<EntityPositionsResponse>(entityPositionsState)
    useEffect(() => {
        console.debug("Rendering RealmMenu, currently selected realmId is: " + selectedRealmId);
    }, [selectedRealmId])

    useEffect(() => {
        if (authDetail.sessionIsActive()) {
            console.debug("fetching realm list");
            realmContext
                .listRealms({authData: authDetail.authData})
                .then(realms => setRealmList(realms));
        }
    }, [authDetail.authData, realmContext]);

    function handleSelectedRealm(id: string) {
        localStorage.setItem(localStorageKeys.REALM_ID, id);
        setSelectedRealmId(id);
        setEntityPositions({mobs: [], colonies: [], errorMessages: []});
    }

    return <>
        <Dropdown
            menu={{
                theme: "dark",
                items: realmList.map(r => {
                    return {label: r.name, key: r.id, onClick: () => handleSelectedRealm(r.id)}
                })
            }}>
            <Button style={{cursor: "pointer"}} icon={<BiChevronDown/>} size={"small"} type={"primary"}>Realms</Button>
        </Dropdown>
    </>

}

export default RealmMenu;