import {Remark} from "react-remark";
import React from "react";

export function Documentation() {

    // todo fetch the documentation from the backend and display it here

    return <><Remark>{
        `
# About the Game

A turn-based strategy game set in a medieval world. Players take on the role of lords and compete with each other for dominance by building armies, expanding their territory, and managing their resources.

## Gameplay
A game of strategy, resource gathering, exploration, and war.
The object of the game is to defeat your opponents.
Players start the game with a single army. 
They must build up a colony, gather resources, and recruit new units to expand their army. 
As they progress through the game, they can research new technologies, build advanced structures, and train more powerful units.

## Features

- *Perpetual gameplay*: The clock is always ticking with tick-based turns every hour.
- *Army Maneuvering*: Fielded Armies maneuver across the map fight your enemies on the field of battle!
- *Resource gathering and management*: A strong Economy means a strong Military, dominate your enemies.
- *Colony building and Expansion*: Expand your territory by settling more colonies on buildable terrain (plains) anywhere on the map
- *Kingdoms and alliances*: Join forces with your friends, share vision, post armies in each other's colonies for defenses, focus on different research to balance the kingdom.
- *Technology research and advancement*: Coming soon...
- *Magic*: Coming soon.

## Eras

The game is played in what's known as an Era. Each Era lasts until a clear winner is determined. During the Era players must try to accumulate as much power as possible before the Era ends.

## Pace

The game is paced slowly, with each turn taking 1 hour. Larger maps take several (real) days to traverse using large armies. 

## Contact the Developer
Coming soon...
        

## Resources
### Ore
Gathered in mines. Create weapons and armor for military units.
### Gold
Gathered in mines. Pay military units, and peasants for the construction of buildings.
### Stone
Gathered in mines. Used to construct walls and buildings.
### Wood
Gathered in Lumbermills. Construct buildings and war machines
### Food
Gathered in Farms. Consumed by the population of your kingdom.

## Units
### Peasants
They represent the general population of colonies, their growth rate depends on several factors, including how much work there is in the colony, 
how abundant the food supply is, if they feel safe inside the city and more!
### Scouts
The first type of Unit available. They have a fast movement rate considering they are not mounted. They add defense bonuses to armies
if the army remains stationary in the field.  

## Technology
Coming Soon...

## Gameplay
___
### First Era
On your first Era of play, focus on learning the basics
- How to move your armies
- How to settle colonies
- How to queue buildings in the colonies
- How to queue military units in the colonies
- How to evaluate your economy and make decisions about what kind of buildings to build next etc...
- How to transfer units from colonies to armies and back again


## Army Movement
___
Armies move on the map at their movement rate (indicated in the Army Context Menu). 
They are only as fast as their slowest units, so an army of all knights with 1 ballista will be only as fast as the ballista.
- To move an army \`left-click\` on their icon on the map and continue to \`left-click\` waypoints to set their route. Once you are satisfied with the destination, \`double-click\`.

## Credits and Attributions
---
### Artwork
Some of the artwork in MithTerra is attributed to:
`
    }
    </Remark>
        <li><a href="https://opengameart.org/users/beast" target="_blank">Beast</a></li>
        <li><a href="https://opengameart.org/content/armor-icons-by-equipment-slot" target="_blank">
            Clint Bellanger, Blarumyrran, crowline (CrowLineStudio), Justin Nichol
        </a></li>
    </>
}