import {useRecoilValue} from "recoil";
import {AuthData} from "../services/APIServiceTypes";
import {authDataState} from "../atoms";
import {ADMIN_EMAIL} from "../utils/Toolbox";
import {useEffect} from "react";
import LocalStorageKeys from "../LocalStorageKeys";


export class AuthDetail {
    authData: AuthData

    constructor(authData: AuthData) {
        this.authData = authData;
    }

    public sessionIsActive(): boolean {
        console.debug("Checking is session is active...")
        return !this.isExpired();
    }

    private isExpired(): boolean {
        const now = new Date().getTime()
        console.debug("session data: ", this.authData);
        console.debug("Current timestamp: ", now);
        const isExpired = (this.authData.expiration || now - 1) < now;
        console.debug("Session is expired? " + isExpired);
        return isExpired;
    }

    public isAdmin(): boolean {
        return this.authData.email === ADMIN_EMAIL
    }
}

const useAuth = (): AuthDetail => {
    const authData = useRecoilValue<AuthData | undefined>(authDataState);

    useEffect(() => {
        if (authData) {
            localStorage.setItem(LocalStorageKeys.AUTH_DATA, JSON.stringify(authData));
        } else {
            localStorage.setItem(LocalStorageKeys.AUTH_DATA, JSON.stringify(null));
        }
    }, [authData])

    return new AuthDetail(authData || {
        authToken: "",
        email: "",
        expiration: Date.now() - 1,
        username: "",
        flagId: undefined,
        webSocketCredentials: {key: "", clusterDetail: {cluster: ""}},
        userId: ""
    });

}


export default useAuth