import RulerName from "../player/RulerName";
import React, {useContext} from "react";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";
import {Button, Popconfirm, Space} from "antd";
import {KingdomDTOResponse, KingdomServiceContext, Rank} from "../../services/KingdomServiceContext";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import BirdIcon from "../utility/BirdIcon";
import {KingdomAPI} from "../../App";

const KingdomOfficer = (props: {
    kingdom: KingdomDTOResponse,
    memberId: string,
    onChange: (newKingdom: KingdomDTOResponse) => void
}) => {
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)

    function demote() {
        kingdomService.demote({
            kingdomId: props.kingdom.id,
            userId: props.memberId,
            rank: Rank.MEMBER
        }, authDetail.authData)
            .then(res => {
                props.onChange(res);
            })
    }

    return <>
        <RulerName ruler={rulers[props.memberId]}/>
        <Space>
            {props.kingdom.leaderId === authDetail.authData.userId
                && <Popconfirm title={"Demote " + rulers[props.memberId].name + "?"}
                               icon={<BirdIcon/>}
                               cancelText={"No you fool!"}
                               okText={"Do it! " + rulers[props.memberId].name + " does not deserve the title!"}
                               onConfirm={demote}>
                    <Button danger={true}>Demote</Button>
                </Popconfirm>}
        </Space>
    </>

}
export default KingdomOfficer