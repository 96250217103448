import {KingdomDTOResponse, MemberApplication} from "../../services/KingdomServiceContext";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import React, {useState} from "react";
import RulerName from "../player/RulerName";
import {rulersOfTheRealm} from "../../atoms";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {Divider, Modal, Table} from "antd";
import PendingApplication from "./PendingApplication";

function kingdomOfPlayer(playerId: string, kingdoms: KingdomDTOResponse[]): KingdomDTOResponse | undefined {
    // todo this is probably inefficient - slow - improve this performance if needed
    return kingdoms.find((k) => Array.of(k.memberIds, k.officerIds, [k.leaderId]).flatMap(i => i).some((id) => id === playerId))
}


const PendingApplicationsTable = (props: { kingdoms: KingdomDTOResponse[] }) => {
    const authDetail: AuthDetail = useAuth();
    const playersKingdom = kingdomOfPlayer(authDetail.authData.userId, props.kingdoms)
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const [selectedApplication, setSelectedApplication] = useState<MemberApplication | undefined>(undefined)

    const columns = [
        {
            title: "Ruler",
            render: (application: MemberApplication) => <div style={{cursor: "pointer"}}><RulerName
                ruler={rulers[application.userId]}/></div>
        }
    ]

    function openApplication(memberApplication: MemberApplication) {
        setSelectedApplication(memberApplication);
    }

    return <>
        {playersKingdom && playersKingdom.applications.filter(application => application.status === "PENDING").length > 0
            ? <>
                <Divider orientation={"left"}>Pending Applications</Divider>
                <Table
                    size={"small"}
                    columns={columns}
                    dataSource={playersKingdom?.applications.filter(application => application.status === "PENDING")}
                    onRow={(r, i) => {
                        return {onClick: (event) => openApplication(r)}
                    }}/>
            </>
            : <></>
        }
        {selectedApplication &&
            <Modal open={true}
                   title={"Pending Kingdom Application"}
                   footer={false}
                   onCancel={() => setSelectedApplication(undefined)}
            >
                <PendingApplication application={selectedApplication}
                                    onFinished={() => setSelectedApplication(undefined)}/>
            </Modal>
        }
    </>
}

export default PendingApplicationsTable