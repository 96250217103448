import {Arrow} from "react-konva";
import React, {useEffect} from "react";
import {Coordinate, MobileEntity} from "../../services/RealmServiceContext";
import {selectedMobileEntityState} from "../../atoms";
import {useRecoilValue} from "recoil";

function findCoordinate(A: Coordinate, deg: number): Coordinate {
    const rad = deg * (Math.PI / 180);
    return new Coordinate(
        A.x + 24 * Math.cos(rad),
        A.y - 24 * Math.sin(rad) // the y coordinate on this map is pixel coordinate, thus the sign must be flipped
    );
}

function findDirectionalArrowPoints(heading: number): number[] {
    const movementArrowEndCoordinate = findCoordinate(new Coordinate(0, 0), heading);
    return [0, 0, movementArrowEndCoordinate.x, movementArrowEndCoordinate.y];
}


const DirectionalArrow = (props: { mob: MobileEntity }) => {
    const [directionalArrowPoints, setDirectionalArrowPoints] = React.useState<number[]>([])
    const selectedMob = useRecoilValue<MobileEntity>(selectedMobileEntityState)

    useEffect(() => {
        setDirectionalArrowPoints(findDirectionalArrowPoints(props.mob.header.vector.velocity.heading))
    }, [props.mob.header.vector.velocity.heading])

    return <Arrow stroke={"black"}
                  visible={selectedMob !== props.mob}
                  pointerWidth={6}
                  pointerLength={6}
                  strokeWidth={2}
                  points={directionalArrowPoints}
                  x={props.mob.header.vector.position.x}
                  y={props.mob.header.vector.position.y}/>
}

export default DirectionalArrow;