import React, {useEffect} from "react";
import {Button, Space} from "antd";
import {useRecoilState} from "recoil";
import {helpEnabled} from "../atoms";
import {GiHelp} from "react-icons/gi";
import LocalStorageKeys from "../LocalStorageKeys";

const ToggleHelpButton = () => {
    const [displayHelp, setDisplayHelp] = useRecoilState<boolean>(helpEnabled)

    useEffect(() => {
        localStorage.setItem(LocalStorageKeys.DISPLAY_HELP, displayHelp ? "1" : "0");
    }, [displayHelp])

    return (
        <Button size={"small"} type={"dashed"} title={"Help"} onClick={() => setDisplayHelp(!displayHelp)}>
            <Space>
                <GiHelp/>{displayHelp ? "Hide Help" : "Show Help"}
            </Space>
        </Button>)

}

export default ToggleHelpButton