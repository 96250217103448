import {Coordinate} from "../../../services/RealmServiceContext";
import React from "react";
import {useRecoilValue} from "recoil";
import {GlobalLayers, GlobalLayersState} from "../../../atoms";
import DebugData from "../../DebugData";
import {Group, Layer} from "react-konva";
import {Html} from "react-konva-utils";

const DebugLayer = (props: { mousePosition: Coordinate }) => {
    const globalLayers = useRecoilValue<GlobalLayers>(GlobalLayersState)


    return <Layer visible={globalLayers.debugLayer}>
        <Group id={"debug-layer"} x={props.mousePosition.x + 40} y={props.mousePosition.y + 40}>
            <Html>
                <DebugData info={props}/>
            </Html>
        </Group>
    </Layer>


}

export default DebugLayer