import {Divider, List, Skeleton, Tag} from "antd";
import React from "react";
import {ArmyDetailResponse, ArmyHeader, ColonyHeader, RulersResponse} from "../../services/RealmServiceContext";
import {useRecoilValue} from "recoil";
import {rulersOfTheRealm} from "../../atoms";
import MobIcon from "./MobIcon";
import ArmyUnitDetail from "./ArmyUnitDetail";
import ColonyIcon from "../colony/ColonyIcon";
import RulerName from "../player/RulerName";
import useAuth, {AuthDetail} from "../../hooks/useAuth";

interface detailProps {
    detail: ArmyDetailResponse | undefined,
    setSelectedArmy: (armyHeader: ArmyHeader | undefined) => void
    setSelectedColony: (colonyHeader: ColonyHeader | undefined) => void
}

const ArmyDetail = ({detail, setSelectedArmy, setSelectedColony}: detailProps) => {
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)

    function handleArmyClick(armyHeader: ArmyHeader) {
        console.debug(armyHeader)
        setSelectedArmy(armyHeader)
    }

    function handleColonyClick(c: ColonyHeader) {
        console.debug("clicked on colony: ", c);
        setSelectedColony(c);
    }
    function kingdomOfRuler(rulerId: string): string {
        return rulers[rulerId].kingdomId || rulerId;
    }

    return !detail
        ? <Skeleton active/>
        : <>
            <ArmyUnitDetail armyDetail={detail}/>
            <Divider>Armies within Range</Divider>
            <List
                size="small"
                dataSource={detail.armiesWithinRange.filter(a => kingdomOfRuler(a.ownerId) !== kingdomOfRuler(authDetail.authData.userId))}
                renderItem={(a: ArmyHeader) => <List.Item>
                    <List.Item.Meta key={a.id}
                                    style={{cursor: "pointer"}}
                                    title={<a href={""} onClick={(e) => {
                                        e.preventDefault();
                                        handleArmyClick(a);
                                    }}><Tag><RulerName ruler={rulers[a.ownerId]}/><MobIcon mob={a}/></Tag></a>}
                    />
                </List.Item>

                }
            />
            <Divider>Colonies within Range</Divider>
            <List
                size="small"
                dataSource={detail.coloniesWithinRange.filter(c => kingdomOfRuler(c.ownerId) !== kingdomOfRuler(authDetail.authData.userId))}
                renderItem={(c: ColonyHeader) =>
                    <List.Item>
                        <List.Item.Meta key={c.id}
                                        style={{cursor: "pointer"}}
                                        title={<a href={""} onClick={(e) => {
                                            e.preventDefault();
                                            handleColonyClick(c)
                                        }}><ColonyIcon colony={c} variant={"dark"}/></a>}
                        >

                        </List.Item.Meta>

                    </List.Item>
                }
            />
        </>

}
export default ArmyDetail