import {useEffect, useState} from "react";
import {MilitaryUnitResponse} from "../../../services/ReferenceServiceContext";
import UnitTable from "./UnitTable";
import useReferenceServiceContext from "../useReferenceServiceContext";

function ManageUnits() {
    const [authDetail, refService] = useReferenceServiceContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [units, setUnits] = useState<MilitaryUnitResponse[]>([]);

    useEffect(() => {
        setLoading(true);

        refService.listUnits(authDetail.authData)
            .then((response) => {
                setUnits(response);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            })

    }, []);

    return <>
        <UnitTable units={units} loading={loading}/>
    </>

}

export default ManageUnits;