import RulerName from "../player/RulerName";
import React, {useContext} from "react";
import {useRecoilValue} from "recoil";
import {RulersResponse} from "../../services/RealmServiceContext";
import {rulersOfTheRealm} from "../../atoms";
import {Button, Popconfirm, Space} from "antd";
import {KingdomDTOResponse, KingdomServiceContext, Rank} from "../../services/KingdomServiceContext";
import useAuth, {AuthDetail} from "../../hooks/useAuth";
import BirdIcon from "../utility/BirdIcon";
import {KingdomAPI} from "../../App";
import {weSentABird} from "../../services/APIResponseHandler";

const KingdomMember = (props: {
    kingdom: KingdomDTOResponse,
    memberId: string,
    onChange: (newKingdom: KingdomDTOResponse) => void
}) => {
    const authDetail: AuthDetail = useAuth();
    const rulers = useRecoilValue<RulersResponse>(rulersOfTheRealm)
    const kingdomService = useContext<KingdomServiceContext>(KingdomAPI)

    function promote() {
        weSentABird({
            message: "We have promoted " + rulers[props.memberId].name + " to be an officer among our ranks!"
        })
        kingdomService
            .promote({userId: props.memberId, kingdomId: props.kingdom.id, rank: Rank.OFFICER}, authDetail.authData)
            .then(res => {
                props.onChange(res);
            })
    }

    function expel() {
        weSentABird({
            message: "We have expelled " + rulers[props.memberId].name + " the kingdom has no room for weak rulers!"
        })
        kingdomService
            .expel({userId: props.memberId, kingdomId: props.kingdom.id}, authDetail.authData)
            .then(res => {
                props.onChange(res);
            })
    }

    return <>
        <RulerName ruler={rulers[props.memberId]}/>
        <Space>
            {props.kingdom.leaderId === authDetail.authData.userId && <Button onClick={promote}>Promote</Button>}
            {(props.kingdom.leaderId === authDetail.authData.userId
                    || props.kingdom.officerIds.findIndex(item => item === authDetail.authData.userId) >= 0)
                && <Popconfirm title={"Expel " + rulers[props.memberId].name + "?"}
                               icon={<BirdIcon/>}
                               cancelText={"No you fool!"}
                               okText={"Yes, there is no room for weakness here!"} onConfirm={expel}>
                    <Button danger={true}>Expel</Button>
                </Popconfirm>}
        </Space>
    </>

}
export default KingdomMember