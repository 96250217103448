import React from "react";
import {Remark} from "react-remark";


const QuickStartGuide = () => {

    return (<Remark>{
`
# Quick Start
Thanks for choosing MithTerra. I've worked hard to bring this game to life.

## Setup your profile
- Create your flag.
- Select a realm to join by using the menu at the top and clicking on the \`Realms\` menu.

## Settle your first Colony
- Choose a location close to water for a bonus to food production, mountains for a bonus to
 stone production, or forest for a bonus to wood production. The closer you are to the resources the faster 
 the colony will harvest them using your economic buildings and workers. However, building too close
 to the resources will also limit the size of the colony. It's a balance.
- If you're in a Kingdom you will have to select a location within your Kingdom's fog of war unless
 you're the first army to settle.
### Field the Army where you want to settle your Colony.
- Field an Army (right click on the map, give your army a name and then click \'Field Army\'.
 In a moment your army will appear on the map. Pick a location on the map that's far enough
 away from other colonies, and environment (trees, mountains etc...). A good heuristic to follow is
 1 - 2 squares away from resources, this way your colony has room to expand, but it will also receive bonus
 for harvesting the nearby resources.
### Settle the Colony at your army's location
- Right click on your army and select \`Settle Colony\`, answer the questions your paige has for you
 and he will dispatch a bird to the army. Once there the men in the army will construct the Colony.
`
}
    </Remark>)


}


export default QuickStartGuide